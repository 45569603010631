// regex for any combination of spaces and punctuation - http://stackoverflow.com/a/25575009
const wordSeparators =
  /[\s\u2000-\u206F\u2E00-\u2E7F\\'!"#$%&()*+,\-./:;<=>?@[\]^_`{|}~]+/;
const capitals = /[A-Z\u00C0-\u00D6\u00D9-\u00DD]/g;

export const kebabCase = (string: string) =>
  string
    .replace(capitals, (match) => ` ${match.toLowerCase() || match}`)
    .trim()
    .split(wordSeparators)
    .join('-')
    .replace(/^-/, '')
    .replace(/-\s*$/, '');

export const replaceCamelCase = (text: string) => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0) + result.slice(1).toLowerCase();
};
