// react
import React, { VFC, useEffect, useState } from 'react';

// packages
import { useHistory } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// redux
import { pushMessageAlert } from 'store/modules/alert/slice';
import { clearState as clearAuthState } from 'store/modules/auth/slice';
import {
  selectFlowType,
  selectUrlProduct,
} from 'store/modules/config/selectors';
import {
  selectEmailError,
  selectEmailLoading,
  selectEmailSuccess,
} from 'store/modules/email/selectors';
import {
  clearEmailState,
  setEmail as setEmailAction,
} from 'store/modules/email/slice';
import { sendEmailToken } from 'store/modules/email/thunks';
import { clearState as ordersClearState } from 'store/modules/orders/slice';

// hooks
import { useAppDispatch, useAppSelector } from 'store/hooks';

// components
import Box from '@mui/material/Box';
import ContactInfo from 'components/ContactInfo';
import FileClaim from 'components/FilePage/FileClaim';
import HeaderDescription from 'components/HeaderDescription';

// utils
import { generateTranslation } from 'utils/translation/i18nextTranslation';

// types
import type { FilePageType } from 'utils/translation/types';
import type { FormInputs } from 'components/FilePage/types';

const FilePageContent: VFC = function () {
  // redux state
  const productInUrl = useAppSelector(selectUrlProduct);
  const emailError = useAppSelector(selectEmailError);
  const emailLoading = useAppSelector(selectEmailLoading);
  const emailSuccess = useAppSelector(selectEmailSuccess);
  const type = useAppSelector(selectFlowType);

  // hooks
  const dispatch = useAppDispatch();
  const history = useHistory();

  // state
  const [email, setEmail] = useState('');

  // functions
  const onSubmit = (data: FormInputs) => {
    setEmail(data.email);
    dispatch(sendEmailToken(data.email));
  };

  useEffect(() => {
    dispatch(clearEmailState());
    dispatch(clearAuthState());
    dispatch(ordersClearState());
  }, [dispatch]);

  useEffect(() => {
    if (!emailSuccess) return;

    dispatch(setEmailAction(email));
    dispatch(
      pushMessageAlert({
        message: emailSuccess,
        type: 'success',
      })
    );
    history.push(`/${productInUrl}/${type}/email-sent`);
  }, [emailSuccess, email, history, dispatch, type, productInUrl]);

  const translation = generateTranslation('filePage') as FilePageType;

  return (
    <Stack>
      <Box px={{ sx: 2, sm: 0 }}>
        <HeaderDescription title={translation.title}>
          <Typography mb={4}>{translation.description}</Typography>
        </HeaderDescription>
        <Grid container justifyContent="center">
          <Grid item sm={8} xs={12}>
            <FileClaim
              loading={emailLoading}
              error={emailError}
              onSubmit={onSubmit}
              translation={translation.fileClaim}
            >
              <ContactInfo
                linkText={translation.contactInfo.linkText}
                title={translation.contactInfo.title}
                introVerbiage={translation.contactInfo.introVerbiage}
                showEmail
              />
            </FileClaim>
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default FilePageContent;
