import { AxiosResponse } from 'axios';
import { claimApi } from 'store/baseApi';
import {
  BillingFormattedData,
  ClaimFormattedData,
  RechargeFormattedData,
  VoidFormattedData,
} from './types';

const createClaim = (
  params: ClaimFormattedData
): Promise<AxiosResponse<any, any>> => claimApi.post(`claims/intake`, params);

const voidPolicies = (
  orderId: string,
  params: VoidFormattedData
): Promise<AxiosResponse<any, any>> =>
  claimApi.post(`orders/${orderId}/void`, params);

const rechargeOrder = (
  orderId: string,
  params: RechargeFormattedData
): Promise<AxiosResponse<any, any>> =>
  claimApi.post(`orders/${orderId}/recharge`, params);

const updateBillingInformation = (
  orderId: string,
  params: BillingFormattedData
): Promise<AxiosResponse<any, any>> =>
  claimApi.patch(`orders/${orderId}`, params);

const api = {
  createClaim,
  voidPolicies,
  rechargeOrder,
  updateBillingInformation,
};

export default api;
