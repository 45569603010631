import React, {VFC} from 'react';
import Layout from 'components/Layout';
import {Controller, UseFormReturn} from 'react-hook-form';
import Panel from 'components/Panel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export const TextAreaPanel: VFC<{
  description: string;
  placeholder: string;
  name: string;
  characterLimit?: number;
  formApi: UseFormReturn<any, any>;
}> = function ({description, placeholder, name, characterLimit, formApi: {control}}) {
  return (
    <Panel>
      <Layout>
        <Typography mb={2}>{description}</Typography>
        <Controller
          name={name}
          control={control}
          render={({field: {onChange, value, ref, ...rest}, fieldState: {error}}) =>
            <TextField
              sx={{
                '& .MuiFormHelperText-root': {
                  textAlign: 'left',
                  mx: 0,
                  mt: 1,
                },
              }}
              inputRef={ref}
              {...rest}
              helperText={error?.message || ' '}
              error={Boolean(error)}
              onChange={onChange}
              value={value}
              placeholder={placeholder}
              multiline
              minRows={5}
              variant="filled"
              inputProps={{
                maxLength: characterLimit,
              }}
            />
          }
        />
      </Layout>
    </Panel>
  );
};
