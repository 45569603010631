import React, { VFC, useState } from 'react';
import { generateTranslation } from 'utils/translation/i18nextTranslation';
import { useAppSelector } from 'store/hooks';
import { selectLinks } from 'store/modules/config/selectors';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ContactInfoComponentType } from 'utils/translation/types';
import { ContactInfoProps } from './types';

const ContactInfo: VFC<ContactInfoProps> = function ({
  linkText,
  title,
  introVerbiage,
  showEmail = false,
}) {
  const [open, setOpen] = useState(false);
  const translation = generateTranslation(
    'contactInfo'
  ) as ContactInfoComponentType;
  const links = useAppSelector(selectLinks);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Link
        component="button"
        type="button"
        className="font-weight-medium"
        onClick={handleClickOpen}
        underline="always"
        sx={{
          color: 'text.primary',
          textDecorationColor: (theme) => theme.palette.text.primary,
        }}
      >
        {linkText}
      </Link>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="contact-info-title"
        aria-describedby="contact-info-description"
      >
        <DialogTitle
          id="contact-info-title"
          sx={{ pb: 2 }}
          className="headline font-weight-bold"
        >
          <Box sx={{ px: 5 }} className="text-center">
            {title}
          </Box>
        </DialogTitle>
        <DialogContent
          sx={{ margin: 0, padding: 1 }}
          className="text-center line-height-2--children"
        >
          <Typography>
            {introVerbiage}{' '}
            {showEmail && (
              <Link
                underline="always"
                href={translation.links.helpEmail || links.helpEmail}
                target="_blank"
              >
                {translation.description}
              </Link>
            )}
            {showEmail && '.'}
          </Typography>
        </DialogContent>
        <DialogActions style={{ padding: '20px' }}>
          <Button onClick={handleClose} className="btn-style" autoFocus>
            {translation.button}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ContactInfo;
