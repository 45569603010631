import React, { VFC } from 'react';
import Typography from '@mui/material/Typography';
import Layout from 'components/Layout';
import Panel from 'components/Panel';
import { UseFormReturn } from 'react-hook-form';
import { useAppSelector } from 'store/hooks';
import { selectFlowType } from 'store/modules/config/selectors';
import { Flow } from 'store/modules/config/types';
import ReviewTable from 'components/ReviewPage/ReviewTable';
import {
  selectSelectedItems,
  selectSelectedOrder,
} from 'store/modules/orders/selectors';
import CreditCardForm from './CreditCardForm';

export const CreditCardPanel: VFC<{
  name: string;
  description: string;
  formApi: UseFormReturn<any, any>;
}> = function ({
  description,
  name,
  formApi: { resetField, setValue, control, watch },
}) {
  const type = useAppSelector(selectFlowType);
  const selectedItems = useAppSelector(selectSelectedItems);
  const selectedOrder = useAppSelector(selectSelectedOrder);

  return (
    <>
      {type === Flow.recharge && (
        <ReviewTable
          selectedItems={selectedItems}
          selectedOrder={selectedOrder!}
          displayPremiumTotal
        />
      )}
      <Panel>
        <Layout>
          <Typography mb={2}>{description}</Typography>
          <CreditCardForm
            control={control}
            name={name}
            watch={watch}
            resetField={resetField}
            selectedOrderAddress={selectedOrder?.customer}
            setValue={setValue}
          />
        </Layout>
      </Panel>
    </>
  );
};
