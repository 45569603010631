import { resolvePropFromConfigToReduxState } from 'store/hooks';
import { RootState } from 'store/types';
import { extractPropFromObject } from 'store/utils/hooks';
import { isObject } from 'utils/object';
import { AxiosResponse } from 'axios';
import { FormattedItem } from '../orders/types';
import { Flow, FlowType } from '../config/types';
import api from './api';
import {
  BillingFormattedData,
  ClaimFormattedData,
  RechargeFormattedData,
  VoidFormattedData,
} from './types';

export function flattenUniqueIds(selectedItems: FormattedItem[]): string[] {
  return selectedItems.map((item) => item.id);
}

export function flattenItemIds(selectedItems: FormattedItem[]): string[] {
  return selectedItems.map((item) => item.id);
}

export function submissionMapping(state: RootState, submissionMapping: any) {
  const res = { ...submissionMapping };
  for (const key in submissionMapping) {
    if (Object.prototype.hasOwnProperty.call(submissionMapping, key)) {
      const element = submissionMapping[key];
      const resolved = resolveAPISubmissionMapping(element, state);
      res[key] = resolved;
    }
  }
  return res;
}

const resolveAPISubmissionMapping = (obj: any, state: RootState) => {
  if (Array.isArray(obj)) {
    return resolveArrayFromConfigToReduxState(obj, state);
  }
  if (isObject(obj)) {
    return resolveObjectFromConfigToReduxState(obj, state);
  }
  return resolvePropFromConfigToReduxState(obj);
};

const resolveArrayFromConfigToReduxState = (
  prop: string[],
  state: RootState
) => {
  const resolve = prop.map((item) => {
    const stateObj = item.split('${GLOBAL.')[1].split('}')[0];
    return stateObj.split('.').reduce(extractPropFromObject(state), {});
  });
  return resolve.flat();
};

const resolveObjectFromConfigToReduxState = (obj: any, state: RootState) => {
  const resolved = { ...obj };
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      resolved[key] = resolvePropFromConfigToReduxState(obj[key]);
    }
  }
  return resolved;
};

export const callFlowSubmitApi = (
  orderUuid: string | undefined,
  data:
    | VoidFormattedData
    | ClaimFormattedData
    | RechargeFormattedData
    | BillingFormattedData,
  type: FlowType
): Promise<AxiosResponse<any, any>> => {
  switch (type) {
    case Flow.cancellation:
      return api.voidPolicies(orderUuid || '', data as VoidFormattedData);
    case Flow.recharge:
      return api.rechargeOrder(orderUuid || '', data as RechargeFormattedData);
    case Flow.billing:
      return api.updateBillingInformation(
        orderUuid || '',
        data as BillingFormattedData
      );
    default:
      return api.createClaim(data as ClaimFormattedData);
  }
};
