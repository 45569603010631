/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-template-curly-in-string */

// mocks
import { shopGGeneralConfig } from 'mocks/products/shopguarantee';

// types
import type { ConfigFileTypes, ConfigStep } from 'types';

export const shopGSteps: ConfigStep[] = [
  {
    name: 'Covered Reasons',
    backButtonText: '',
    fields: [
      {
        component: 'HeaderDescription',
        verbiages: {
          title: 'What Happened to Your Shipment?',
          footer: '',
          description:
            'From the coverage options below, please select the scenario that applies to your claim.',
        },
      },
      {
        component: 'PerilsPanel',
        props: {
          required: true,
          name: 'peril',
        },
        verbiages: {
          reviewPageTitle: 'Covered Reason',
        },
      },
      {
        component: 'StepSubmitButton',
        verbiages: {
          text: 'Next',
          allCompleteText: 'Update',
        },
      },
    ],
  },
  {
    name: 'Documents',
    backButtonText: 'Covered Reasons',
    fields: [
      {
        component: 'HeaderDescription',
        verbiages: {
          title: 'Additional Information Needed',
          footer: '',
          description:
            'To begin processing your claim, we need a little more information. Thank you.',
        },
      },
      {
        component: 'AddressPanel',
        props: {
          reviewPageGrid: {
            xs: 12,
            sm: 6,
            md: 3,
          },
          name: 'billingAddress',
          required: true,
          defaultValue: {
            address_1: '${GLOBAL.customerReducer.customer.address_1}',
            address_2: '${GLOBAL.customerReducer.customer.address_2}',
            city: '${GLOBAL.customerReducer.customer.city}',
            state: '${GLOBAL.customerReducer.customer.state}',
            zip_code: '${GLOBAL.customerReducer.customer.zip_code}',
            country: '${GLOBAL.customerReducer.customer.country}',
            phone: '${GLOBAL.customerReducer.customer.phone}',
          },
        },
        verbiages: {
          description: 'Billing information associated with purchase.',
          reviewPageTitle: 'Billing Address',
        },
      },
      {
        component: 'TextAreaPanel',
        props: {
          reviewPageGrid: {
            xs: 12,
            sm: 6,
            md: 5,
          },
          name: 'summary',
          required: true,
        },
        verbiages: {
          description:
            'Please enter short statement below to summarize details of claim:',
          placeholder: 'Enter Text',
          reviewPageTitle: 'Personal Statement',
        },
      },
      {
        component: 'DescriptionPanel',
        props: {
          name: 'DescriptionPanel1',
          excludeFromReview: true,
          reviewPageGrid: {
            xs: 12,
            sm: 6,
            md: 4,
          },
        },
        verbiages: {
          description:
            '${GLOBAL.formsReducer.forms.step1.peril.name}: ${GLOBAL.formsReducer.forms.step1.peril.description}',
          reviewPageTitle: 'Peril Selected',
        },
      },
      {
        component: 'FileUploadPanel',
        props: {
          reviewPageGrid: {
            xs: 12,
            sm: 6,
            md: 12,
          },
          name: 'perilsUpload',
          defaultValue: [],
          required: false,
          document_type: 'R',
        },
        verbiages: {
          description: 'Please upload necessary documentation.',
          contactInfoText:
            'Uploading required documentation expedites the refund process',
          reviewPageTitle: 'Supporting Documentation',
          noFileUploaded: 'No file(s) uploaded',
          acceptance:
            'We can only accept the following file formats: .jpeg, .png, .pdf, and .mp4',
          footer:
            'Typical documentation required for this claim: ${GLOBAL.formsReducer.forms.step1.peril.documents_description}',
        },
      },
      {
        component: 'StepSubmitButton',
        verbiages: {
          text: 'Next',
          allCompleteText: 'Update',
        },
      },
    ],
  },
  {
    name: 'Review',
    backButtonText: 'Documents',
    fields: [
      {
        component: 'ReviewPanel',
        verbiages: {},
      },
    ],
  },
  {
    name: 'ThankYouPage',
    backButtonText: '',
    fields: [
      {
        component: 'ThankYouPage',
        verbiages: {},
      },
    ],
  },
];
export const shopGSubmissionMapping = {
  customer: {
    first_name: '${GLOBAL.customerReducer.customer.first_name}',
    last_name: '${GLOBAL.customerReducer.customer.last_name}',
    email: '${GLOBAL.customerReducer.customer.email}',
    address_line_1:
      '${GLOBAL.formsReducer.forms.step2.billingAddress_address_1}',
    address_line_2:
      '${GLOBAL.formsReducer.forms.step2.billingAddress_address_2}',
    city: '${GLOBAL.formsReducer.forms.step2.billingAddress_city}',
    state: '${GLOBAL.formsReducer.forms.step2.billingAddress_state}',
    zip_code: '${GLOBAL.formsReducer.forms.step2.billingAddress_zip_code}',
    country: '${GLOBAL.formsReducer.forms.step2.billingAddress_country}',
    phone_number: '${GLOBAL.formsReducer.forms.step2.billingAddress_phone}',
  },
  files: ['${GLOBAL.formsReducer.forms.step2.perilsUpload}'],
  statement: '${GLOBAL.formsReducer.forms.step2.summary}',
  peril: '${GLOBAL.formsReducer.forms.step1.peril.id}',
  additional_comment: 'None Provided.',
};

export const shopGRefundConfig: ConfigFileTypes = {
  ...shopGGeneralConfig,
  steps: shopGSteps,
  submissionMapping: shopGSubmissionMapping,
};
