import { useCallback } from 'react';

// packages
import { useHistory } from 'react-router-dom';

// redux
import { clearState as authClearState } from 'store/modules/auth/slice';
import { clearState as claimsClearState } from 'store/modules/claims/slice';
import {
  selectFlowType,
  selectUrlProduct,
} from 'store/modules/config/selectors';
import { clearState as customerClearState } from 'store/modules/customer/slice';
import { clearAllForms } from 'store/modules/forms';
import { clearState as ordersClearState } from 'store/modules/orders/slice';
import { clearState as perilsClearState } from 'store/modules/perils/slice';
import { clearThanksState } from 'store/modules/thanks/slice';

// hooks
import { useAppDispatch, useAppSelector } from 'store/hooks';

// utils
import { clear } from 'utils/localstorage';

export function useClearReduxPersistedState() {
  // hooks
  const dispatch = useAppDispatch();
  const history = useHistory();

  // redux state
  const productInUrl = useAppSelector(selectUrlProduct);
  const type = useAppSelector(selectFlowType);

  const clearReduxPersistedStateWithoutAuth = useCallback(async () => {
    dispatch(claimsClearState());
    dispatch(clearAllForms());
    dispatch(clearThanksState());
    dispatch(customerClearState());
    dispatch(ordersClearState());
    dispatch(perilsClearState());
  }, [dispatch]);

  const clearReduxPersistedState = useCallback(async () => {
    dispatch(authClearState());
    clearReduxPersistedStateWithoutAuth();
  }, [clearReduxPersistedStateWithoutAuth, dispatch]);

  const clearReduxPersistedStateAndRedirect = useCallback(async () => {
    clearReduxPersistedState();

    // clear local storage
    await clear();

    // redirect to the email magic link screen
    return history.push(`/${productInUrl}/${type}`);
  }, [clearReduxPersistedState, history, productInUrl, type]);

  return {
    clearReduxPersistedState,
    clearReduxPersistedStateAndRedirect,
    clearReduxPersistedStateWithoutAuth,
  };
}
