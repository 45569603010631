/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createClaim } from './thunks';
import { ClaimsState, Claim } from './types';

const initialState: ClaimsState = {
  lang: '',
  claim: {
    status: 'idle',
    id: '',
    confirmationNumber: '',
  },
  interruptedSession: false,
};

export const ClaimsSlice = createSlice({
  name: 'claims',
  initialState,
  reducers: {
    setClaim: (state, action: PayloadAction<Claim>) => {
      state.claim = action.payload;
    },
    setPreferredLang: (state, action: PayloadAction<string>) => {
      state.lang = action.payload;
    },
    setInterruptionValue: (state, action: PayloadAction<boolean>) => {
      state.interruptedSession = action.payload;
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(createClaim.fulfilled, (state, { payload }) => {
      state.claim = {
        id: payload.id,
        status: 'success',
        confirmationNumber: payload.confirmation_number,
      };
    });
    builder.addCase(createClaim.rejected, (state, action) => {
      const error = (action.payload ||
        action.error.message ||
        'Error creating a claim') as string;
      state.claim = {
        id: '',
        status: 'error',
        errorMessage: error,
      };
    });
    builder.addCase(createClaim.pending, (state, _) => {
      state.claim = {
        id: '',
        status: 'loading',
      };
    });
  },
});

export const { setPreferredLang, clearState, setInterruptionValue, setClaim } =
  ClaimsSlice.actions;

export default ClaimsSlice.reducer;
/* eslint-enable no-param-reassign */
