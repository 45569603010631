const defaultStyles = {
  borderRadius: '2px',
  boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.24) !important',
};

export default {
  input: {
    ...defaultStyles,
    background: 'white !important',
    border: '1px solid rgb(234, 234, 232)',
    flexGrow: 1,
    '.MuiInputBase-input': {
      py: 2,
    },
  },
  timePicker: {
    ...defaultStyles,
    background: 'white !important',
    '.MuiInputBase-input': {
      pt: 3,
      pb: '12px',
    },
  },
};
