import { AxiosResponse } from "axios";
import { authApi } from "store/baseApi";

const getAccessToken = (token: string): Promise<AxiosResponse<any, any>> => authApi.post(`customer/email-token`, { token })

const extendAccessToken = (token: string): Promise<AxiosResponse<any, any>> => authApi.post(`refresh`, { headers: { Authorization: `Bearer ${token}` } })

const api = {
    getAccessToken,
    extendAccessToken
}

export default api;