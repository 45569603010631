import { createAsyncThunk } from '@reduxjs/toolkit';
import { ProductEnum, ProductInUrlType } from 'types';
import { getProductSlug } from 'utils/helpers';

import api from './api';
import { FlowType } from './types';

export const configFetch = function (url: string) {
  return new Promise<any>((resolve, reject) => {
    fetch(url)
      .then((r) => r.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => reject(error));
  });
};

export const getConfig = createAsyncThunk(
  'config/getConfig',
  async (
    {
      product,
      client,
      type,
    }: {
      product: ProductInUrlType;
      client: string | null;
      type: FlowType;
    },
    thunkApi
  ) => {
    const productSlug = getProductSlug(ProductEnum[product]);

    try {
      const { data } = await api.getConfig(productSlug, client, type);

      return {
        config: data.configuration,
        productInUrl: product,
        productId: data.product,
        type,
      };
    } catch (e) {
      return thunkApi.rejectWithValue('Error fetching config');
    }
  }
);
