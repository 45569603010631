// redux
import { RootState } from 'store';

export const selectReasons = (state: RootState) =>
  state.reasonsReducer.reasons.status === 'success'
    ? state.reasonsReducer.reasons.data
    : [];

export const selectReasonsError = (state: RootState) =>
  state.reasonsReducer.reasons.status === 'error';

export const selectReasonsErrorMessage = (state: RootState) =>
  state.reasonsReducer.reasons.status === 'error' &&
  state.reasonsReducer.reasons.errorMessage;

export const selectReasonsLoading = (state: RootState) =>
  state.reasonsReducer.reasons.status === 'loading';
