// utils
import { isEmptyObject } from 'utils/object';

// types
import { ProductInUrlType, ProductType, ProductEnum } from 'types';

export const containsReduxStateTags = (prop: string | undefined) =>
  Boolean(prop && prop.indexOf('{GLOBAL.') >= 0);

export const containsHookFormStateTags = (prop: string | undefined) =>
  Boolean(prop && prop.indexOf('{LOCAL.') >= 0);

export const extractPropFromObject =
  (state: any) => (acc: { [x: string]: any }, curr: string) => {
    if (isEmptyObject(acc)) {
      if (Object.prototype.hasOwnProperty.call(state, curr)) return state[curr];
      // eslint-disable-next-line prefer-template
      throw new Error('Property ' + curr + ' is missing in config.json');
    }

    if (Object.prototype.hasOwnProperty.call(acc, curr)) return acc[curr];
    // eslint-disable-next-line prefer-template
    throw new Error('Property ' + curr + ' is missing in config.json');
  };

export const formatProperty = (state: any, propStart: string, end: string) => {
  const secondSlice = end.split('}');
  const statePropKeys = secondSlice[0];
  const propEnd = secondSlice
    .map((slice, index) => {
      if (index > 0) {
        if (containsHookFormStateTags(slice))
          // eslint-disable-next-line prefer-template
          return slice + '}';
        return slice;
      }
      return '';
    })
    .join('');

  try {
    const replaced = statePropKeys
      .split('.')
      .reduce(extractPropFromObject(state), {});

    return propStart + replaced + propEnd;
  } catch (e) {
    const error: any = e;
    // eslint-disable-next-line no-console
    console.log(error.message);
    return propStart + propEnd;
  }
};

export const getProductValue = (
  product: ProductInUrlType | undefined
): ProductType | undefined => (product ? ProductEnum[product] : undefined);
