// packages
import { rest } from 'msw';

// utils
import { getProductFromProductSlug } from 'utils/helpers';

// mocks
import mocks from 'mocks';
import { mockPerils, mockReasons } from 'mocks/shared';

export const handlers = [
  rest.post(
    `${process.env.REACT_APP_API_V4_URL}/auth/customer`,
    (req, res, ctx) => res(ctx.json({ 'email-token': 'someEmailToken' }))
  ),
  rest.post(
    `${process.env.REACT_APP_API_V4_URL}/auth/customer/email-token`,
    (req, res, ctx) =>
      res(
        ctx.json(
          'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImRlbW9AZXhhbXBsZS5jb20iLCJzdWIiOiJhZDdkYmRjMS0wMmU4LTRhMDMtOTM5Yy03NzQ2ODAyNjM0ZjkiLCJleHAiOjE2NjQ3OTUyMTksImlhdCI6MTY2NDc5MDgxNn0.q6VKUYyMXZzSFLlGF1zquG3Zw7J_XIYxgh6-2hSgapA'
        )
      )
  ),
  rest.post(
    `${process.env.REACT_APP_API_V4_URL}/auth/refresh`,
    (req, res, ctx) =>
      res(
        ctx.json(
          'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImRlbW9AZXhhbXBsZS5jb20iLCJzdWIiOiJhZDdkYmRjMS0wMmU4LTRhMDMtOTM5Yy03NzQ2ODAyNjM0ZjkiLCJleHAiOjE2NjQ3OTUzNDIsImlhdCI6MTY2NDc5MDgxNn0.qtYaAxs7CuqZYnEwNewgxVZkCSE1GHupW5gMn9QBe_I'
        )
      )
  ),
  // New config endpoint
  rest.get(
    `${process.env.REACT_APP_API_V4_URL}/products/:productSlug/config`,
    (req, res, ctx) => {
      const { productSlug } = req.params;
      const type = req.url.searchParams.get('type');
      const client = req.url.searchParams.get('client');
      const product = getProductFromProductSlug(productSlug);
      const mockKey = `${type === 'claim' ? 'refund' : type}/${product}`;

      return mocks[mockKey].config
        ? res(
            ctx.json({
              product,
              configuration: mocks[mockKey].config,
              type,
              client,
            })
          )
        : res(ctx.status(404, 'Config Not Found'));
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_V4_URL}/:product/orders`,
    (req, res, ctx) => {
      const { product } = req.params;
      return mocks[`refund/${product}`].order
        ? res(ctx.json(mocks[`refund/${product}`].order), ctx.delay(150))
        : res(ctx.status(404, 'Order Not Found'));
    }
  ),
  rest.get(
    `${process.env.REACT_APP_API_V4_URL}/raas/embedded/orders`,
    (req, res, ctx) => res(ctx.json(mocks['refund/raas'].order), ctx.delay(150))
  ),
  rest.get(
    `${process.env.REACT_APP_API_V4_URL}/raas/optional/orders`,
    (req, res, ctx) =>
      res(
        ctx.json({
          count: 2,
          next: '[https://newdomain.protecht.com/raas/orders/?limit=20&offset=20](https://newdomain.protecht.com/raas/orders/?limit=20&offset=20)',
          previous: null,
          items: [],
        }),
        ctx.delay(150)
      )
  ),
  rest.get(
    `${process.env.REACT_APP_API_V4_URL}/shopguarantee/:id/voidable`,
    (req, res, ctx) =>
      mocks['cancellation/shopguarantee'].voidable
        ? res(
            ctx.json(mocks['cancellation/shopguarantee'].voidable),
            ctx.delay(150)
          )
        : res(ctx.status(404, 'Order Not Found'))
  ),
  rest.get(
    `${process.env.REACT_APP_API_V4_URL}/policies/:uuid/perils`,
    (req, res, ctx) => res(ctx.json(mockPerils))
  ),
  rest.get(
    `${process.env.REACT_APP_API_INTERNAL_URL}/policies/action-reasons?source=USERFORM&type=VOID`,
    (req, res, ctx) => res(ctx.json(mockReasons))
  ),
  rest.post(
    `${process.env.REACT_APP_API_V4_URL}/claims/files`,
    (req, res, ctx) =>
      res(
        ctx.json({
          id: `${Math.random().toString()}`,
          created: '2019-08-24T14:15:22Z',
          file: `public/download-${Math.random().toString()}.jpeg`,
          filename: `name${Math.random().toString()}`,
          document_type: 'SD',
          url: `https://ticketguardian-qa-claims.s3.amazonaws.com/public/download-${Math.random().toString()}s.jpeg?AWSAccessKeyId=ASIATPPK6X4ZVQFCUKOL&Signature=yXCNgkOsMxGDwxZKGI7qqSUOWKA%3D&x-amz-security-token=IQoJb3JpZ2luX2VjELz%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJIMEYCIQD4IsAuQufA3upy5uF1xHaBHrYcZ1mE0llcLOH8sj452QIhAKKssQgeftTXQbzVxDCqLlisXMU%2Blp1KqidgQBmZ3CbIKu0DCEUQAxoMMjM5NDAwMzA0NDM1IgzYWFn1NpcJ3NGewJ0qygOO%2BSEw%2F%2BzXlfQ9sMQLQma9nPs4WqqEgwZ%2F2VLhxbi2NESmPQMiT9Any9Hq4pRVEJ7kv5z7UnJ%2FIyeGrq4ZNYafxypeaqvZOtQIkZ%2FcZ4OdVlUs8WwBQrfm7Qu7CmTBm5wPIElzUzr3w9zxkhdaLyEJNk3v95Wfvk00nxk2NmbGJMTYTjy6yjuNx8B8IHsjHtkRT86LdcAJArsqr%2F9mnX%2FiDt0bzZ%2BwgzrAJ83u%2BwURNNZgKbE61tHPqJsm7otoRJiHRzA9qFtXfUp%2Bdc0j9RTNX6yCBE8B8SR9tIU9Kjgfy0Y%2BJtLTxVgowgaEYiM%2F73KoImderA1Nz6izFdcAf5q7u6OvnFNSZpVezexvzf%2B%2BW%2B0Eyb0Hsbvq72hKC9IoQfDT%2F4P3hIuEuiX7Zc1xA7AgtmNvQBtMlsiIVvvYq84EeO7sy0a3aYoZJl8u8%2Bj0NzcmJUdfDO7Zwg1FlaNz%2FkulU2h5DCAAR0Ox3KfTxiKXloSFzxW5ejYO0yapDXp0tVVaqHiCzmiPSciuSyPeWS02wIyUxM6vjEdCXtfYdzRcokhWcpCwGWX7jNaccibAFnOqiyQGLJZfIbhiywqRKMcJOilEQJsBDnyHXTDt%2F7eYBjqkAdaqd7kG3X7S%2BDhaiE4jt36gOmqo1FXU1DR%2BXOOprd8wlY7tLb%2BbSz8gNvYqbbsHvsaf1bbfuNiWLAqbSvP%2FjDL8kJNA0vEKJPM7h8LIHpcBxOVFMjIAIsuqwbFZPgTwaFP2HvHoM7qbgNK7qAyYHTQsAS277MBgXrrB0U9hDeGjqQzSCRO0tJoc8rYGBZjyohLsI0vZrUDE6g8r4JSnY0Yh6AMp&Expires=1661865651`,
        })
      )
  ),
  rest.post(
    `${process.env.REACT_APP_API_V4_URL}/claims/intake`,
    (req, res, ctx) =>
      res(
        ctx.json({
          id: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
          ...req.body,
        })
      )
  ),
  rest.patch(
    `${process.env.REACT_APP_API_V4_URL}/orders/:id`,
    (req, res, ctx) => res(ctx.json({ requires_payment: true }))
  ),
  rest.post(
    `${process.env.REACT_APP_API_V4_URL}/orders/:id/void`,
    (req, res, ctx) =>
      res(
        ctx.json({
          id: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
          ...req.body,
        })
      )
  ),
  rest.post(
    `${process.env.REACT_APP_API_V4_URL}/orders/:id/recharge`,
    (req, res, ctx) =>
      res(
        ctx.json({
          id: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
          ...req.body,
        })
      )
  ),
  rest.get(
    `${process.env.REACT_APP_API_V4_URL}/tracks/:carrier/:tracking_number`,
    (req, res, ctx) =>
      res(
        ctx.json({
          tracking_status: {
            status: 'PRE_TRANSIT',
          },
          ...req.body,
        })
      )
  ),
  rest.post(`${process.env.REACT_APP_API_V4_URL}/feedback`, (req, res, ctx) =>
    res(
      ctx.json({
        ...req.body,
      })
    )
  ),
];
