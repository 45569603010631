import React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import FormHelperText from '@mui/material/FormHelperText';
import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
import { useTheme } from 'styles';
import './filepondOverrides.css';
import { ProcessServerConfigFunction } from 'filepond';
import { Button, Typography } from '@mui/material';
import { useAppDispatch } from 'store/hooks';
import { deleteAllFiles, deleteSingleFile } from 'store/modules/forms';
import { FileList } from 'components/FileSelection/FileList';
import { ParseContent } from 'utils/parser/StringToHtml';

import {
  UploadFilesProps,
  FilePondErrorDescription,
  FilePondFile,
} from './types';

registerPlugin(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImagePreview,
  FilePondPluginMediaPreview
);

const UploadFiles: React.VFC<UploadFilesProps> = function App({
  description,
  acceptance,
  params,
  uploads,
  footer,
  storedValue,
  fieldName,
  setIsSubmitting,
  setUploadedFile,
  removeFile,
}) {
  const [error, setError] = React.useState('');
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isSmallOrLess = useMediaQuery(theme.breakpoints.up('md'));
  const uploadApiUrl = `${process.env.REACT_APP_API_V4_URL}/claims/files`;
  const pathname = window.location.pathname.split('/');
  const step = pathname[pathname.length - 1];
  const clearFiles = React.useCallback(() => {
    dispatch(deleteAllFiles({ fieldName, step }));
  }, [dispatch, fieldName, step]);

  const deleteFile = React.useCallback(
    (fileId: string) => {
      dispatch(deleteSingleFile({ fieldName, step, fileId }));
    },
    [dispatch, fieldName, step]
  );

  const processFile: ProcessServerConfigFunction = (
    fieldName,
    file,
    metadata,
    load,
    error,
    progress,
    abort
  ) => {
    // fieldName is the name of the input field
    // file is the actual file object to send
    const formData = new FormData();
    formData.append(fieldName, file, file.name);
    formData.append('document_type', params.document_type);

    const request = new XMLHttpRequest();
    request.open('POST', uploadApiUrl);

    // Should call the progress method to update the progress to 100% before calling load
    // Setting computable to false switches the loading indicator to infinite mode
    request.upload.onprogress = (e) => {
      progress(e.lengthComputable, e.loaded, e.total);
    };

    // Should call the load method when done and pass the returned server file id
    // this server file id is then used later on when reverting or restoring a file
    // so your server knows which file to return without exposing that info to the client
    request.onload = () => {
      if (request.status >= 200 && request.status < 300) {
        // the load method accepts either a string (id) or an object
        load(request.responseText);
      } else {
        // Can call the error method if something is wrong, should exit after
        error('oh no');
      }
    };

    request.send(formData);

    // Should expose an abort method so the request can be cancelled
    return {
      abort: () => {
        // This function is entered if the user has tapped the cancel button'
        request.abort();

        // Let FilePond know the request has been cancelled
        abort();

        setIsSubmitting(false);
      },
    };
  };

  const errorHandler = (error: FilePondErrorDescription, file) => {
    const metadata = file.getMetadata();

    // only allow file related errors for files that haven't been deleted.
    // In the future, we should consider replacing filepond completely.
    // There are a lot of custom functionality that filepond wasn't met to support.
    if (error && !metadata.delete && Object.keys(metadata).length > 0) {
      const type = error.type || error.main;
      const body = error.body || error.sub;

      if (!error.type && !error.body && !error.main && !error.sub) {
        setError('There was a problem with your upload, please try again.');
      } else if (body !== 'Forbidden') {
        setError(`${type} - ${body}`);
      }
    }
    // Stops file pond from trying to show the file in a non deleted state.
    file.abortLoad();

    setIsSubmitting(false);
  };

  const formatAddCb = (
    error: FilePondErrorDescription | null,
    file: FilePondFile
  ) => {
    const metadata = file.getMetadata();
    if (!error && !metadata.delete) {
      setUploadedFile(JSON.parse(file.serverId));
      setIsSubmitting(false);
    }
  };

  const formatResponseRemoveCb = (
    error: FilePondErrorDescription | null,
    file: FilePondFile
  ) => {
    setIsSubmitting(true);

    let fileInfo = file.getMetadata();
    file.setMetadata('delete', true);
    if (Object.keys(fileInfo).length <= 1 && file.serverId) {
      fileInfo = JSON.parse(file.serverId);
    }
    if (Object.keys(fileInfo).length > 1) {
      removeFile(fileInfo);
    } else {
      file.abortProcessing();
    }

    setIsSubmitting(false);
  };

  const filePondSelectText = () => {
    let verbiage =
      '<u class="font-weight-bold">Click</u> & select your files to upload';

    if (isSmallOrLess) {
      verbiage =
        'Drag & drop your files or <u class="font-weight-bold">browse</u>';
    }

    return verbiage;
  };

  return (
    <Stack direction="column">
      <Box mb={{ xs: 3 }}>
        {description}
        <p className="font-weight-bold"> {acceptance}</p>
        {footer && <ParseContent content={footer} />}
      </Box>
      <Box>
        <Box sx={{ cursor: 'pointer' }}>
          <FilePond
            allowImagePreview
            onaddfilestart={() => {
              setIsSubmitting(true);
              setError('');
            }}
            labelFileLoadError="No Preview Available"
            name="file"
            maxFileSize="15MB"
            server={{
              url: process.env.REACT_APP_S3_URL,
              process: processFile,
              load: '/',
            }}
            labelIdle={filePondSelectText()}
            allowMultiple
            allowDrop
            maxFiles={10}
            acceptedFileTypes={['image/*', 'application/pdf', 'video/mp4']}
            onerror={errorHandler}
            onprocessfile={formatAddCb}
            onremovefile={formatResponseRemoveCb}
          />
        </Box>

        {Boolean(storedValue) && storedValue.length > 0 && (
          <>
            <Typography fontWeight="bold" pb={1}>
              uploaded files:
            </Typography>

            <FileList deleteFile={deleteFile} uploads={storedValue} />

            <Box
              display="flex"
              padding="10px"
              flexDirection="column"
              alignItems="center"
            >
              <Button
                variant="contained"
                className="btn-style"
                onClick={clearFiles}
              >
                Delete all files
              </Button>
            </Box>
          </>
        )}
      </Box>
      <Box
        justifyContent={{ xs: 'center' }}
        flexWrap="wrap"
        className="text-xs-left"
      >
        {error && (
          <FormHelperText error className="text-center">
            {error}
          </FormHelperText>
        )}
      </Box>
    </Stack>
  );
};

export default UploadFiles;
