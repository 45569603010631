export const deepMerge = (target: any, source: any) => {
  if (!isObject(target) || !isObject(source)) return {};

  const output = { ...target };

  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach((key) => {
      if (isObject(source[key])) {
        if (!(key in target)) Object.assign(output, { [key]: source[key] });
        else output[key] = deepMerge(target[key], source[key]);
      } else {
        source[key] !== null && Object.assign(output, { [key]: source[key] });
      }
    });
  }

  return output;
};

export const getKeyByValue = (
  object: Record<string, unknown> | string,
  value: unknown
) => {
  if (isEmptyObject(object)) return;

  return Object.keys(object).find((key) => object[key] === value);
};

export const isEmptyObject = (item: any) => {
  if (!isObject(item)) return false;

  return Object.keys(item).length === 0;
};

export const isObject = (item: unknown) => {
  if (item === null || typeof item !== 'object') return false;

  // prevent false positives for arrays, dates, etc
  const proto = Object.getPrototypeOf(item);
  if (proto !== null && proto !== Object.prototype) return false;

  return true;
};
