// react
import React, { ReactNode, VFC } from 'react';

// packages
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// types
interface Item {
  description: string;
  href: string;
  path: ReactNode;
  linkTitle: string;
  title: string;
}

const LandingPageItem: VFC<{
  item: Item;
}> = function ({ item }) {
  return (
    <Box
      component="li"
      className="no-first-top-margin"
      sx={{
        alignItems: 'start',
        backgroundColor: '#fff',
        borderRadius: '6px',
        display: { sm: 'flex' },
        justifyContent: 'space-between',
        marginTop: '24px',
        marginX: 'auto',
        maxWidth: '60rem',
        padding: { xs: '20px 28px', md: '28px 36px' },
        width: '100%',
        '& .MuiBox-root:first': {
          marginTop: '0',
        },
      }}
    >
      <Box sx={{ paddingTop: { sm: '4px' } }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="35"
          height="35"
          fill="none"
        >
          {item.path}
        </svg>
      </Box>
      <Box
        sx={{
          paddingLeft: { sm: '16px' },
          paddingTop: { xs: '12px', sm: 0 },
          width: '100%',
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontSize: '15px',
            fontWeight: '700',
            lineHeight: { xs: '20px', md: '24px' },
          }}
        >
          {item.title}
        </Typography>
        <Box sx={{ marginTop: '12px' }}>
          <Typography sx={{ fontWeight: '400' }}>{item.description}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: { xs: 'center', sm: 'end' },
            marginTop: '16px',
            width: '100%',
          }}
        >
          <Button
            href={item.href}
            sx={{
              backgroundColor: '#f5faff',
              border: 'solid 1px #3ba1ff',
              borderRadius: '6px',
              maxWidth: '260px',
              padding: { xs: '20px', md: '24px' },
              width: '100%',
            }}
          >
            <Typography
              sx={{
                color: '#0b82ef',
                fontSize: { xs: '12px', md: '14px' },
                fontWeight: '700',
                lineHeight: { xs: '15px', md: '17px' },
                textAlign: 'center',
              }}
            >
              {item.linkTitle}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default LandingPageItem;
