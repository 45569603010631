// react
import React from 'react';

// packages
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';

// icons
import SearchIcon from '@mui/icons-material/Search';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ClearRounded from '@mui/icons-material/ClearRounded';

// hooks
import { useHistory } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { SearchDates } from 'hooks/useSearchDates';

// utils
import { dateFilterLabel } from 'utils/date';

// types
import { ColumnFilterEnum } from 'components/OrdersPage/DateSearchPanel';

export const Filters: React.FC<{
  searchTerm: string;
  clicked: boolean;
  searchDate: SearchDates;
  setSearchTerm: (newSearchTerm: string) => void;
  handleDateFilterPanelVisibility: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => void;
  clearSearchDate: () => void;
}> = function ({
  searchTerm,
  searchDate,
  clicked,
  clearSearchDate,
  setSearchTerm,
  handleDateFilterPanelVisibility,
}) {
  // hooks
  const theme = useTheme();
  const history = useHistory();

  // constants
  const isCertificatePage = history.location.pathname.includes('/certificate/');

  const currentDateFilterLabel = dateFilterLabel(
    searchDate.startDate,
    searchDate.endDate
  );

  return (
    <Stack
      direction={{ sm: 'row' }}
      sx={{
        backgroundColor: '#f5f0f0',
        display: 'flex',
        flexDirection: {
          xs: currentDateFilterLabel ? 'column' : 'row',
          sm: 'row',
        },
        justifyContent: {
          xs: 'justify-between',
          sm: 'flex-end',
        },
        padding: '0.5rem',
        ...(isCertificatePage && {
          backgroundColor: '#f6f7f7',
          paddingX: '24px',
        }),
      }}
    >
      <Paper
        component="form"
        sx={{
          alignItems: 'center',
          borderRadius: '0.5rem',
          display: 'flex',
          paddingX: {
            xs: 2,
          },
          paddingY: {
            xs: 1,
          },
          position: 'relative',
          width: {
            xs: '100%',
            md: '300px',
          },
          ...(isCertificatePage && {
            border: 'solid',
            borderColor: '#d9d9d9',
            borderWidth: '1px',
            borderRadius: '0.25rem',
            boxShadow: 'none',
          }),
        }}
      >
        <InputBase
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          placeholder="Search"
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault();
            }
          }}
          sx={{
            paddingRight: 4,
            width: '100%',
          }}
        />
        {searchTerm.length ? (
          <IconButton
            aria-label="clear"
            onClick={() => setSearchTerm('')}
            type="button"
            sx={{
              position: 'absolute',
              right: 0,
            }}
          >
            <ClearRounded />
          </IconButton>
        ) : (
          <IconButton
            type="button"
            aria-label="search"
            sx={{
              position: 'absolute',
              right: 0,
            }}
          >
            <SearchIcon />
          </IconButton>
        )}
      </Paper>
      <Paper
        component="form"
        elevation={isCertificatePage ? 0 : 1}
        sx={{
          alignItems: 'center',
          bgcolor: clicked
            ? theme.palette.primary.main
            : theme.palette.common.white,
          borderRadius: '0.5rem',
          display: 'flex',
          justifyContent: 'space-between',
          marginLeft: { xs: currentDateFilterLabel ? 0 : 2, sm: 2 },
          marginTop: { xs: currentDateFilterLabel ? 2 : 0, sm: 0 },
          minWidth: {
            xs: '37px',
          },
          ...(isCertificatePage && {
            border: 'solid',
            borderColor: '#d9d9d9',
            borderWidth: '1px',
            borderRadius: '0.25rem',
          }),
        }}
      >
        <Box sx={{ alignItems: 'center', display: 'flex' }}>
          <IconButton
            type="button"
            aria-label="search"
            onClick={handleDateFilterPanelVisibility}
          >
            <CalendarMonthIcon
              sx={{ color: clicked ? theme.palette.common.white : 'inherit' }}
            />
          </IconButton>
          {searchDate.column && (
            <Box
              sx={{
                color: clicked ? theme.palette.primary.contrastText : 'inherit',
                opacity: clicked ? 1 : 0.5,
              }}
            >{`${
              ColumnFilterEnum[searchDate.column]
            }: ${currentDateFilterLabel}`}</Box>
          )}
        </Box>
        {searchDate.column && (
          <IconButton
            onClick={clearSearchDate}
            type="button"
            aria-label="clear"
            sx={{
              color: clicked ? theme.palette.common.white : 'inherit',
              opacity: clicked ? 1 : 0.5,
            }}
          >
            <ClearRounded />
          </IconButton>
        )}
      </Paper>
    </Stack>
  );
};
