// packages
import { AxiosPromise } from 'axios';

// redux
import { configApi } from 'store/baseApi';

// types
import type { FlowType, GetConfigResponse } from 'store/modules/config/types';

const getConfig = (
  productSlug: string,
  client: string | null, // can be client id or client slug
  type: FlowType
): AxiosPromise<GetConfigResponse> => {
  const getURL = `products/${productSlug}/config`;

  const config = {
    params: {
      type: type === 'refund' ? 'claim' : type,
      client,
    },
  };

  return configApi.get(getURL, config);
};

const api = { getConfig };

export default api;
