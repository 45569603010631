import React, { memo, VFC } from 'react';
import { GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { formattedCost } from 'utils/helpers';
// eslint-disable-next-line import/no-extraneous-dependencies
import isEqual from 'lodash/isEqual';
import { statusColumn } from 'utils/itemTable';
import { ItemTableProps } from '../types';
import GenericItemTable from '../shared/GenericItemTable';

const ItemTable: VFC<ItemTableProps> = memo(
  ({
    order,
    items,
    selected,
    handleSelectionUpdate,
    handleAttemptToSelect,
  }) => {
    const { tracking_number } = items[0];

    const hasSelections = selected.length > 0;

    const rows: GridRowsProp = items.map((item, index) => ({
      id: index,
      status: item.status,
      claim_status: item.claim_status,
      name: `${item.name} - ${item.description}`,
      unit_cost: item.unit_cost,
    }));

    const defaultGridColDef = {
      sortable: false,
      hideable: false,
    };

    const columns: GridColDef[] = [
      { field: 'id', hide: true },
      {
        field: 'name',
        renderHeader: () => `Tracking number: ${tracking_number}`,
        flex: 5,
        ...defaultGridColDef,
      },
      {
        ...statusColumn,
        renderHeader: () => null,
        width: 130,
        ...defaultGridColDef,
      },
      {
        field: 'unit_cost',
        headerName: '',
        valueFormatter: (params) =>
          formattedCost(
            parseFloat(params.value as string),
            order.locale,
            order.currency
          ),
        flex: 1,
        ...defaultGridColDef,
      },
    ];

    return (
      <GenericItemTable
        order={order}
        items={items}
        rows={rows}
        columns={columns}
        hasSelections={hasSelections}
        selected={selected}
        handleSelectionUpdate={handleSelectionUpdate}
        handleAttemptToSelect={handleAttemptToSelect}
      />
    );
  },
  (prevProps, nextProps) => isEqual(prevProps.selected, nextProps.selected)
);

export default ItemTable;
