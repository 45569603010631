import React, { VFC } from 'react';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from 'components/InfoIcon';
import Box from '@mui/material/Box';
import { generateTranslation } from 'utils/translation/i18nextTranslation';
import { StatusColumnType } from 'utils/translation/types';

const StatusHeader: VFC = function () {
  const translation = generateTranslation('statusColumn') as StatusColumnType;

  const uniqueTranslationStatusList = Object.values(translation.status).filter(
    (obj, index, self) =>
      self.findIndex(
        (o) => o.description === obj.description && o.text === obj.text
      ) === index
  );

  return (
    <>
      <b>{translation.header}&nbsp;</b>
      <Tooltip
        disableFocusListener
        enterTouchDelay={10}
        title={
          <Box fontSize={12} sx={{ maxHeight: '400px', overflowX: 'auto' }}>
            {uniqueTranslationStatusList
              .sort((a, b) => {
                if (a.text < b.text) return -1;
                if (a.text > b.text) return 1;

                return 0;
              })
              .map((status, i) => (
                <Box p={1} mb={1} key={i}>
                  <span className="font-weight-black">
                    {status.description ? `${status.text}: ` : `${status.text}`}
                  </span>
                  {status.description}
                </Box>
              ))}
          </Box>
        }
        color="action"
        placement="top"
      >
        <InfoIcon />
      </Tooltip>
    </>
  );
};

export default StatusHeader;
