import React from 'react';
import { DataGrid, GridRowParams } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { FormattedItem, FormattedOrder } from 'store/modules/orders/types';
import { getStatusClass, isIndividualCheckboxDisabled } from 'utils/helpers';
import { GenericItemTableProps } from '../types';

const GenericItemTable: React.FC<GenericItemTableProps> = function ({
  order,
  items,
  rows,
  columns,
  hasSelections,
  selected,
  handleSelectionUpdate,
  handleAttemptToSelect,
}) {
  return (
    <Box
      sx={{
        overflowX: 'auto',
        overflowY: 'hidden',
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        isRowSelectable={(params: GridRowParams) =>
          isIndividualCheckboxDisabled(
            params.row.status,
            params.row.claim_status,
            selected,
            hasSelections
          )
        }
        onSelectionModelChange={(selection) => {
          handleSelectionUpdate(
            selection.map((index) => items[Number(index)]) as FormattedItem[],
            order as FormattedOrder
          );
        }}
        selectionModel={selected.map((selection) =>
          items.findIndex((item) => item.id === selection.id)
        )}
        onCellClick={handleAttemptToSelect}
        onColumnHeaderClick={handleAttemptToSelect}
        checkboxSelection
        disableColumnFilter
        disableColumnSelector
        autoHeight
        hideFooter
        disableColumnMenu
        getRowClassName={(params) =>
          getStatusClass(params.row.status, params.row.claim_status)
        }
      />
    </Box>
  );
};

export default GenericItemTable;
