import React, { FC } from 'react';
import { generateTranslation } from 'utils/translation/i18nextTranslation';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useTheme } from 'styles';
import { AppType } from 'utils/translation/types';

const CustomHelmetProvider: FC<{}> = function ({ children }) {
  const translation = generateTranslation('app') as AppType;
  const theme = useTheme();
  return (
    <HelmetProvider>
      <Helmet>
        <title>{translation.appTitle}</title>
        <style type="text/css">
          {`.embeddedServiceHelpButton .helpButton .uiButton {
            background-color: ${theme.palette.primary.main} !important;
            font-family: "Arial", sans-serif !important;
          }
          .embeddedServiceHelpButton .helpButton .uiButton:focus {
            outline: 1px solid ${theme.palette.primary.main} !important;
          }`}
        </style>
      </Helmet>
      {children}
    </HelmetProvider>
  );
};

export default CustomHelmetProvider;
