// react
import React, { useState, VFC } from 'react';

// redux
import { selectLinks, selectUrlProduct } from 'store/modules/config/selectors';

// packages
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// hooks
import { useAppSelector } from 'store/hooks';
import { useHistory } from 'react-router-dom';
import { useClearReduxPersistedState } from 'hooks/useClearReduxPersistedState';

// styles
import styles from 'components/Footer/V1/styles';

// components
import Modal from 'components/Modal';

// utils
import { generateTranslation } from 'utils/translation/i18nextTranslation';
import { getProductValue } from 'store/utils/hooks';

// types
import type { AppType } from 'utils/translation/types';
import { ProductEnum } from 'types';

const Footer: VFC = function () {
  // redux state
  const links = useAppSelector(selectLinks);
  const product = getProductValue(useAppSelector(selectUrlProduct));

  // hooks
  const { clearReduxPersistedStateAndRedirect } = useClearReduxPersistedState();
  const history = useHistory();

  // state
  const [showModal, setShowModal] = useState(false);

  // constants
  const isCertificatePage = history.location.pathname.includes('/certificate/');
  const isRaas =
    product === ProductEnum.enhancedrefundprogram ||
    product === ProductEnum.optionalRAAS;
  const translation = generateTranslation('app') as AppType;

  // functions
  const openModal = () => {
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const startOver = async () => {
    setShowModal(false);

    clearReduxPersistedStateAndRedirect();
  };

  return (
    <Stack
      justifyContent="center"
      component="footer"
      height={{ xs: '297px', sm: '325px' }}
      mt={5}
      style={{
        ...styles.footer,
        ...(isCertificatePage && {
          backgroundColor: '#f6f7f7',
        }),
      }}
    >
      <Modal
        show={showModal}
        onDecline={closeModal}
        onSubmit={startOver}
        title={translation.footer.modal.title}
        declineText={translation.footer.modal.declineText}
        submitText={translation.footer.modal.submitText}
        modalText={translation.footer.modal.text}
      />
      <Stack sx={styles.layoutContainer}>
        <Typography
          variant="h1"
          className="font-weight-bold text-center"
          sx={{
            ...styles.footerText,
            color: 'white',
            ...(isCertificatePage && {
              color: '#8a8b8a',
              fontSize: '16px',
            }),
          }}
        >
          {translation.footer.title} {translation.footer.text}
        </Typography>
        <div className="text-center">
          <Button
            variant="contained"
            className="btn-style font-weight-bold"
            sx={{
              ...styles.btnStyle,
              mt: 3,
              ...(isCertificatePage && {
                backgroundColor: '#3aa0ff',
              }),
            }}
            onClick={openModal}
          >
            {translation.footer.button}
          </Button>
        </div>
        {translation.footer.disclaimer ? (
          <Typography
            variant="body1"
            className="white--text text-center"
            sx={styles.disclaimer}
          >
            {translation.footer.disclaimer}
          </Typography>
        ) : null}
        <Stack
          direction="row"
          sx={styles.privacyContainer}
          justifyContent="flex-end"
        >
          {!isRaas && (
            <Link
              href={links.contactUsUrl}
              underline="none"
              target="_blank"
              style={styles.privacyAnchor}
            >
              <span
                style={{
                  ...styles.privacyText,
                  ...(isCertificatePage && {
                    borderBottom: 'none',
                    color: '#8a8b8a',
                  }),
                }}
              >
                {translation.footer.contactLink}
              </span>
            </Link>
          )}

          <Link
            href={links.privacyPolicyUrl}
            underline="none"
            target="_blank"
            style={styles.privacyAnchor}
          >
            <span
              style={{
                ...styles.privacyText,
                ...(isCertificatePage && {
                  borderBottom: 'none',
                  color: '#8a8b8a',
                }),
              }}
            >
              {translation.footer.privacyLink}
            </span>
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Footer;
