import { Theme } from 'styles';

export default {
  card: {
    position: 'relative',
    display: 'flex',
    height: '100%',
    border: '5px solid transparent',
    '&:hover': {
      cursor: 'pointer',
      boxShadow: '2px 3px 2px 1px #ccc',
    },
    '&.active': {
      border: '5px solid inherit',
      boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.24)',
      transition: 'border 0.2s',
      borderColor: (theme: Theme) => theme.palette.primary.main,
    },
  },
  icon: {
    position: 'absolute',
    top: '5px',
    right: '5px',
  },
  iconContainer: {
    height: '60px',
    display: 'flex',
    alignItems: 'center',
  },
  descriptionContainer: {
    height: { sm: '90px' },
    flex: { xs: '1 1 0', sm: 'unset' },
  },
  descriptionText: {
    lineHeight: '1.2',
    position: 'relative',
  },
};
