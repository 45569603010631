// redux
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ErrorData } from 'store/errorInterceptor';
import api from 'store/modules/reasons/api';

// types
import type { Reason } from 'store/modules/reasons/types';

export const getReasons = createAsyncThunk(
  'reasons/getReasons',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.getReasons('VOID');

      return response.data.map((item: Reason) => ({
        key: item.name,
        value: item.userform,
        hasComment: item.optional_comment,
      }));
    } catch (e: any) {
      if (e?.response?.data) {
        const errorData = e.response.data as ErrorData;
        const errorMessage = errorData?.detail || errorData?.error?.message;
        return rejectWithValue(errorMessage);
      }

      return rejectWithValue('Error fetching reasons');
    }
  }
);
