// utils
import { isObject } from 'utils/object';

const hasOwnProperty = (obj: any, prop: string) =>
  Object.prototype.hasOwnProperty.call(obj, prop);

export const keyOverrideMerge = (product: any, translationOverride: any) => {
  const translation = { ...translationOverride };
  for (const key in translationOverride) {
    if (hasOwnProperty(translationOverride, key)) {
      const element = translationOverride[key];
      if (isObject(element)) {
        translation[key] = keyOverrideMerge(product, element);
      } else if (containsTranslationOverride(element)) {
        const keys = getPropKeys(element);
        translation[key] = getValue(keys, product);
      }
    }
  }
  return translation;
};

export const takeTranslationFromObject = (prop: string, verbiages: any) => {
  const keys = getPropKeys(prop);
  return getValue(keys, verbiages);
};

const getValue = (keys: string[], product: any): string => {
  if (keys.length > 0)
    if (hasOwnProperty(product, keys[0])) {
      const element = product[keys[0]];
      if (isObject(element)) return getValue(keys.slice(1), element);

      return element;
    }
  // eslint-disable-next-line no-console
  console.log(
    `key (${keys.join('.')}) not found in translation override object`,
    product
  );
  return '';
};

const getPropKeys = (prop: string): string[] =>
  prop.substring(prop.indexOf('.') + 1, prop.lastIndexOf('}')).split('.');

export const containsTranslationOverride = (prop: string | undefined) =>
  Boolean(prop && prop.indexOf('{TRANSLATION.') >= 0);
