// react
import React, { VFC } from 'react';

// components
import CircularProgress from 'components/CircularProgress';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
} as const;

const Loader: VFC = function () {
  return (
    <div style={{ height: '100vh', position: 'relative' }}>
      <CircularProgress style={style} />
    </div>
  );
};

export default Loader;
