export default {
  appComponent: {
    title: 'Continue With Your Unfinished Refund request?',
    appTitle: 'Refunds Form',
    footer: {
      title: 'Need to Start Your Refund request',
      disclaimer: '',
    },
  },
  filePage: {
    title: 'File a Refund request',
  },
  orderSelectionPage: {
    consentText:
      'By checking this box you consent to the processing of your personal information under our privacy policy (available below).',
    button: 'Start Refund Request',
    noOrders: 'No refund protection found.',
    rules: {
      row1: '1) You may select one or more issued ID(s) for each refund request.',
      row2: '2) You cannot select ID(s) from separate orders.',
    },
    header: {
      row2: "Please select the ID(s) for which you'd like to start a refund.",
    },
    itemTable: {
      header: 'Covered Amount',
    },
  },
  emailSent: {
    description:
      "Click the link, and you'll be signed in. This is a one-time use link and expires in 4 hours.",
  },
  reviewPage: {
    header: {
      description:
        'Below, please review the information you provided for accuracy. Providing detailed information assists us in expediting your refund request. Once you confirm all is correct, please sign your name in the "Sign Here" box and then click "Submit Refund Request".',
    },
    sign: {
      button: 'Submit Refund Request',
      cancel: '',
      note: '',
      claims: {
        text: "By clicking 'Submit Refund' you certify that all information on this refund request is accurate and truthful under  penalty of perjury.  FanShield, RegShield, and TourShield are marketing product names for the ticket event programme Refund Protection. Terms, Conditions,",
        footer:
          ' and Coverage may vary. See Application and Refund Protection for full details. You can view Protecht, ',
        floridaText: '',
        link: {
          text: {
            row2: 'updates concerning your Refund Protection.',
          },
        },
      },
    },
    reviewTable: {
      total: 'Total Refund Amount: ',
      title: 'Description',
      covered: 'Refundable Amount',
    },
  },
  addressForm: {
    labels: {
      city: 'City/Town',
      state: 'Province/Region',
      zip_code: 'Postal Code',
    },
  },
  thankYouPage: {
    header: {
      description: {
        row2: 'Refund request',
      },
      title: {
        submitted: 'Thank You, Have a Great Day!',
        notSubmitted: 'Thank You for Filing Your Refund Request.',
      },
    },
    description:
      'Please retain your refund request number above for future reference. You will receive a follow-up email within the next 24 hours. If you have additional questions after receiving that email, please ',
  },
};
