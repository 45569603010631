// utils
import { testingEnv } from 'utils/env';
import { getKeyByValue } from 'utils/object';

// types
import {
  ClaimStatusType,
  FormattedItem,
  ItemStatusType,
  ItemStatusEnum,
} from 'store/modules/orders/types';
import type { ProductType } from 'types';

export const formattedCost = (
  valueToFormat: number | bigint,
  locale: string,
  currency: string
) => {
  const formattedLocale = locale.replace('_', '-');
  return new Intl.NumberFormat(formattedLocale, {
    style: 'currency',
    currency,
  }).format(valueToFormat);
};

export const filterAcceptableItems = (item: FormattedItem) =>
  item.status === ItemStatusEnum.Issued && !item.claim_status;

export const getClient = (hostname: string) => {
  const regex = /^(?:(.*?)\.)?([^.]+\.[^.]+)$/;
  const match = hostname.match(regex);

  if (match) {
    const client = match[1] ? match[1].split('.') : null;
    const fullSecondLevelDomain = match[2];
    const lastIndex = fullSecondLevelDomain.lastIndexOf('.');
    const secondLevelDomain = fullSecondLevelDomain.slice(0, lastIndex);
    const topLevelDomain = fullSecondLevelDomain.slice(lastIndex + 1);
    const environment = client && client.length >= 1 && client[1];
    const shouldReturnClient =
      client &&
      client.length > 0 &&
      // if the first item is a testing environment or "refund", no client should be set
      ![...testingEnv, 'refund'].includes(client[0].toLowerCase());

    return {
      client:
        shouldReturnClient && client && client.length > 0 ? client[0] : null,
      environment: environment ?? null,
      secondLevelDomain,
      topLevelDomain,
    };
  }
  return {
    client: null,
    environment: null,
    secondLevelDomain: null,
    topLevelDomain: null,
  };
};

const getProductSlugMap = () =>
  JSON.parse(process.env.REACT_APP_PRODUCT_SLUG_MAPPING || '') || {
    product: '',
  };

export const getProductSlug = (product?: ProductType | string) => {
  if (!product) return;

  const productSlugMap = getProductSlugMap();

  return productSlugMap[product];
};

export const getProductFromProductSlug = (productSlug?: string) => {
  if (!productSlug) return;

  const productSlugMap = getProductSlugMap();

  return getKeyByValue(productSlugMap, productSlug);
};

export const getStatusClass = (
  status: ItemStatusType,
  claim_status: ClaimStatusType
) => {
  if (claim_status || status !== ItemStatusEnum.Issued) return 'grey--text';

  return '';
};

export const getUrlDomains = () => getClient(window.location.hostname);

export const generateHelpLink = (productInUrl: string | undefined) => {
  const baseUrl = 'https://help.requestmyrefund.com';

  if (!productInUrl) return baseUrl;

  const { client } = getUrlDomains();

  switch (productInUrl) {
    case 'enhancedrefundprogram':
    case 'optionalRAAS':
      if (!client) return baseUrl;
      return `${baseUrl}/?c=${client}&pr=raas`;
    case 'fanshield':
      return `${baseUrl}/general`;
    default:
      return `${baseUrl}/${productInUrl}`;
  }
};

export const getFlowTypeAndProduct = (url: string): any => {
  const urlSegments = url.match(/[a-zA-Z0-9-]+/g);
  let productSegment: string | null = null;
  let typeSegment: string | null = null;

  if (urlSegments?.length) {
    [productSegment, typeSegment] = urlSegments;
  }

  return {
    product: productSegment,
    type: typeSegment,
  };
};

// only used in cancellation and refund flows.
export const isIndividualCheckboxDisabled = (
  status: ItemStatusType,
  claim_status: ClaimStatusType,
  selected: FormattedItem[],
  hasSelections: boolean
) => {
  const thisTableBoolean = selected.length > 0 && !hasSelections;

  let result = true;

  if (!claim_status && status === ItemStatusEnum.Issued) result = false;

  if (thisTableBoolean) result = true;

  return !result;
};
