import React, { FC } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { SxProps, Theme } from '@mui/material/styles';

const AppCard: FC<{
  elevation?: number;
  title?: string;
  showHeader?: boolean;
  headerComponent?: JSX.Element;
  sx?: SxProps<Theme>;
  padded?: boolean;
}> = function ({
  elevation = 1,
  showHeader = true,
  padded = false,
  title,
  headerComponent,
  children,
  sx,
}) {
  return (
    <Card
      elevation={elevation}
      sx={{
        '& .MuiCardContent-root:last-child': {
          pb: 3,
        },
        ...sx,
      }}
    >
      {showHeader && (
        <CardHeader
          sx={{
            bgcolor: 'primary.main',
            fontSize: 16,
            color: 'white',
            fontWeight: 'bold',
            textAlign: 'left',
          }}
          disableTypography
          title={title}
          subheader={headerComponent}
        />
      )}
      {padded ? <CardContent>{children}</CardContent> : children}
    </Card>
  );
};

export default AppCard;
