// react
import React, { useCallback, useEffect, VFC } from 'react';

// packages
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// redux
import { pushMessageAlert } from 'store/modules/alert/slice';
import {
  selectFlowType,
  selectUrlProduct,
} from 'store/modules/config/selectors';
import {
  selectFileComplete,
  selectEmailError,
  selectEmailLoading,
  selectEmailSuccess,
  selectLinkEmail,
} from 'store/modules/email/selectors';
import { sendEmailToken } from 'store/modules/email/thunks';

// hooks
import { useAppDispatch, useAppSelector } from 'store/hooks';

// components
import CircularProgress from 'components/CircularProgress';
import HeaderDescription from 'components/HeaderDescription';

// utils
import { generateTranslation } from 'utils/translation/i18nextTranslation';

// types
import type { EmailSentType } from 'utils/translation/types';

const EmailSentContent: VFC = function () {
  // redux state
  const email = useAppSelector(selectLinkEmail);
  const emailLoading = useAppSelector(selectEmailLoading);
  const emailError = useAppSelector(selectEmailError);
  const emailSuccess = useAppSelector(selectEmailSuccess);
  const fileComplete = useAppSelector(selectFileComplete);
  const productInUrl = useAppSelector(selectUrlProduct);
  const type = useAppSelector(selectFlowType);

  // hooks
  const dispatch = useAppDispatch();
  const history = useHistory();

  // functions
  const resendEmail = useCallback(async () => {
    await dispatch(sendEmailToken(email));
    if (emailError) {
      return dispatch(
        pushMessageAlert({
          message: emailError,
          type: 'error',
        })
      );
    }
    return dispatch(
      pushMessageAlert({
        message: emailSuccess,
        type: 'success',
      })
    );
  }, [dispatch, email, emailError, emailSuccess]);

  useEffect(() => {
    if (!fileComplete) {
      history.push(`/${productInUrl}/${type}`);
    }
  }, [fileComplete, history, type, productInUrl]);

  // translations
  const translation = generateTranslation('emailSent') as EmailSentType;

  return (
    <Stack>
      <Box px={{ sx: 2, sm: 0 }}>
        <HeaderDescription title={translation.header}>
          <Typography marginTop={3}>
            {translation.confirmation}
            <span className="font-weight-bold">{email}</span>
          </Typography>
          <Typography marginTop={3}>{translation.description}</Typography>
          <Typography marginTop={3}>
            {translation.request}
            {!emailLoading ? (
              <Link component="button" underline="none" onClick={resendEmail}>
                {translation.routerLink}
              </Link>
            ) : (
              <CircularProgress
                size={25}
                thickness={5}
                sx={{ display: 'inline-flex', verticalAlign: 'middle' }}
              />
            )}
            {translation.spam}
          </Typography>
          <Typography marginTop={3}>
            {translation.wrong}
            <Link
              underline="none"
              component={RouterLink}
              to={`/${productInUrl}/${type}`}
            >
              {translation.button}
            </Link>
          </Typography>
        </HeaderDescription>
      </Box>
    </Stack>
  );
};

export default EmailSentContent;
