/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { createFeedback } from './thunks';
import { ThanksState } from './types';

const initialState: ThanksState = {
  feedback: {
    status: 'idle',
  },
}

export const ThanksSlice = createSlice({
  name: 'thanks',
  initialState,
  reducers: {
    clearThanksState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(createFeedback.fulfilled, (state, { payload }) => {
      state.feedback = {
        status: 'success',
        successMessage: 'Feedback Sent!'
      }
    })
    builder.addCase(createFeedback.rejected, (state, action) => {
      const error = action.payload as string;
      state.feedback = {
        status: 'error',
        errorMessage: error
      };
    })
    builder.addCase(createFeedback.pending, (state, _) => {
      state.feedback.status = 'loading';
    })
  }
});

export const {
  clearThanksState,
} = ThanksSlice.actions;

export default ThanksSlice.reducer;
/* eslint-enable no-param-reassign */
