import { authApi } from 'store/baseApi';

const sendEmailToken = (body: {
  email: string;
  products: string[];
  type: string;
}) => authApi.post(`customer`, body);

const api = { sendEmailToken };

export default api;
