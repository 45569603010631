import React, { VFC, useState, useEffect } from 'react';
import { generateTranslation } from 'utils/translation/i18nextTranslation';
import { ItemTableWrapperType } from 'utils/translation/types';
import Typography from '@mui/material/Typography';
import { FormattedItem, FormattedOrder } from 'store/modules/orders/types';
import Card from 'components/Card';
import { dateSlashes } from 'utils/date';
import ItemTable from './ItemTable';
import { ItemTableWrapperProps } from '../types';

const ItemTableWrapper: VFC<ItemTableWrapperProps> = function ({
  isDisabled,
  order,
  items,
  selected,
  updateSelected,
}) {
  const [showNonSelectableError, setShowNonSelectableError] = useState(false);
  const [isTableLocked, setIsTableLocked] = useState(false);
  const [attemptSelection, setAttemptSelection] = useState(false);
  const [fireSelectionUpdate, setFireSelectionUpdate] = useState<{
    trigger: boolean;
    items: FormattedItem[];
    order: FormattedOrder;
  }>();

  useEffect(() => {
    if (isDisabled) {
      setIsTableLocked(true);
    } else {
      setIsTableLocked(false);
    }
    setAttemptSelection(false);
    setShowNonSelectableError(false);
  }, [isDisabled]);

  useEffect(() => {
    if (isTableLocked) {
      if (attemptSelection) {
        setShowNonSelectableError(true);
      } else {
        setShowNonSelectableError(false);
      }
    }
  }, [attemptSelection, isTableLocked]);

  useEffect(() => {
    if (fireSelectionUpdate?.trigger) {
      if (!isTableLocked) {
        updateSelected(fireSelectionUpdate?.items, fireSelectionUpdate.order);
      }
      setFireSelectionUpdate(undefined);
    }
  }, [fireSelectionUpdate, isTableLocked, updateSelected, attemptSelection]);

  const handleSelectionUpdate = (
    items: FormattedItem[],
    order: FormattedOrder
  ) => {
    setFireSelectionUpdate({
      trigger: true,
      items,
      order,
    });
  };

  const handleAttemptToSelect = () => {
    setAttemptSelection(true);
  };

  const translation = generateTranslation(
    'itemTableWrapper'
  ) as ItemTableWrapperType;

  return (
    <>
      {isDisabled && showNonSelectableError && (
        <Typography className="text-xs-center red--text">
          {translation.error}
        </Typography>
      )}

      <Card
        sx={{
          mb: 3,
          ...(isTableLocked && {
            opacity: '0.4',
            cursor: 'not-allowed',
          }),
        }}
        title={`${dateSlashes({ item: order?.created })} - ${
          order.client_name
        } - Order: ${order.reference_id}`}
      >
        <ItemTable
          order={order}
          items={items}
          selected={selected}
          handleSelectionUpdate={handleSelectionUpdate}
          handleAttemptToSelect={handleAttemptToSelect}
        />
      </Card>
    </>
  );
};

export default ItemTableWrapper;
