import React, { VFC } from 'react';
import { ProgressBarStepProps } from 'components/ProgressBar';
import MuiStepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel';
import { StepperConnector, AppStepperIcon } from './components';

type StepperProps = {
  currentStep: number;
  steps: ProgressBarStepProps[];
};

const Stepper: VFC<StepperProps> = function ({ currentStep, steps }) {
  return (
    <MuiStepper
      activeStep={currentStep}
      alternativeLabel
      connector={<StepperConnector />}
      sx={{
        flex: '1 1 auto',
        maxWidth: '600px',
      }}
    >
      {steps.map((step, index) => (
        <Step key={index}>
          <StepLabel
            sx={{
              p: 3,
              [`.${stepLabelClasses.labelContainer}`]: {
                xs: {
                  display: 'none',
                },
                md: {
                  display: 'block',
                },
              },
              [`.${stepLabelClasses.label}.${stepLabelClasses.alternativeLabel}`]:
              {
                mt: 2,
              },
            }}
            StepIconComponent={AppStepperIcon}
          >
            {step.text}
          </StepLabel>
        </Step>
      ))}
    </MuiStepper>
  );
};

export default Stepper;
