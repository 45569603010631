import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'store/types';
import { getProductValue } from 'store/utils/hooks';
import { selectFlowType, selectUrlProduct } from '../config/selectors';
import { ItemStatusEnum, ProductOrder } from './types';
import { Flow, FlowType } from '../config/types';
import api from './api';

export const getOrders = createAsyncThunk(
  'orders/getorders',
  async (
    props: {
      url: string | null;
    },
    thunkApi
  ) => {
    const { url } = props;
    const product = getProductValue(
      selectUrlProduct(thunkApi.getState() as RootState)
    );
    const type = selectFlowType(thunkApi.getState() as RootState);

    if (!product)
      return thunkApi.rejectWithValue({
        error: { message: 'product is mandatory' },
      });

    try {
      const response = await api.getOrders(product, url);

      const filteredItems = filterOrders(response?.data?.items, type);
      return { data: { ...response.data, items: filteredItems } };
    } catch (e) {
      return thunkApi.rejectWithValue('Error fetching Orders');
    }
  }
);

function filterOrders(orders: ProductOrder[], type: FlowType) {
  let filteringCondition: (keyof typeof ItemStatusEnum)[] | null = null;
  if (type === Flow.recharge) {
    filteringCondition = ['On Hold'];
  }
  if (type === Flow.billing) {
    filteringCondition = ['Insufficient Address'];
  }
  return filteringCondition
    ? orders
        .map((currentOrder: ProductOrder) => {
          const filteredItems = currentOrder.items.filter((i) =>
            (filteringCondition as (keyof typeof ItemStatusEnum)[]).includes(
              i.status
            )
          );
          return { ...currentOrder, items: filteredItems };
        })
        .filter((o) => o.items.length > 0)
    : orders;
}
