import React, { VFC } from 'react';
import Layout from 'components/Layout';
import Panel from 'components/Panel';
import Typography from '@mui/material/Typography';
import { Controller, UseFormReturn } from 'react-hook-form';
import TextField from '@mui/material/TextField';

export type InputPanelProps = {
  name: string;
  description?: string;
  label?: string;
  formApi: UseFormReturn<any, any>;
};

export const InputPanel: VFC<InputPanelProps> = function ({
  description,
  label,
  name,
  formApi: { control },
}) {
  return (
    <Panel>
      <Layout>
        <Typography mb={2}>{description}</Typography>
        <Controller
          name={name}
          control={control}
          render={({
            field: { onChange, value, ref },
            fieldState: { error },
          }) => (
            <TextField
              inputRef={ref}
              helperText={error?.message || ' '}
              error={Boolean(error)}
              onChange={onChange}
              value={value}
              label={label || ''}
              variant="filled"
              name={name}
            />
          )}
        />
      </Layout>
    </Panel>
  );
};
