import React from 'react';
import CircularProgress, {
  CircularProgressProps,
} from '@mui/material/CircularProgress';

const AppCircularProgress = function (props: CircularProgressProps) {
  return <CircularProgress data-testid="loading-spinner" {...props} />;
};

export default AppCircularProgress;
