// react
import React from 'react';

// packages
import Card from '@mui/material/Card';

const Panel = function ({ children }) {
  return (
    <Card
      sx={{
        padding: {
          xs: 3,
          sm: '10px',
        },
        mx: {
          xs: 2,
          sm: 0,
        },
        backgroundColor: 'rgba(250, 250, 250, 0.902)',
      }}
    >
      {children}
    </Card>
  );
};

export default Panel;
