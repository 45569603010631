// react
import React, { ElementType, VFC } from 'react';

// packages
import { UseFormReturn } from 'react-hook-form';

// redux
import { resolveFormComponentVerbiages, useAppSelector } from 'store/hooks';
import { getEntireForm } from 'store/modules/forms';

// components
import { AddressPanel } from 'components/FormBuilder/components/AddressPanel';
import { CertificateCoveragePage } from 'components/CertificateCoveragePage';
import { CreditCardPanel } from 'components/FormBuilder/components/CreditCardPanel';
import { DatePanel } from 'components/FormBuilder/components/DatePanel';
import { DecisionPanel } from 'components/FormBuilder/components/DecisionPanel';
import { DescriptionPanel } from 'components/FormBuilder/components/DescriptionPanel';
import { FileUploadPanel } from 'components/FormBuilder/components/FileUploadPanel';
import HeaderDescription from 'components/HeaderDescription';
import { InputPanel } from 'components/FormBuilder/components/InputPanel';
import PerilsPanel from 'components/FormBuilder/components/PerilsPanel';
import { RadioGroupPanel } from 'components/FormBuilder/components/RadioGroup';
import ReviewPage from 'components/ReviewPage';
import { TextAreaPanel } from 'components/FormBuilder/components/TextArea';
import ThankYouPage from 'components/ThankYouPage';
import { UpdateBillingAddressPanel } from 'components/FormBuilder/components/UpdateBillingAddressPanel';

// types
import type {
  AddressPanelType,
  CreditCardType,
  DatePanelType,
  DecisionPanelType,
  DescriptionPanelType,
  FileUploadPanelType,
  HeaderDescriptionType,
  InputPanelType,
  MultiSelectType,
  StepSubmitButtonType,
  TextAreaType,
  UpdateBillingAddressPanelType,
} from 'utils/translation/types';
import type {
  AddressPanelConfigProps,
  ComponentProps,
  CreditCardConfigProps,
  DatePanelConfigProps,
  DecisionPanelConfigProps,
  FileUploadPanelConfigProps,
  InputPanelConfigProps,
  MultiSelectProps,
  PerilsPanelConfigProps,
  TextAreaConfigProps,
  VerbiageTypes,
} from 'types';
import type { StepComponent } from 'components/FormBuilder/types';

const GenericFormField: VFC<{
  formApi: UseFormReturn<any, any>;
  props?: ComponentProps;
  setIsSubmitting: (value: boolean) => void;
  StepSubmitButton: ElementType;
  type: StepComponent;
  verbiages: VerbiageTypes;
}> = function ({
  formApi,
  props,
  setIsSubmitting,
  StepSubmitButton,
  type,
  verbiages,
}) {
  // redux state
  const form = useAppSelector(getEntireForm);

  switch (type) {
    case 'HeaderDescription': {
      // constants
      const translation = resolveFormComponentVerbiages(
        verbiages,
        formApi.getValues()
      ) as HeaderDescriptionType;

      return (
        <HeaderDescription
          description={translation.description}
          footer={translation.footer}
          title={translation.title}
        />
      );
    }

    case 'InputPanel': {
      // constants
      const p = props as InputPanelConfigProps;
      const translation = resolveFormComponentVerbiages(
        verbiages,
        formApi.getValues()
      ) as InputPanelType;

      return (
        <InputPanel
          description={translation.description}
          formApi={formApi}
          label={translation.placeholder}
          name={p.name}
        />
      );
    }

    case 'DatePanel': {
      // constants
      const p = props as DatePanelConfigProps;
      const translation = resolveFormComponentVerbiages(
        verbiages,
        formApi.getValues()
      ) as DatePanelType;

      return (
        <DatePanel
          description={translation.description}
          displayTime={p.displayTime}
          formApi={formApi}
          name={p.name}
        />
      );
    }

    case 'DecisionPanel': {
      // constants
      const p = props as DecisionPanelConfigProps;
      const pathname = window.location.pathname.split('/');
      const step = pathname[pathname.length - 1];
      const translation = resolveFormComponentVerbiages(
        verbiages,
        formApi.getValues()
      ) as DecisionPanelType;

      return (
        <DecisionPanel
          childrenComponents={p.childrenComponents}
          confirm={translation.confirm}
          courtesyMessage={translation.courtesyMessage}
          description={translation.description}
          displayChildrenOnYes={p.displayChildrenOnYes}
          formApi={formApi}
          name={p.name}
          reject={translation.reject}
          setIsSubmitting={setIsSubmitting}
          StepSubmitButton={StepSubmitButton}
          storedValue={form[step]?.[p.name]}
        />
      );
    }

    case 'AddressPanel': {
      // constants
      const p = props as AddressPanelConfigProps;
      const translation = resolveFormComponentVerbiages(
        verbiages,
        formApi.getValues()
      ) as AddressPanelType;

      return (
        <AddressPanel
          description={translation.description}
          formApi={formApi}
          name={p.name}
        />
      );
    }

    case 'UpdateBillingAddressPanel': {
      // constants
      const p = props as AddressPanelConfigProps;
      const translation = resolveFormComponentVerbiages(
        verbiages,
        formApi.getValues()
      ) as UpdateBillingAddressPanelType;

      return (
        <UpdateBillingAddressPanel
          formApi={formApi}
          name={p.name}
          translation={translation}
        />
      );
    }

    case 'FileUploadPanel': {
      // constants
      const p = props as FileUploadPanelConfigProps;
      const pathname = window.location.pathname.split('/');
      const step = pathname[pathname.length - 1];
      const translation = resolveFormComponentVerbiages(
        verbiages,
        formApi.getValues()
      ) as FileUploadPanelType;

      return (
        <FileUploadPanel
          acceptance={translation.acceptance}
          contactInfoText={translation.contactInfoText}
          description={translation.description}
          document_type={p.document_type}
          footer={translation.footer}
          formApi={formApi}
          name={p.name}
          setIsSubmitting={setIsSubmitting}
          storedValue={form[step]?.[p.name]}
        />
      );
    }

    case 'StepSubmitButton': {
      // constants
      const translation = resolveFormComponentVerbiages(
        verbiages,
        formApi.getValues()
      ) as StepSubmitButtonType;

      return (
        <StepSubmitButton
          allCompleteText={translation.allCompleteText}
          text={translation.text}
        />
      );
    }

    case 'PerilsPanel': {
      // constants
      const p = props as PerilsPanelConfigProps;

      return <PerilsPanel formApi={formApi} name={p.name} />;
    }

    case 'DescriptionPanel': {
      // constants
      const translation = resolveFormComponentVerbiages(
        verbiages,
        formApi.getValues()
      ) as DescriptionPanelType;

      return (
        <DescriptionPanel
          description={translation.description}
          title={translation.title}
        />
      );
    }

    case 'TextAreaPanel': {
      // constants
      const p = props as TextAreaConfigProps;
      const translation = resolveFormComponentVerbiages(
        verbiages,
        formApi.getValues()
      ) as TextAreaType;

      return (
        <TextAreaPanel
          characterLimit={p.characterLimit}
          description={translation.description}
          formApi={formApi}
          name={p.name}
          placeholder={translation.placeholder}
        />
      );
    }

    case 'MultiSelect': {
      // constants
      const p = props as MultiSelectProps;
      const v = verbiages as MultiSelectType;

      return (
        <RadioGroupPanel
          formApi={formApi}
          label={v.label}
          name={p.name}
          optionsEndpoint={p.optionsEndpoint}
        />
      );
    }

    case 'CreditCardPanel': {
      // constants
      const p = props as CreditCardConfigProps;
      const translation = resolveFormComponentVerbiages(
        verbiages,
        formApi.getValues()
      ) as CreditCardType;

      return (
        <CreditCardPanel
          description={translation.description}
          formApi={formApi}
          name={p.name}
        />
      );
    }

    case 'ReviewPanel': {
      return <ReviewPage />;
    }

    case 'ThankYouPage': {
      return <ThankYouPage />;
    }

    case 'CertificatePage': {
      return <CertificateCoveragePage />;
    }

    default:
      // eslint-disable-next-line no-console
      console.error('Component not found', type);
      return null;
  }
};

export default GenericFormField;
