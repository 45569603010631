import React from 'react';
import * as ls from 'utils/localstorage';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { buildYupValidation } from 'utils/validation';
import { useBuilderForm } from 'hooks';
import LoadingButton from 'components/LoadingButton';
import FormBuilder from 'components/FormBuilder';
import { customErrorMessageInterceptorByStatusCodes } from 'store/errorInterceptor';
import { ComponentStructure, ConditionalSubmitProps, RequestType } from 'types';
import {
  resolvePropFromConfigToReduxState,
  resolveObjectsFromConfigToReduxState,
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  getLastUncompletedStep,
  selectAllStepsComplete,
  updateForm,
} from 'store/modules/forms';
import { stepName } from 'globalVariables';
import { StepSubmitButtonType } from 'utils/translation/types';
import {
  selectFlowType,
  selectSteps,
  selectUrlProduct,
} from 'store/modules/config/selectors';
import { setClaim } from 'store/modules/claims/slice';
import axios from 'axios';
import { isEmptyObject } from 'utils/object';
import { selectAccessToken } from 'store/modules/auth/selectors';
import { useBoolean } from 'react-use';
import V1Layout from 'layouts/V1';

const SubmitButton: React.FC<{ isSubmitting: boolean }> = function ({
  isSubmitting,
  children,
}) {
  return (
    <div className="text-center">
      <LoadingButton
        variant="contained"
        disabled={isSubmitting}
        loading={isSubmitting}
        type="submit"
        className="btn-style"
      >
        {children}
      </LoadingButton>
    </div>
  );
};

const ValidatedFormBuilder: React.FC<{
  layout: ComponentStructure[];
  stepId: number;
  lastStepIndex: number;
}> = function ({ layout, stepId, lastStepIndex }) {
  const { validation, defaultValues } = layout.reduce(buildYupValidation, {
    validation: {},
    defaultValues: {},
  });
  const type = useAppSelector(selectFlowType);
  const productInUrl = useAppSelector(selectUrlProduct);
  const history = useHistory();
  const { pathname } = useLocation();
  const lastStepName = `/${productInUrl}/${type}/${stepName}${lastStepIndex}`;
  const dispatch = useAppDispatch();
  const configSteps = useAppSelector(selectSteps);
  const allStepsComplete = useAppSelector(selectAllStepsComplete);
  const lastUncompletedStep = useAppSelector(getLastUncompletedStep);
  const token = useAppSelector(selectAccessToken) || ls.getToken();

  const formatProps = (_acc, curr: ComponentStructure) => {
    if (curr.component === 'StepSubmitButton') {
      const p = curr.props as ConditionalSubmitProps;
      if (p) {
        return {
          endpoint: String(resolvePropFromConfigToReduxState(p.endpoint)),
          submissionMapping: { ...p.submissionMapping },
          requestType: p.requestType as RequestType,
        };
      }
    }
  };

  const currentStepSubmitButtonProps = configSteps[stepId - 1].fields.reduce(
    formatProps,
    {
      endpoint: '',
      requestType: 'PUT',
      submissionMapping: {},
    }
  ) as ConditionalSubmitProps;

  const updatedDefaultValues = Object.prototype.hasOwnProperty.call(
    defaultValues,
    'date'
  )
    ? {
        ...defaultValues,
        date: extractDate(defaultValues),
      }
    : { ...defaultValues };
  const formApi = useBuilderForm(`${stepName}${stepId}`, {
    mode: 'onChange',
    defaultValues: updatedDefaultValues,
    resolver: yupResolver(yup.object(validation)),
  });
  const [isSubmitting, setIsSubmitting] = useBoolean(
    formApi.formState.isSubmitting
  );

  const onSubmitHandler = async () => {
    if (currentStepSubmitButtonProps?.endpoint) {
      setIsSubmitting(true);
      const req = {
        url: currentStepSubmitButtonProps.endpoint,
        method: currentStepSubmitButtonProps.requestType,
        data: resolveObjectsFromConfigToReduxState(
          currentStepSubmitButtonProps.submissionMapping
        ),
        headers: { Authorization: `Bearer ${token}` },
      };
      const conditionalStep = configSteps.find((step) => step.conditional);
      try {
        axios.interceptors.response.use(
          (response) => response,
          (response) =>
            // TODO: this should be moved as part of the custom file for custom messages
            // When doing custom API calls on submit button. When scope grows.
            customErrorMessageInterceptorByStatusCodes(
              response,
              [
                {
                  statusCode: 404,
                  customMessage:
                    'Address not found, please double check information.',
                },
              ],
              dispatch
            )
        );
        const res = await axios(req);
        dispatch(
          updateForm({
            name: `${stepName}${stepId}`,
            data: { response: res.data },
          })
        );
        if (isConditionalStepRequired(res.data, conditionalStep?.conditional)) {
          return history.push(
            `/${productInUrl}/${type}/${stepName}${stepId + 1}`
          );
        }
        dispatch(
          setClaim({
            status: 'success',
          })
        );

        return pathname === lastStepName
          ? history.push(`/${productInUrl}/${type}/`)
          : history.push(lastStepName);
      } catch (e: any) {
        setIsSubmitting(false);
        return dispatch(
          setClaim({
            status: 'error',
            errorMessage: e.msg ?? 'Error Occurred',
          })
        );
      }
    }
    if (allStepsComplete || pathname === lastStepName)
      return history.push(`/${productInUrl}/${type}${lastUncompletedStep}`);

    return history.push(`/${productInUrl}/${type}/${stepName}${stepId + 1}`);
  };

  return (
    <V1Layout>
      <FormBuilder
        onSubmit={formApi.handleSubmit(onSubmitHandler)}
        layout={layout}
        formApi={formApi}
        setIsSubmitting={setIsSubmitting}
        StepSubmitButton={({ text, allCompleteText }: StepSubmitButtonType) => (
          <SubmitButton isSubmitting={isSubmitting}>
            {allStepsComplete ? allCompleteText : text}
          </SubmitButton>
        )}
      />
    </V1Layout>
  );
};

export default ValidatedFormBuilder;

const isConditionalStepRequired = (
  data: any,
  conditional: string | undefined
) => {
  if (!conditional) return false;

  const props = extractPropString(conditional);
  return resolveProps(props, data);
};

const extractPropString = (searchTerm: string) => {
  const res = searchTerm.split('response.')[1].replace('}', '');
  return res.split('.');
};

const resolveProps = (props: string[], data: any) => {
  const reducer = (acc: any, curr: string) =>
    isEmptyObject(acc) ? data[curr] : acc[curr];

  return props.reduce(reducer, {});
};

const extractDate = (defaultValues: { [key: string]: string | null }) => {
  if (Object.prototype.hasOwnProperty.call(defaultValues, 'date')) {
    const defaultObjectValue = { ...defaultValues };
    const { date } = defaultObjectValue;
    if (!date) return null;

    const completeDate = date.split('T')[0];

    return completeDate;
  }

  return null;
};
