import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

function paramsToObject(
  entries: IterableIterator<[string, string]>
): Record<string, string> {
  return Array.from(entries).reduce(
    (paramsObject, [key, value]) => ({
      ...paramsObject,
      [key]: value,
    }),
    {}
  );
}

function useRouteQueryParams() {
  const { search } = useLocation();
  return useMemo(() => {
    const urlParams = new URLSearchParams(search);
    return paramsToObject(urlParams.entries());
  }, [search]);
}

export default useRouteQueryParams;
