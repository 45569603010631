// react
import React, { useMemo } from 'react';

// packages
import { DateTime } from 'luxon';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Controller, UseFormReturn } from 'react-hook-form';

// redux
import { selectSelectedItems } from 'store/modules/orders/selectors';

// hooks
import { useAppSelector } from 'store/hooks';

// components
import Layout from 'components/Layout';
import Panel from 'components/Panel';

// styles
import styles from 'styles/styles';

// utils
import { fromFormatDate, getDaysInTheFuture } from 'utils/date';

// types
import type { Control } from 'react-hook-form';

const DateTimePicker: React.VFC<{
  control: Control<any, any>;
  label: string;
  minDate: DateTime;
  name: string;
  styles?: Record<string, any>;
}> = function ({
  control,
  label,
  minDate,
  name,
  styles: additionalStyles = {},
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({
        field: { value, onChange, ...rest },
        fieldState: { error },
      }) => (
        <>
          <MuiDateTimePicker
            {...rest}
            label={label}
            maxDate={getDaysInTheFuture(1)}
            minDateTime={minDate}
            onChange={(value) => {
              if (!value || typeof value === 'string') return;

              onChange(value.toFormat('yyyy-MM-dd t'));
            }}
            slotProps={{
              textField: {
                error: Boolean(error),
              },
            }}
            timeSteps={{
              minutes: 15,
            }}
            timezone="system"
            value={fromFormatDate(value, true)}
            sx={{
              ...additionalStyles,
              '& .MuiInputBase-formControl': {
                ...styles.timePicker,
              },
            }}
          />
          <FormHelperText error={Boolean(error?.message)}>
            {error?.message ?? ' '}
          </FormHelperText>
        </>
      )}
    />
  );
};

export const DatePanel: React.VFC<{
  name: string;
  description?: string;
  formApi: UseFormReturn<any, any>;
  displayTime?: boolean;
}> = function ({
  description,
  name,
  formApi: { control },
  displayTime = false,
}) {
  // redux state
  const eventDate = useAppSelector(
    (state) => state.formsReducer.forms?.step1?.date
  );
  const items = useAppSelector(selectSelectedItems);

  // constants
  const minDateTime = DateTime.fromJSDate(new Date('2000-01-01'));
  const oldestIssuedItem = useMemo(
    () =>
      items
        .filter((i) => i.status === 'issued')
        .reduce(
          (previous, current) =>
            new Date(previous.issued) < new Date(current.issued)
              ? previous
              : current,
          { issued: '' }
        ),
    [items]
  );
  const oldestIssuedItemDateTime = DateTime.fromJSDate(
    new Date(oldestIssuedItem?.issued)
  ).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const eventDateTime = DateTime.fromJSDate(new Date(eventDate)).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  const maxDates = {
    // event date
    date: null,
    incidentDate: eventDateTime.plus({ days: 1 }),
  };
  const minDates = {
    // event date
    date: oldestIssuedItemDateTime,
    incidentDate: oldestIssuedItemDateTime,
  };

  // functions
  const getMinDate = () => {
    if (!minDates[name]) return minDateTime;

    return minDates[name];
  };

  const getMaxDate = () => {
    if (!maxDates[name]) return null;

    return maxDates[name];
  };

  return (
    <Panel>
      <Layout>
        {description && <Typography mb={2}>{description}</Typography>}
        {displayTime ? (
          <>
            <DateTimePicker
              control={control}
              label="Time you arrived"
              minDate={minDateTime}
              name="attendance_arrival_date"
            />
            <DateTimePicker
              control={control}
              label="Time you left"
              minDate={minDateTime}
              name="attendance_departure_date"
            />
          </>
        ) : (
          <Controller
            name={name}
            control={control}
            render={({
              field: { onChange, ref, value, ...rest },
              fieldState: { error },
            }) => (
              <>
                <DatePicker
                  {...rest}
                  inputRef={ref}
                  label="Date"
                  minDate={getMinDate()}
                  maxDate={getMaxDate()}
                  onChange={(value) => {
                    if (!value || typeof value === 'string') return;

                    onChange(value.toFormat('yyyy-MM-dd'));
                  }}
                  timezone="system"
                  value={fromFormatDate(value)}
                  slotProps={{
                    textField: {
                      error: Boolean(error),
                    },
                  }}
                  sx={{
                    '& .MuiInputBase-formControl': {
                      ...styles.timePicker,
                    },
                  }}
                />
                <FormHelperText error={Boolean(error?.message)}>
                  {error?.message ?? ' '}
                </FormHelperText>
              </>
            )}
          />
        )}
      </Layout>
    </Panel>
  );
};
