import React, { memo, VFC } from 'react';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowModel,
} from '@mui/x-data-grid';
import { generateTranslation } from 'utils/translation/i18nextTranslation';
import { ItemTableType } from 'utils/translation/types';
// eslint-disable-next-line import/no-extraneous-dependencies
import isEqual from 'lodash/isEqual';
import { statusColumn } from 'utils/itemTable';
import ValueTooltip from 'components/ValueTooltip';
import { ItemTableProps } from '../types';
import GenericItemTable from './RechargeGenericItemTable';

export type ItemTableGridRow = {
  id: number;
  status: string;
  claim_status: string;
  number: string;
  name: string;
  coverage_amount: string;
};

const ItemTable: VFC<ItemTableProps> = memo(
  ({
    order,
    items,
    selected,
    handleSelectionUpdate,
    handleAttemptToSelect,
  }) => {
    const translation = generateTranslation('itemTable') as ItemTableType;
    const hasSelections = selected.length > 0;

    const rows: GridRowModel<ItemTableGridRow>[] = items.map((item, index) => ({
      id: index,
      status: item.status,
      claim_status: item.claim_status,
      number: item.reference_id,
      name: item.name,
      coverage_amount: item.unit_cost_literal,
      sold_by: item.policy.sold_by,
    }));

    const defaultGridColDef = {
      sortable: false,
      hideable: false,
    };

    const columns: GridColDef<ItemTableGridRow>[] = [
      {
        ...statusColumn,
        ...defaultGridColDef,
        width: 130,
      },
      {
        field: 'number',
        headerName: 'ID',
        renderCell: (params: GridRenderCellParams<string>) => (
          <ValueTooltip value={params.value} />
        ),
        ...defaultGridColDef,
        flex: 1,
      },
      {
        field: 'name',
        headerName: 'Description',
        renderCell: (params: GridRenderCellParams<string>) => (
          <ValueTooltip value={params.value} />
        ),
        ...defaultGridColDef,
        flex: 2,
      },
      {
        field: 'coverage_amount',
        headerName: translation.header,
        ...defaultGridColDef,
        width: 200,
      },
      {
        field: 'sold_by',
        headerName: 'Sold By',
        renderCell: (params: GridRenderCellParams<string>) => (
          <ValueTooltip value={params.value} />
        ),
        ...defaultGridColDef,
        flex: 2,
      },
    ];

    return (
      <GenericItemTable
        order={order}
        items={items}
        selected={selected}
        handleSelectionUpdate={handleSelectionUpdate}
        handleAttemptToSelect={handleAttemptToSelect}
        rows={rows}
        columns={columns}
        hasSelections={hasSelections}
      />
    );
  },
  (prevProps, nextProps) => isEqual(prevProps.selected, nextProps.selected)
);

export default ItemTable;
