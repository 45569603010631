import { Flow } from 'store/modules/config/types';
import { ProductEnum } from 'types';
import claimFanshield from './claim/products/fanshield';
import claimShopG from './claim/products/shopguarantee';
import claimRaas from './claim/products/raas';
import cancellationFanshield from './cancellation/products/fanshield';
import cancellationShopG from './cancellation/products/shopguarantee';
import cancellationRaas from './cancellation/products/raas';
import certificateFanshield from './certificate/products/fanshield';
import certificateTourshield from './certificate/products/tourshield';
import certificateRegshield from './certificate/products/regshield';
import certificateShopG from './certificate/products/shopguarantee';
import certificateRaas from './certificate/products/raas';
import billingFanshield from './billing/products/fanshield';
import billingRaas from './billing/products/raas';
import billingShopG from './billing/products/shopguarantee';
import generalClaim from './claim/general';
import generalCancellation from './cancellation/general';
import generalCertificate from './certificate/general';
import generalRecharge from './recharge/general';
import rechargeFanshield from './recharge/products/fanshield';
import rechargeRaas from './recharge/products/raas';
import generalBilling from './billing/general';

export default {
  [Flow.refund]: {
    general: generalClaim,
    [ProductEnum.fanshield]: claimFanshield,
    [ProductEnum.regshield]: claimFanshield,
    [ProductEnum.tourshield]: claimFanshield,
    [ProductEnum.shopguarantee]: claimShopG,
    [ProductEnum.enhancedrefundprogram]: claimRaas,
  },
  [Flow.cancellation]: {
    general: generalCancellation,
    [ProductEnum.regshield]: cancellationFanshield,
    [ProductEnum.tourshield]: cancellationFanshield,
    [ProductEnum.fanshield]: cancellationFanshield,
    [ProductEnum.enhancedrefundprogram]: cancellationRaas,
    [ProductEnum.shopguarantee]: cancellationShopG,
  },
  [Flow.certificate]: {
    general: generalCertificate,
    [ProductEnum.regshield]: certificateRegshield,
    [ProductEnum.tourshield]: certificateTourshield,
    [ProductEnum.fanshield]: certificateFanshield,
    [ProductEnum.enhancedrefundprogram]: certificateRaas,
    [ProductEnum.shopguarantee]: certificateShopG,
  },
  [Flow.recharge]: {
    general: generalRecharge,
    [ProductEnum.fanshield]: rechargeFanshield,
    [ProductEnum.tourshield]: rechargeFanshield,
    [ProductEnum.regshield]: rechargeFanshield,
    [ProductEnum.enhancedrefundprogram]: rechargeRaas,
  },
  [Flow.billing]: {
    general: generalBilling,
    [ProductEnum.fanshield]: billingFanshield,
    [ProductEnum.regshield]: billingFanshield,
    [ProductEnum.tourshield]: billingFanshield,
    [ProductEnum.enhancedrefundprogram]: billingRaas,
    [ProductEnum.shopguarantee]: billingShopG,
  },
};
