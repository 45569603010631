import React, { VFC } from 'react';
import Typography from '@mui/material/Typography';
import Layout from 'components/Layout';
import Panel from 'components/Panel';
import AddressForm from 'components/FormBuilder/components/AddressPanel/AddressForm';
import { UseFormReturn } from 'react-hook-form';

export const AddressPanel: VFC<{
  name: string;
  description: string;
  formApi: UseFormReturn<any, any>;
}> = function ({
  description,
  name,
  formApi: { resetField, setValue, control, watch },
}) {
  return (
    <Panel>
      <Layout>
        <Typography mb={2}>{description}</Typography>
        <AddressForm
          control={control}
          name={name}
          watch={watch}
          resetField={resetField}
          setValue={setValue}
        />
      </Layout>
    </Panel>
  );
};
