import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import { sanitize } from 'dompurify';

type ParseContentProps = { content: string; style?: React.CSSProperties };

export const ParseContent: React.FC<ParseContentProps> = function ({
  content,
  style,
}) {
  const translate = ReactHtmlParser(
    sanitize(content).replaceAll(
      '<a ',
      '<a target="_blank" rel="noopener noreferrer" '
    )
  );
  return <span style={style}>{translate}</span>;
};
