import { AxiosPromise } from 'axios';
import { orderApi } from 'store/baseApi';
import { ProductType, ProductEnum } from 'types';
import { ShippoDataResponse } from '../claims/types';
import { OrdersDTO, ProductOrder, TrackingInfo } from './types';

const getOrders = async (product: ProductType, url: string | null) => {
  if (product === ProductEnum.enhancedrefundprogram) {
    const orders: AxiosPromise<OrdersDTO>[] = [
      url
        ? orderApi.get(`${product}/optional/orders${url}`)
        : orderApi.get(`${product}/optional/orders`),
      url
        ? orderApi.get(`${product}/embedded/orders${url}`)
        : orderApi.get(`${product}/embedded/orders`),
    ];
    const [optional, embedded] = await Promise.allSettled(orders);

    if (optional.status === 'rejected' && embedded.status === 'rejected') {
      throw new Error('No orders found');
    }
    const embeddedItems =
      embedded.status === 'fulfilled' ? [...embedded.value.data.items] : [];
    const embeddedValue =
      embedded.status === 'fulfilled'
        ? { ...embedded.value }
        : { ...embedded.reason };
    const optionalItems =
      optional.status === 'fulfilled' ? [...optional.value.data.items] : [];
    const mergedItems = [...embeddedItems, ...optionalItems];
    mergedItems.sort(compare);
    const response =
      optional.status === 'fulfilled'
        ? {
            ...optional.value,
            data: { ...optional.value.data, items: mergedItems },
          }
        : {
            ...embeddedValue,
            data: { ...embeddedValue.data, items: mergedItems },
          };
    return response;
  }
  return url
    ? orderApi.get(`${product}/orders${url}`)
    : orderApi.get(`${product}/orders`);
};

function compare(a: ProductOrder, b: ProductOrder) {
  if (a.created < b.created) {
    return -1;
  }
  if (a.created > b.created) {
    return 1;
  }
  return 0;
}

const validateOrdersOnshippo = async (trackingInfo: TrackingInfo[]) => {
  const promises = trackingInfo.map((info) =>
    shippoOrderCheck(info.carrier, info.tracking_number)
  );
  try {
    const responses = await Promise.all(promises);
    return responses.every(
      (res) =>
        res.data.tracking_status.status === 'PRE_TRANSIT' ||
        res.data.tracking_status.status === 'UNKNOWN' // TODO confirm that with product!
    )
      ? { voidable: true }
      : { voidable: false };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error while calling validateOrdersOnshippo');
    return { voidable: false };
  }
};

const shippoOrderCheck = (
  carrier: string | null | undefined,
  tracking_number: string | null | undefined
): AxiosPromise<ShippoDataResponse> =>
  orderApi.get(`/tracks/${carrier}/${tracking_number}`);

const api = { getOrders, validateOrdersOnshippo };

export default api;
