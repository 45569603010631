import { RootState } from 'store';
import { selectFlowType } from '../config/selectors';

export const selectPreferredLang = (state: RootState) =>
  state.claimsReducer.lang;
export const selectClaimComplete = (state: RootState) =>
  state.claimsReducer.claim.status === 'success';
export const selectClaimLoading = (state: RootState) =>
  state.claimsReducer.claim.status === 'loading';
export const selectClaimNumber = (state: RootState) =>
  state.claimsReducer.claim.id;
export const selectConfirmationVoidNumber = (state: RootState) =>
  state.claimsReducer.claim.confirmationNumber;
export const selectReviewComplete = (state: RootState) =>
  Boolean(
    selectClaimNumber(state) ||
      selectConfirmationVoidNumber(state) ||
      (state.claimsReducer.claim.status === 'success' &&
        (selectFlowType(state) === 'billing' ||
          selectFlowType(state) === 'recharge'))
  );
export const selectInterruptedSession = (state: RootState) =>
  state.claimsReducer.interruptedSession;
