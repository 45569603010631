// react
import React from 'react';

// packages
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// redux
import { selectLinks, selectUrlProduct } from 'store/modules/config/selectors';
import {
  selectCertNum,
  selectCustomer,
  selectOrders,
  selectSelectedOrder,
} from 'store/modules/orders/selectors';

// hooks
import { useAppSelector } from 'store/hooks';

// styles
import { styled } from '@mui/material/styles';

// components
import Card from 'components/Card';
import PrintButton from 'components/Print';

// utils
import { dateSlashes } from 'utils/date';
import { ParseContent } from 'utils/parser/StringToHtml';
import { generateTranslation } from 'utils/translation/i18nextTranslation';
import { getProductValue } from 'store/utils/hooks';

// types
import type { FormattedItem, FormattedOrder } from 'store/modules/orders/types';
import type { CertOfCoverageType } from 'utils/translation/types';
import { ProductEnum } from 'types';

const ColumnLayout = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  '& > *': {
    flexBasis: '50%',
    marginBottom: 0,
    marginTop: '12px',
    '&:last-child': {
      marginBottom: '12px',
    },
  },
});

const styles = {
  cardStyles: {
    border: 'solid',
    borderColor: '#d9d9d9',
    borderBottomWidth: '2px',
    borderLeftWidth: '1px',
    borderRightWidth: '1px',
    borderTopWidth: '0px',
    textAlign: 'left',
    '& .MuiCardHeader-root': {
      backgroundColor: '#3aa0ff',
      boxShadow: 'none',
      fontSize: '13px',
      paddingX: '24px',
      paddingY: '12px',
    },
  },
  termsAndConditions: {
    color: 'grey',
    display: 'block',
    marginTop: '24px',
  },
};

export const CertificateCoveragePage: React.FC = function () {
  // redux
  const cert_num = useAppSelector(selectCertNum);
  const selectedOrder = useAppSelector(selectSelectedOrder);
  const links = useAppSelector(selectLinks);
  const orders = useAppSelector(selectOrders);
  const product = getProductValue(useAppSelector(selectUrlProduct));

  // constants
  const order = selectedOrder?.uuid;
  const translation = generateTranslation(
    'certificateOfCoveragePage'
  ) as CertOfCoverageType;
  const orderSelected: FormattedOrder | undefined = orders.find(
    (o) => o.uuid === order
  );
  const item: FormattedItem | undefined = orderSelected?.items.find(
    (i) => i.policy.id === cert_num
  );

  // redux
  const customer = useAppSelector(selectCustomer(orderSelected?.uuid));

  return (
    <>
      <Stack
        mt={3}
        direction={{ xs: 'column-reverse', sm: 'row' }}
        justifyContent="space-between"
        spacing={3}
      >
        <Stack spacing={2} direction={{ xs: 'column', sm: 'row' }}>
          <Button
            component={Link}
            variant="outlined"
            target="_blank"
            href={item?.policy.certificate_of_coverage}
            sx={{
              backgroundColor: '#f5fbfe',
              borderRadius: '0.25rem',
              color: '#5aacde !important',
              textDecorationLine: 'none !important',
            }}
          >
            {translation.block1.title}
          </Button>
        </Stack>
        <div style={{ textAlign: 'right' }}>
          <PrintButton />
        </div>
      </Stack>
      <Typography textAlign="center" mt={5} mb={3}>
        {translation.description.part1}
        {translation.description.product}
        {translation.description.part2}
        <Link
          underline="always"
          target="_blank"
          href={translation.links.helpEmail || links.helpEmail}
        >
          contact us
        </Link>
        .
      </Typography>
      <Card
        elevation={0}
        title={translation.block1.cardTitle}
        padded
        sx={styles.cardStyles}
      >
        <p>
          {translation.block1.row1} #: <b>{cert_num}</b>
        </p>
        <p>
          {translation.block1.row2}:&nbsp;
          <b>
            {
              // take the premium at item level if any (Fanshield, Raas) or take at order level (ShopG)
              product !== ProductEnum.shopguarantee
                ? item?.premium_literal
                : orderSelected?.order_premium_total
            }
          </b>
        </p>
        <p>
          Effective Date:{' '}
          <b>
            {item?.issued &&
              dateSlashes({ item: item.issued, placeholder: '-' })}
          </b>
        </p>
        <p>
          Jurisdiction Issued:{' '}
          <b>{`${
            orderSelected?.jurisdiction?.region
              ? `${orderSelected.jurisdiction.region}, `
              : ''
          }${
            orderSelected?.jurisdiction?.country
              ? orderSelected.jurisdiction.country
              : ''
          }
          `}</b>
        </p>
        <p>
          Provided by: <b>{item?.policy.underwriter}</b>
        </p>
        <p>
          Sold by: <b>{item?.policy.sold_by}</b>
        </p>
        {item?.policy?.event_interruption && (
          <p>
            Event Interruption: <b>{item?.policy?.event_interruption}</b>
          </p>
        )}
        {item?.policy?.weather_inconvenience && (
          <p>
            Weather Inconvenience: <b>{item?.policy?.weather_inconvenience}</b>
          </p>
        )}
        {item?.policy?.cancel_for_any_reason && (
          <p>
            Cancel for any reason: <b>{item?.policy?.cancel_for_any_reason}</b>
          </p>
        )}
      </Card>
      <Card elevation={0} title="Orders Details" padded sx={styles.cardStyles}>
        <ColumnLayout>
          <p>
            Merchant Name:{' '}
            <b>
              {orderSelected?.client_display_name || orderSelected?.client_name}
            </b>
          </p>
          <p>
            Merchant Order: <b>{orderSelected?.reference_id}</b>
          </p>
          <p>
            Date of Payment:{' '}
            <b>
              {orderSelected?.created &&
                dateSlashes({ item: orderSelected.created, placeholder: '-' })}
            </b>
          </p>
          <p>
            Event Date:{' '}
            <b>
              {item?.event?.start_date &&
                dateSlashes({
                  item: item.event.start_date,
                  placeholder: '-',
                })}
            </b>
          </p>
          <p>
            Total Cost of Refundable Items:{' '}
            <b>
              {
                // take item cost for Fanshield, Raas or order total (ShopG)
                product !== ProductEnum.shopguarantee
                  ? item?.unit_cost_literal
                  : orderSelected?.order_subtotal_literal
              }
            </b>
          </p>
        </ColumnLayout>
      </Card>
      <Card
        elevation={0}
        title="Contact Information"
        padded
        sx={styles.cardStyles}
      >
        <ColumnLayout>
          <p>
            Full Name:{' '}
            <b>
              {customer?.last_name}, {customer?.first_name}
            </b>
          </p>
          <p>
            Email: <b>{customer?.email}</b>
          </p>
          <p>
            Address Line 1: <b>{customer?.address_1}</b>
          </p>
          <p>
            Address Line 2: <b>{customer?.address_2}</b>
          </p>
          <p>
            City: <b>{customer?.city}</b>
          </p>
          <p>
            State: <b>{customer?.state}</b>
          </p>
          <p>
            Zip Code: <b>{customer?.zip_code}</b>
          </p>
          <p>
            Country: <b>{customer?.country}</b>
          </p>
        </ColumnLayout>
      </Card>
      <ParseContent
        style={styles.termsAndConditions}
        content={item?.policy.terms_and_conditions || ''}
      />
    </>
  );
};
