import React, { useEffect, useState, VFC } from 'react';
import {
  Controller,
  Control,
  UseFormWatch,
  UseFormResetField,
  UseFormSetValue,
  FieldValues,
} from 'react-hook-form';
import { generateTranslation } from 'utils/translation/i18nextTranslation';
import { AddressFormType } from 'utils/translation/types';
import Grid from '@mui/material/Grid';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import ListSubheader from '@mui/material/ListSubheader';

import {
  getCountrySubdivisions,
  getChildSubdivisionMatchingCountryAndState,
  SubdivisionWithChildren,
  getCountriesList,
} from 'utils/location';
import { AutocompleteProps } from './types';

type AddressFormProps = {
  control: Control;
  name: string;
  watch: UseFormWatch<FieldValues>;
  resetField: UseFormResetField<any>;
  setValue: UseFormSetValue<any>;
};
const AddressForm: VFC<AddressFormProps> = function ({
  control,
  name,
  watch,
  resetField,
  setValue,
}) {
  const [countriesList, setCountriesList] = useState<AutocompleteProps[]>([]);
  const [statesFromCountry, setStatesFromCountry] = useState<
    SubdivisionWithChildren[] | ['n/a']
  >([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const translation = generateTranslation('addressForm') as AddressFormType;
  const [labels, setLabels] = useState<{
    city: string;
    state: string;
    zip_code: string;
  }>({
    city: translation.labels.city,
    state: translation.labels.state,
    zip_code: translation.labels.zip_code,
  });

  const countryFieldName = `${name}_country`;
  const address1FieldName = `${name}_address_1`;
  const address2FieldName = `${name}_address_2`;
  const cityFieldName = `${name}_city`;
  const stateFieldName = `${name}_state`;
  const zip_codeFieldName = `${name}_zip_code`;
  const firstNameFieldName = `${name}_first_name`;
  const lastNameFieldName = `${name}_last_name`;
  const country = watch(countryFieldName as any);
  const state = watch(stateFieldName as any);

  useEffect(() => {
    const countriesList = getCountriesList().map((country) => ({
      value: country.alpha2,
      label: country.name,
    }));

    setCountriesList(countriesList);
  }, []);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === countryFieldName && type === 'change') {
        resetField(stateFieldName, {
          defaultValue: '',
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, resetField, countryFieldName, stateFieldName]);

  useEffect(() => {
    if (country) {
      setLabels({
        city: translation.labels.city,
        state: translation.labels.state,
        zip_code: translation.labels.zip_code,
      });
      const states = getChildSubdivisionMatchingCountryAndState(country, state);
      setStatesFromCountry(states);

      if (states && states.length && states[0] === 'n/a') {
        setValue(stateFieldName, 'n/a');
      }
    }
  }, [
    country,
    state,
    stateFieldName,
    translation.labels.city,
    translation.labels.state,
    translation.labels.zip_code,
    resetField,
    setValue,
  ]);

  return (
    <>
      <Controller
        name={firstNameFieldName as any}
        control={control}
        render={({
          field: { onChange, value, ref, ...rest },
          fieldState: { error },
        }) => (
          <TextField
            variant="filled"
            inputRef={ref}
            {...rest}
            name={firstNameFieldName}
            helperText={error?.message || ' '}
            error={Boolean(error)}
            onChange={onChange}
            value={value}
            label="First Name"
          />
        )}
      />
      <Controller
        name={lastNameFieldName as any}
        control={control}
        render={({
          field: { onChange, value, ref, ...rest },
          fieldState: { error },
        }) => (
          <TextField
            variant="filled"
            inputRef={ref}
            {...rest}
            name={lastNameFieldName}
            helperText={error?.message || ' '}
            error={Boolean(error)}
            onChange={onChange}
            value={value}
            label="Last Name"
          />
        )}
      />
      <Controller
        name={address1FieldName as any}
        control={control}
        render={({
          field: { onChange, value, ref, ...rest },
          fieldState: { error },
        }) => (
          <TextField
            variant="filled"
            inputRef={ref}
            {...rest}
            name={address1FieldName}
            helperText={error?.message || ' '}
            error={Boolean(error)}
            onChange={onChange}
            value={value}
            label={translation.address.placeholder1}
          />
        )}
      />
      <Controller
        name={address2FieldName as any}
        control={control}
        render={({
          field: { onChange, value, ref, ...rest },
          fieldState: { error },
        }) => (
          <TextField
            variant="filled"
            inputRef={ref}
            {...rest}
            name={address2FieldName}
            helperText={error?.message || ' '}
            error={Boolean(error)}
            onChange={onChange}
            value={value}
            label={translation.address.placeholder2}
          />
        )}
      />
      <Controller
        name={countryFieldName as any}
        control={control}
        render={({
          field: { value, onChange, ref, ...rest },
          fieldState: { error },
        }) => (
          <>
            {countriesList.length && (
              <FormControl variant="filled">
                <InputLabel id="Select-Country">
                  {' '}
                  {translation.menuItem}
                </InputLabel>
                <Select
                  variant="filled"
                  sx={{
                    textAlign: 'left',
                  }}
                  inputRef={ref}
                  {...rest}
                  error={Boolean(error)}
                  value={value}
                  onChange={onChange}
                  name={countryFieldName}
                  labelId="Select-Country"
                  id="Country"
                >
                  <MenuItem disabled selected value="">
                    {translation.menuItem}
                  </MenuItem>
                  {countriesList.map((country) => (
                    <MenuItem key={`${country.value}`} value={country.value}>
                      {country.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <FormHelperText error={!!error?.message}>
              {error?.message ?? ' '}
            </FormHelperText>
          </>
        )}
      />
      <Controller
        name={cityFieldName as any}
        control={control}
        render={({
          field: { onChange, value, ref, ...rest },
          fieldState: { error },
        }) => (
          <TextField
            variant="filled"
            inputRef={ref}
            {...rest}
            name={cityFieldName}
            helperText={error?.message || ' '}
            error={Boolean(error)}
            onChange={onChange}
            value={value}
            label={labels.city}
          />
        )}
      />
      <Grid container spacing={1}>
        <Grid item xs={12} sm={6}>
          <Controller
            name={stateFieldName as any}
            control={control}
            render={({
              field: { value, onChange, ref, ...rest },
              fieldState: { error },
            }) => (
              <FormControl variant="filled">
                <InputLabel id="Select-State"> {labels.state}</InputLabel>
                <Select
                  variant="filled"
                  sx={{
                    textAlign: 'left',
                  }}
                  inputRef={ref}
                  {...rest}
                  value={value}
                  name={stateFieldName}
                  error={Boolean(error)}
                  labelId="Select-State"
                  id="State"
                  open={menuOpen}
                  onClose={() => {
                    const states = getChildSubdivisionMatchingCountryAndState(
                      country,
                      value
                    );
                    // when a value doesn't map to the statesFromCountry, it won't display a value on dropdown
                    // Because of this, when a subdivision with a child is selected, it won't display the child value.
                    // Because it doesn't map to the parents. The parents are the labels within the menu item.
                    // So I set the states as the child subdivision selected. When they open the menu, I revert it back
                    // To be the complete list of parents and possible child subdivisions.
                    setStatesFromCountry(states);
                    setMenuOpen(false);
                  }}
                  onOpen={() => {
                    setMenuOpen(true);
                    const states = getCountrySubdivisions(country);
                    setStatesFromCountry(states);
                  }}
                >
                  <MenuItem disabled selected value="">
                    {labels.state}
                  </MenuItem>
                  {statesFromCountry && statesFromCountry[0] === 'n/a' ? (
                    <MenuItem
                      key="region-not-applicable"
                      value="n/a"
                      onClick={() => {
                        onChange({
                          target: {
                            name: stateFieldName,
                            value: 'n/a',
                          },
                        });
                        setMenuOpen(false);
                      }}
                    >
                      n/a
                    </MenuItem>
                  ) : (
                    (statesFromCountry as SubdivisionWithChildren[]).map(
                      (state) =>
                        state.children ? (
                          <div key={`${state.code}`}>
                            <ListSubheader>{state.name}</ListSubheader>
                            {state.children.map(
                              (child: SubdivisionWithChildren) => (
                                <div key={`${child.code}`}>
                                  <MenuItem
                                    value={child.code.split('-')[1]}
                                    onClick={() => {
                                      onChange({
                                        target: {
                                          name: stateFieldName,
                                          value: child.code.split('-')[1],
                                        },
                                      });
                                      setStatesFromCountry(
                                        state.children as any
                                      );
                                      setMenuOpen(false);
                                    }}
                                  >
                                    {child.name}
                                  </MenuItem>
                                </div>
                              )
                            )}
                          </div>
                        ) : (
                          <MenuItem
                            key={`${state.code}`}
                            value={state.code.split('-')[1]}
                            onClick={() => {
                              onChange({
                                target: {
                                  name: stateFieldName,
                                  value: state.code.split('-')[1],
                                },
                              });
                              setMenuOpen(false);
                            }}
                          >
                            {state.name}
                          </MenuItem>
                        )
                    )
                  )}
                </Select>
                <FormHelperText error={Boolean(error?.message)}>
                  {error?.message ?? ' '}
                </FormHelperText>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <Controller
            name={zip_codeFieldName as any}
            control={control}
            render={({
              field: { onChange, value, ref, ...rest },
              fieldState: { error },
            }) => (
              <TextField
                variant="filled"
                inputRef={ref}
                {...rest}
                name={zip_codeFieldName}
                helperText={error?.message || ' '}
                error={Boolean(error)}
                onChange={onChange}
                value={value}
                label={labels.zip_code}
              />
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default AddressForm;
