import { createSlice } from '@reduxjs/toolkit';
import { getCountryAlpha2FromAlphaCode } from 'utils/location';
import { CustomerState } from './types';

const initialState: CustomerState = {
  customer: {
    id: '',
    email: '',
    first_name: '',
    last_name: '',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    zip_code: '',
    country: '',
    phone: '',
  },
};

export const customerReducer = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomer: (state, action) => {
      const { country, ...rest } = action.payload;
      const alpha2Country = getCountryAlpha2FromAlphaCode(country);
      // eslint-disable-next-line no-param-reassign
      state.customer = {
        country: alpha2Country,
        ...rest,
      };
    },
    clearState: () => initialState,
  },
});

export const { setCustomer, clearState } = customerReducer.actions;

export default customerReducer.reducer;
