export default {
  filePage: {
    title: 'Certificate of coverage details',
    description:
      'Please enter the email you purchased the certificate of coverage with.',
  },
  appComponent: {
    title: 'Continue With Your Unfinished Refund request?',
    appTitle: 'Certificate Form',
    footer: {
      title: 'Need to Start',
      disclaimer: '',
    },
  },
  emailSent: {
    description:
      "Click the link, and you'll be signed in. This is a one-time use link and expires in 4 hours.",
  },
  orderSelectionPage: {
    noOrders: 'No Certificates found.',
    rules: {
      row1: '',
      row2: '',
    },
    header: {
      title: 'Your Certificate of Coverage Order Details',
      row2: "Please select the Shopguarantee ID for which you'd like to view your certificate of coverage details.",
    },
  },
  certificateOfCoveragePage: {
    description: {
      product: 'ShopGuarantee',
      part2:
        ' to protect your upcoming experience. Below is information regarding your certificate of coverage.If you have any questions, please ',
    },
    block1: {
      cardTitle: 'Certificate of Coverage Information',
      row1: 'Certificate of Coverage',
      row2: 'Total Cost of Certificate of Coverage',
      title: 'Description of Coverage',
    },
  },
};
