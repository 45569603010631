import React, { FC } from 'react';
import MuiLoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton';

// wrapping LoadingButton children in HTML element is required to prevent application from breaking when using Translation Tools (DEV-3591 and MUI known issue reported on their docs)

const LoadingButton: FC<LoadingButtonProps> = function ({
  children,
  ...props
}) {
  return (
    <MuiLoadingButton {...props}>
      <span>{children}</span>
    </MuiLoadingButton>
  );
};

export default LoadingButton;
