// types
import type { ProductInUrlType } from 'types';
import type { FlowType } from 'store/modules/config/types';

const configs = {
  billing: {},
  cancellation: {
    shopguarantee: true,
  },
  certificate: {},
  refund: {
    shopguarantee: true,
  },
  recharge: {},
};

const calculateHasConfigType = ({
  product,
  type,
}: {
  product: ProductInUrlType;
  type: FlowType;
}) => {
  try {
    if (!(type in configs)) return null;

    const targetType = configs[type];

    if (!(product in targetType)) return false;

    return true;
  } catch (error) {
    return null;
  }
};

export default ({
  product,
  type,
}: {
  product: ProductInUrlType;
  type: FlowType;
}) => {
  // general default config if no match is found
  let config = {
    steps: [],
    submissionMapping: {},
    translationOverride: {
      general: {},
      underwriter: {},
      product: {},
    },
  };

  try {
    const hasConfigType = calculateHasConfigType({ product, type });

    // hasConfigType is null if there's no "type" match in the object
    if (hasConfigType === null) return config;

    // hasConfigType is false if there's no "product" exception match, so return the default for this type
    if (hasConfigType === false) {
      // eslint-disable-next-line global-require, import/no-dynamic-require
      config = require(`configs/${type}/index.json`);
    } else {
      // eslint-disable-next-line global-require, import/no-dynamic-require
      config = require(`configs/${type}/${product}.json`);
    }

    return config;
  } catch (error) {
    return config;
  }
};
