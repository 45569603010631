import { Underwriter } from 'types';

export type FileUploadConsent = boolean;

export type Email = string;

export type OrdersDTO = Pagination & { items: ProductOrder[] };

export type ProductOrder = {
  id: string;
  reference_id: string;
  order_subtotal: string;
  order_subtotal_literal: string;
  order_premium_total: string;
  order_premium_total_literal: string;
  created: string;
  currency: string;
  customer: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    locale: string;
    address_1: string;
    address_2: string;
    city: string;
    state: string;
    country: string;
    postal_code: string;
  };
  items: ProductItems[];
  impressions_count: number;
  client: {
    display_name?: string;
    id: string;
    name: string;
  };
  jurisdiction?: {
    country: string;
    region: string;
  };
};

// raas - fanshield
export type EventDetails = {
  id: string;
  reference_id: string;
  name: string;
  start_date: string;
  end_date: string | null;
  location: {
    venue: string;
    city: string;
    state: string;
    country: string;
  };
};

export type ProductItems = {
  id: string;
  reference_id: string;
  name: string;
  description: string;
  unit_cost: string;
  unit_cost_literal: string;
  premium?: string; // raas and fanshield cancellation
  premium_literal?: string; // raas and fanshield cancellation
  event_id?: string; // raas
  reference_url?: string | null; // raas
  image_url: string | null;
  external_url?: string; // shopG
  status: keyof typeof ItemStatusEnum;
  issued: string;
  claim_status: keyof typeof ClaimStatusEnum | '';
  reference_number: string;
  look_period?: string; // raas and fanshield cancellation
  tracking?: {
    // shopG
    created: string | null;
    number: string | null;
    carrier: string | null;
  } | null;
  policy: {
    id: string;
    underwriter: Underwriter;
    sold_by: string;
    legal_signature: string;
    certificate_of_coverage: string;
    terms_and_conditions: string;
    event_interruption?: string;
    weather_inconvenience?: string;
    is_event_interruption: boolean;
    is_weather_interruption: boolean;
    cancel_for_any_reason?: string;
  };
  event?: EventDetails; // raas and fanshield
};

export type OrdersState = {
  email: Email;
  cert_num?: string;
  fileUploadConsent: FileUploadConsent;
  selectedOrder: FormattedOrder | undefined;
  selectedItems: FormattedItem[];
  items: Items;
};

export type Pagination = {
  count: number;
};

type ProductSlugDTO = {
  status: 'success';
  data: ProductOrder[];
};

type Items =
  | {
      status: 'idle';
    }
  | {
      status: 'loading';
    }
  | {
      status: 'error';
      errorMessage: string;
    }
  | (Pagination & ProductSlugDTO);

export type FormattedOrder = {
  uuid: string;
  client_display_name?: string;
  client_name: string;
  client_id: string;
  created: string;
  reference_id: string;
  order_subtotal: string;
  order_subtotal_literal: string;
  order_premium_total: string;
  order_premium_total_literal: string;
  items: FormattedItem[];
  locale: string;
  currency: string;
  event?: EventDetail;
  jurisdiction?: {
    country: string;
    region: string;
  };
  customer: {
    address_1: string;
    city: string;
    state: string;
    country: string;
    postal_code: string;
    phone: string;
  };
};

export type EventDetail =
  | {
      name: string;
      location: string;
      date: string;
    }
  | undefined;

export type FormattedItem = {
  id: string;
  reference_id: string;
  name: string;
  description?: string; // ShopG
  unit_cost: string;
  unit_cost_literal: string;
  premium?: string; // raas and fanshield cancellation
  premium_literal?: string; // raas and fanshield cancellation
  status: ItemStatusType;
  tracking_number?: string | null; // shopG
  tracking_number_issued?: string | null; // shopG
  tracking_carrier?: string | null; // shopG
  claim_status: ClaimStatusType;
  event: EventDetails | null; // Fanshield - Raas
  issued: string;
  look_period?: string; // raas and fanshield cancellation
  policy: {
    id: string;
    underwriter: Underwriter;
    sold_by: string;
    legal_signature: string;
    certificate_of_coverage: string;
    terms_and_conditions: string;
    weather_inconvenience?: string;
    cancel_for_any_reason?: string;
    event_interruption?: string;
    is_event_interruption: boolean;
    is_weather_interruption: boolean;
  };
};

export type TrackingInfo = {
  carrier: string | null | undefined;
  tracking_number: string | null | undefined;
};

export enum ClaimStatusEnum {
  'Accepted' = 'accepted',
  'Initiated' = 'initiated',
  'Closed' = 'closed',
  'On Hold' = 'on hold',
  'Pending Documents' = 'pending documents',
  'Pending Document Review' = 'pending document review',
  'Pending Agent Review' = 'pending agent review',
  'Approved' = 'approved',
  'Good Will Payout' = 'good will payout',
  'Denied' = 'denied',
}

export type ClaimStatusType = Lowercase<keyof typeof ClaimStatusEnum> | '';

export enum ItemStatusEnum {
  'Accepted' = 'accepted',
  'Canceled' = 'canceled',
  'Insufficient Address' = 'insufficient address',
  'Issued' = 'issued',
  'On Hold' = 'on hold',
  'OFAC Hold' = 'ofac hold',
  'Split' = 'split',
  'Void Initiated' = 'void initiated',
  'Voided' = 'voided',
  'Pending Surcharge' = 'pending surcharge',
  'Upgrade Initiated' = 'upgrade initiated',
  'Upgraded' = 'upgraded',
  'Downgrade Initiated' = 'downgrade initiated',
  'Downgraded' = 'downgraded',
  'Uploaded' = 'uploaded',
}

export type ItemStatusType = Lowercase<keyof typeof ItemStatusEnum>;
