// react
import React, { useCallback, useEffect, useState } from 'react';

// packages
import Box from '@mui/material/Box';

// redux
import {
  selectAccessToken,
  selectAccessTokenStatus,
  selectSingleUseToken,
} from 'store/modules/auth/selectors';
import { setSingleUseToken } from 'store/modules/auth/slice';
import { getAccessToken } from 'store/modules/auth/thunk';
import {
  selectFlowType,
  selectUrlProduct,
} from 'store/modules/config/selectors';
import {
  selectOrderCount,
  selectOrders,
  selectOrdersAreLoading,
} from 'store/modules/orders/selectors';
import { getOrders } from 'store/modules/orders/thunks';

// hooks
import { useHistory } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useRouteQueryParams } from 'hooks';
import { useClearReduxPersistedState } from 'hooks/useClearReduxPersistedState';

// components
import { BillingOrdersPage } from 'components/OrdersPage/Billing/shared/OrdersPage';
import CancellationOrdersPage from 'components/OrdersPage/Cancellation/shared/OrderPage';
import CertificateOrdersPage from 'components/OrdersPage/Certificate/OrderPage';
import CircularProgress from 'components/CircularProgress';
import { OrdersPage } from 'components/OrdersPage/Claim/shared/OrdersPage';
import { Pagination, PaginationState } from 'components/OrdersPage/Pagination';
import { RechargeOrdersPage } from 'components/OrdersPage/Recharge/shared/OrdersPage';
import ShopguaranteeOrders from 'components/OrdersPage/Claim/Shopguarantee/ShopguaranteeOrders';
import ShopguaranteeCancellationOrders from 'components/OrdersPage/Cancellation/ShopGuarantee/ShopguaranteeOrders';
import V1Layout from 'layouts/V1';

// utils
import { getToken } from 'utils/localstorage';
import { generateTranslation } from 'utils/translation/i18nextTranslation';
import { getUrlDomains } from 'utils/helpers';
import { getProductValue } from 'store/utils/hooks';

// types
import { Flow } from 'store/modules/config/types';
import { ProductEnum } from 'types';
import { OrdersTypes } from 'utils/translation/types';

const Orders: React.FC = function () {
  // hooks
  const dispatch = useAppDispatch();
  const {
    clearReduxPersistedStateWithoutAuth,
    clearReduxPersistedStateAndRedirect,
  } = useClearReduxPersistedState();
  const history = useHistory();
  const { token, registered } = useRouteQueryParams();
  const { client } = getUrlDomains();

  // redux state
  const accessToken = useAppSelector(selectAccessToken) ?? getToken();
  const accessTokenStatus = useAppSelector(selectAccessTokenStatus);
  const isOrdersLoading = useAppSelector(selectOrdersAreLoading);
  const orders = useAppSelector(selectOrders);
  const ordersCount = useAppSelector(selectOrderCount);
  const productInUrl = useAppSelector(selectUrlProduct);
  const singleUseToken = useAppSelector(selectSingleUseToken);
  const type = useAppSelector(selectFlowType);

  // constants
  const defaultLimit = 100;
  const product = getProductValue(productInUrl);
  const translation = generateTranslation('orders') as OrdersTypes;

  // state
  const [data, setData] = useState<PaginationState>({
    page: 1,
    limit: defaultLimit,
    offset: 0,
    prev: false,
    next: false,
  });

  // functions
  const goNext = useCallback(() => {
    if (ordersCount && data && ordersCount > data.page * data.limit) {
      setData({
        ...data,
        next: Boolean(ordersCount) && ordersCount > data.page + 1 * data.limit,
        page: data.page + 1,
        offset: data.offset + data.limit,
        prev: true,
      });
    }
  }, [data, ordersCount]);

  const goPrevious = useCallback(() => {
    if (data && data.page > 1) {
      setData({
        ...data,
        prev: data.page - 1 > 1,
        page: data.page - 1,
        offset: data.offset - data.limit,
        next: true,
      });
    }
  }, [data]);

  useEffect(() => {
    clearReduxPersistedStateWithoutAuth();
  }, [clearReduxPersistedStateWithoutAuth]);

  // if the user navigates to a link containing "registered" in the query params, but there's no saved access token in LS,
  // redirect back to the magic link page. This is a scenario that would likely be encountered if the user is on the orders
  // page internally, then resets the application state via the footer, but presses "back" in the browser
  useEffect(() => {
    if (!accessToken && registered && accessTokenStatus !== 'loading')
      return history.push(`/${productInUrl}/${type}`);
  }, [accessToken, accessTokenStatus, history, productInUrl, registered, type]);

  useEffect(() => {
    if (token) {
      if (token !== singleUseToken) {
        dispatch(getAccessToken({ token }));
        dispatch(setSingleUseToken(token));
      }
    } else if (!registered) clearReduxPersistedStateAndRedirect();
  }, [
    clearReduxPersistedStateAndRedirect,
    token,
    orders.length,
    accessToken,
    dispatch,
    singleUseToken,
    registered,
  ]);

  useEffect(() => {
    // if the auth call has not been successful,
    // or if there's no JWT
    // or if the single-use token doesn't match the saved single-use token (we need to wait for the app to request a new JWT)
    // or if there's no product in the URL
    if (
      (!registered && accessTokenStatus && accessTokenStatus !== 'success') ||
      !accessToken ||
      (token && singleUseToken && token !== singleUseToken) ||
      !product
    )
      return;

    let url = data ? `?limit=${data.limit}&offset=${data.offset}` : null;

    if (client) {
      if (url) {
        url = `${url}&client=${client}`;
      } else {
        url = `?client=${client}`;
      }
    }

    dispatch(
      getOrders({
        url,
      })
    );
  }, [
    accessToken,
    accessTokenStatus,
    client,
    data,
    dispatch,
    product,
    registered,
    singleUseToken,
    token,
  ]);

  if (!product || !accessToken || isOrdersLoading || !data)
    return (
      <V1Layout>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      </V1Layout>
    );

  switch (type) {
    case Flow.billing:
      return (
        <V1Layout>
          <BillingOrdersPage
            pagination={
              <Pagination
                data={data}
                goPrevious={goPrevious}
                title={translation.pagination.title}
                count={ordersCount}
                goNext={goNext}
              />
            }
          />
        </V1Layout>
      );
    case Flow.certificate:
      return (
        <V1Layout>
          <CertificateOrdersPage />
        </V1Layout>
      );
    case Flow.cancellation:
      if (product === ProductEnum.shopguarantee)
        return (
          <V1Layout>
            <ShopguaranteeCancellationOrders
              pagination={
                <Pagination
                  data={data}
                  goPrevious={goPrevious}
                  title={translation.pagination.title}
                  count={ordersCount}
                  goNext={goNext}
                />
              }
            />
          </V1Layout>
        );
      return (
        <V1Layout>
          <CancellationOrdersPage
            pagination={
              <Pagination
                data={data}
                goPrevious={goPrevious}
                title={translation.pagination.title}
                count={ordersCount}
                goNext={goNext}
              />
            }
          />
        </V1Layout>
      );
    case Flow.refund:
      if (product === ProductEnum.shopguarantee)
        return (
          <V1Layout>
            <ShopguaranteeOrders
              pagination={
                <Pagination
                  data={data}
                  goPrevious={goPrevious}
                  title={translation.pagination.title}
                  count={ordersCount}
                  goNext={goNext}
                />
              }
            />
          </V1Layout>
        );
      return (
        <V1Layout>
          <OrdersPage />
        </V1Layout>
      );
    case Flow.recharge:
      return (
        <V1Layout>
          <RechargeOrdersPage
            pagination={
              <Pagination
                data={data}
                goPrevious={goPrevious}
                title={translation.pagination.title}
                count={ordersCount}
                goNext={goNext}
              />
            }
          />
        </V1Layout>
      );
    default:
      return null;
  }
};

export default Orders;
