import React, { VFC } from 'react';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

type BackArrowProps = {
  to: string;
  text?: string;
};

const BackArrow: VFC<BackArrowProps> = function ({ to, text }) {
  return (
    <Link
      component={RouterLink}
      to={to}
      className="text font-weight-bold"
      underline="hover"
      sx={{
        display: 'flex',
        alignItems: 'center',
        color: 'text.primary',
        textDecorationColor: (theme) => theme.palette.text.primary,
      }}
    >
      <ArrowBackIosIcon /> {text}
    </Link>
  );
};

export default BackArrow;
