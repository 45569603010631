import { MultiSelectOptionType } from 'utils/translation/types';
import { get, set, has } from 'utils/localstorage';

export const RADIO_SELECTIONS = 'radio_options';

export const radioGroupText = () => {
  if (has(RADIO_SELECTIONS)) {
    return JSON.parse(get(RADIO_SELECTIONS) || '');
  }

  return [];
};

export const initMultiselect = (options: MultiSelectOptionType[]) => {
  set(RADIO_SELECTIONS, options);
};
