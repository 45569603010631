import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

// these are only examples of custom icons coming from the icon sheet and how to create them.
// Mui icons should be used instead.

export const JuryIcon = function (props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d="M12 3c-1.27 0-2.4.8-2.82 2H3v2h1.95L2 14c-.47 2 1 3 3.5 3s4.06-1 3.5-3L6.05 7h3.12c.33.85.98 1.5 1.83 1.83V20H2v2h20v-2h-9V8.82c.85-.32 1.5-.97 1.82-1.82h3.13L15 14c-.47 2 1 3 3.5 3s4.06-1 3.5-3l-2.95-7H21V5h-6.17C14.4 3.8 13.27 3 12 3m0 2a1 1 0 0 1 1 1 1 1 0 0 1-1 1 1 1 0 0 1-1-1 1 1 0 0 1 1-1m-6.5 5.25L7 14H4l1.5-3.75m13 0L20 14h-3l1.5-3.75Z" />
    </SvgIcon>
  );
};

// double coloured icon with rescale class.

export const HealthRaas = function ({ className, ...rest }: SvgIconProps) {
  return (
    <SvgIcon
      {...rest}
      viewBox="0 0 33.16 33.41"
      className={`${className} rescaled-icon`}
    >
      <path
        id="primary"
        d="M26.24 16.43c-.37 1.42-1.09 2.67-1.96 3.83-1.13 1.5-2.45 2.82-3.88 4.02-1.22 1.02-2.48 1.98-3.72 2.97-.05.04-.1.08-.17.02-1.64-1.33-3.37-2.56-4.91-4.02-1.09-1.03-2.12-2.12-2.99-3.35-.8-1.13-1.44-2.33-1.75-3.69-.25-1.11-.32-2.23.09-3.32.72-1.93 2.11-3.08 4.16-3.36 2.16-.3 4.19.81 5.18 2.76.11.21.19.43.28.65.15-.31.27-.63.44-.91 1.06-1.73 2.62-2.62 4.66-2.53 2.44.11 4.34 1.84 4.75 4.25.16.91.05 1.81-.18 2.7Z"
      />
      <path
        id="secondary"
        d="M16.52 14.09c-2.45 0-4.44 1.99-4.44 4.44s1.99 4.44 4.44 4.44 4.44-1.99 4.44-4.44-1.99-4.44-4.44-4.44Zm2.94 5.2h-2.17v2.18h-1.53v-2.18h-2.17v-1.52h2.17v-2.18h1.53v2.18h2.17v1.52Z"
      />
    </SvgIcon>
  );
};
