// react
import React from 'react';

// packages
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

// components
import Card from 'components/Card';

// utils
import { dateSlashes } from 'utils/date';
import { getStatus } from 'utils/itemTable';

// types
import type { FormattedItem, FormattedOrder } from 'store/modules/orders/types';

const ItemDescriptionList = function ({
  item,
  labelStyles,
  styles,
  values,
}: {
  item: FormattedItem;
  labelStyles?: Record<string, any>;
  styles?: Record<string, any>;
  values: { label: string; value: (item: FormattedItem) => string }[];
}) {
  return (
    <Box
      sx={{
        alignItems: 'start',
        display: 'flex-column',
        ...styles,
      }}
    >
      <Box
        sx={{
          textAlign: 'left',
        }}
      >
        {values.map(({ label, value }) => (
          <Box
            key={label}
            sx={{
              alignItems: 'start',
              display: 'flex',
              paddingBottom: { xs: '12px', md: '6px' },
            }}
          >
            <Box sx={{ color: '#2e2f2f', ...labelStyles }}>
              <strong>{label}:</strong>
            </Box>
            <Box
              sx={{
                color: '#202020',
                marginLeft: '1rem',
                maxWidth: {
                  xs: '50%',
                  md: '70%',
                },
                width: '100%',
              }}
            >
              {value(item)}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const CertificateTable: React.FC<{
  items: FormattedItem[];
  order: FormattedOrder;
  startClaim: (
    order: FormattedOrder,
    items: FormattedItem[],
    cert_num?: string
  ) => void;
}> = function ({ items, order, startClaim }) {
  if (items.length <= 0) return null;

  return (
    <Card
      elevation={0}
      title={`${
        order?.created &&
        `Order Date: ${dateSlashes({ item: order?.created })} - `
      }${order.client_name} - Order ID: ${order.reference_id}`}
      sx={{
        '& .MuiCardHeader-root': {
          backgroundColor: '#3aa0ff',
          boxShadow: 'none',
          fontSize: '13px',
          paddingX: '24px',
          paddingY: '12px',
        },
      }}
    >
      {order.items.map((item) => (
        <Box
          key={item.id}
          sx={{
            border: 'solid',
            borderColor: '#d9d9d9',
            borderBottomWidth: '2px',
            borderLeftWidth: '1px',
            borderRightWidth: '1px',
            borderTopWidth: '0px',
            boxShadow: 'none',
            display: 'grid',
            fontSize: '13px',
            gridTemplateColumns: {
              xs: 'repeat(1, minmax(0, 1fr))',
              md: 'repeat(2, minmax(0, 1fr))',
            },
            padding: '24px',
            '&:last-child': { borderBottomWidth: '1px' },
          }}
        >
          <Box sx={{ alignItems: 'start', display: 'flex' }}>
            <ItemDescriptionList
              item={item}
              labelStyles={{
                maxWidth: { xs: '50%', md: '30%' },
                minWidth: { xs: '30%', md: '20%' },
              }}
              styles={{ width: '100%' }}
              values={[
                {
                  label: 'Event Date',
                  value: (item: FormattedItem) =>
                    dateSlashes({
                      item: item?.event?.start_date,
                      placeholder: '-',
                    }),
                },
                {
                  label: 'Event Name',
                  value: (item: FormattedItem) => item.event?.name || '-',
                },
                {
                  label: 'Description',
                  value: (item: FormattedItem) => item.name,
                },
              ]}
            />
          </Box>
          <Box
            sx={{
              alignItems: { md: 'start' },
              display: { md: 'flex' },
              marginLeft: { md: '1.5rem' },
            }}
          >
            <ItemDescriptionList
              item={item}
              labelStyles={{ minWidth: { xs: '50%', md: '40%' } }}
              styles={{ width: '60%' }}
              values={[
                {
                  label: 'Status',
                  value: (item: FormattedItem) =>
                    getStatus(item.status, item.claim_status),
                },
                {
                  label: 'Covered Amount',
                  value: (item: FormattedItem) => item.unit_cost_literal,
                },
                {
                  label: 'Sold By',
                  value: (item: FormattedItem) => item.policy?.sold_by || '-',
                },
              ]}
            />
            <Box
              sx={{
                alignItems: { md: 'center' },
                display: { md: 'flex' },
                height: '100%',
                justifyContent: { md: 'end' },
                paddingTop: { xs: '10px', md: 0 },
                width: { md: '40%' },
              }}
            >
              <Button
                onClick={() => startClaim(order, items, item.policy.id)}
                variant="outlined"
                sx={{
                  backgroundColor: '#f8fcfe',
                  borderRadius: '0.25rem',
                  color: '#47484b',
                  fontSize: { xs: '12px', md: '10px', lg: '12px' },
                  fontWeight: 400,
                  '&:hover': {
                    backgroundColor: '#47484b',
                    borderColor: '#47484b',
                    color: 'white',
                  },
                }}
              >
                Confirmation of Coverage
              </Button>
            </Box>
          </Box>
        </Box>
      ))}
    </Card>
  );
};

export default CertificateTable;
