/* eslint-disable no-param-reassign */
// redux
import { createSlice } from '@reduxjs/toolkit';
import { getReasons } from 'store/modules/reasons/thunks';
import { ReasonsState } from 'store/modules/reasons/types';

const initialState: ReasonsState = {
  reasons: {
    status: 'loading',
  },
};

export const ReasonsSlice = createSlice({
  name: 'reasons',
  initialState,
  reducers: {
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getReasons.fulfilled, (state, { payload }) => {
      state.reasons = {
        data: payload,
        status: 'success',
      };
    });
    builder.addCase(getReasons.rejected, (state, action) => {
      const error = action.payload as string;
      state.reasons = {
        status: 'error',
        errorMessage: error,
      };
    });
    builder.addCase(getReasons.pending, (state, _) => {
      state.reasons = {
        status: 'loading',
      };
    });
  },
});

export const { clearState } = ReasonsSlice.actions;

export default ReasonsSlice.reducer;
/* eslint-enable no-param-reassign */
