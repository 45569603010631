export enum FormComponentEnum {
  'InputPanel' = 'InputPanel',
  'DatePanel' = 'DatePanel',
  'AddressPanel' = 'AddressPanel',
  'UpdateBillingAddressPanel' = 'UpdateBillingAddressPanel',
  'FileUploadPanel' = 'FileUploadPanel',
  'PerilsPanel' = 'PerilsPanel',
  'TextAreaPanel' = 'TextAreaPanel',
  'MultiSelect' = 'MultiSelect',
  'CreditCardPanel' = 'CreditCardPanel',
}
export type FormComponent = keyof typeof FormComponentEnum;

export type StepComponent =
  | FormComponent
  | 'DecisionPanel'
  | 'HeaderDescription'
  | 'DescriptionPanel'
  | 'StepSubmitButton'
  | 'ThankYouPage'
  | 'CertificatePage'
  | 'ReviewPanel';
