// react
import React, { useCallback, useState } from 'react';

// redux
import {
  selectFlowType,
  selectUrlProduct,
} from 'store/modules/config/selectors';
import {
  selectOrdersAreLoading,
  selectOrdersWithItemsIssued,
} from 'store/modules/orders/selectors';
import { setSelectedItemsAndOrder } from 'store/modules/orders/slice';

// hooks
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useHistory } from 'react-router-dom';
import {
  useDebounce,
  useFilteredOrders,
  useSearchDates,
  useSearchTerm,
} from 'hooks';

// utils
import { stepName } from 'globalVariables';
import { generateHelpLink } from 'utils/helpers';
import { generateTranslation } from 'utils/translation/i18nextTranslation';

// packages
import { DateRange } from 'react-date-range';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// components
import CertificateTable from 'components/OrdersPage/Certificate/CertificateTable';
import CircularProgress from 'components/CircularProgress';
import { DateSearchPanel } from 'components/OrdersPage/DateSearchPanel';
import { Filters } from 'components/OrdersPage/Filters';
import HeaderDescription from 'components/HeaderDescription';

// types
import type { ColumnFilter } from 'components/OrdersPage/DateSearchPanel';
import type { FormattedOrder, FormattedItem } from 'store/modules/orders/types';
import type { OrdersTypes } from 'utils/translation/types';

type DefaultDateValues = { column: ColumnFilter } & DateRange;

const defaultDateValues = {
  startDate: new Date(),
  endDate: new Date(),
  key: 'selection',
  column: 'start_date',
};

const CertificateOrdersPage: React.FC = function () {
  // state
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [defaultDates, setDefaultDates] = useState<DefaultDateValues[]>([
    defaultDateValues,
  ]);

  // redux
  const orders = useAppSelector(selectOrdersWithItemsIssued);
  const ordersAreLoading = useAppSelector(selectOrdersAreLoading);
  const type = useAppSelector(selectFlowType);
  const productInUrl = useAppSelector(selectUrlProduct);

  // hooks
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useSearchTerm();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [searchDate, setSearchDate] = useSearchDates();
  const filteredOrders = useFilteredOrders(
    orders,
    debouncedSearchTerm,
    searchDate,
    setDefaultDates
  );

  // constants
  const translation = generateTranslation('orders') as OrdersTypes;
  const helpUrl = generateHelpLink(productInUrl);
  const ordersFound =
    filteredOrders.length > 0 &&
    filteredOrders.some((order) => order.items.length > 0);

  // functions
  const startClaim = useCallback(
    (order: FormattedOrder, items: FormattedItem[], cert_num?: string) => {
      dispatch(
        setSelectedItemsAndOrder({
          order,
          items,
          cert_num,
        })
      );
      return history.push(`/${productInUrl}/${type}/${stepName}1`);
    },
    [dispatch, history, productInUrl, type]
  );

  const handleDateFilterPanelVisibility = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  const clearSearchDate = useCallback(() => {
    setSearchDate({
      column: null,
      endDate: null,
      startDate: null,
    });
    setDefaultDates([defaultDateValues]);
  }, [setSearchDate]);

  return (
    <Stack px={2}>
      <HeaderDescription title={translation.header.title}>
        <Typography mt={2} mb={4}>
          {translation.header.row2}
        </Typography>
      </HeaderDescription>
      <Box className="text-center">
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          display="flex"
          justifyContent="space-evenly"
          mb={5}
        >
          <Button
            component={Link}
            variant="outlined"
            className="btn-style font-weight-bold"
            target="_blank"
            href={`/${productInUrl}/refund/orders?registered=true`}
            sx={{
              backgroundColor: '#f5fbfe',
              borderRadius: '0.25rem',
              color: '#5aacde !important',
              textDecorationLine: 'none !important',
            }}
          >
            {translation.links.newFlow}
          </Button>
          <Button
            component={Link}
            variant="outlined"
            className="btn-style font-weight-bold"
            target="_blank"
            href={helpUrl}
            sx={{
              backgroundColor: '#f5fbfe',
              borderRadius: '0.25rem',
              color: '#5aacde !important',
              marginTop: { xs: '12px !important', sm: '0px !important' },
              textDecorationLine: 'none !important',
            }}
          >
            {translation.links.help}
          </Button>
        </Stack>
        <Filters
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleDateFilterPanelVisibility={handleDateFilterPanelVisibility}
          clicked={Boolean(anchorEl)}
          searchDate={searchDate}
          clearSearchDate={clearSearchDate}
        />
        {ordersFound ? (
          <>
            {filteredOrders.map((order, index) =>
              order.items.length > 0 ? (
                <Box key={index}>
                  <DateSearchPanel
                    anchorEl={anchorEl}
                    setAnchorEl={setAnchorEl}
                    translation={translation}
                    defaultDates={defaultDates}
                    setSearchDate={setSearchDate}
                    clearSearchDate={clearSearchDate}
                  />
                  <CertificateTable
                    key={`${order.uuid}-${index}`}
                    items={order.items}
                    order={order}
                    startClaim={startClaim}
                  />
                </Box>
              ) : null
            )}
          </>
        ) : (
          !ordersAreLoading && (
            <DateSearchPanel
              anchorEl={anchorEl}
              setAnchorEl={setAnchorEl}
              translation={translation}
              setSearchDate={setSearchDate}
              clearSearchDate={clearSearchDate}
              defaultDates={defaultDates}
            >
              <Box className="text-center">{translation.noOrders}</Box>
            </DateSearchPanel>
          )
        )}
        {!ordersFound && ordersAreLoading && (
          <CircularProgress
            size={25}
            thickness={5}
            sx={{ display: 'inline-flex', verticalAlign: 'middle' }}
          />
        )}
      </Box>
    </Stack>
  );
};

export default CertificateOrdersPage;
