// react
import React, { FC } from 'react';

// packages
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { SxProps, Theme } from '@mui/material';

// hooks
import { useHistory } from 'react-router-dom';

const Layout: FC<{ sx?: SxProps<Theme> }> = function ({ children, sx }) {
  // hooks
  const history = useHistory();

  // constants
  const isCertificatePage = history.location.pathname.includes('/certificate/');

  return (
    <Grid container justifyContent="center" className="text-center" sx={sx}>
      <Grid item xs={12} sm={isCertificatePage ? 10 : 8}>
        <Stack direction="column">{children}</Stack>
      </Grid>
    </Grid>
  );
};

export default Layout;
