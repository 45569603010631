import React, { useEffect, VFC, useState, ElementType } from 'react';
import Box from '@mui/material/Box';
import { ComponentStructure } from 'types';
import { UseFormReturn } from 'react-hook-form';
import Layout from 'components/Layout';
import { generateSplitLayout } from 'utils/layout';
import GenericFormField from './components/GenericFormField';

const FormBuilder: VFC<{
  layout: ComponentStructure[];
  formApi: UseFormReturn<any, any>;
  setIsSubmitting: (value: boolean) => void;
  StepSubmitButton: ElementType;
  onSubmit(): void;
}> = function ({
  layout,
  formApi,
  setIsSubmitting,
  StepSubmitButton,
  onSubmit,
}) {
  const [splitLayout, setLayout] =
    useState<Record<number, ComponentStructure[] | ComponentStructure>>();

  useEffect(() => {
    // split the layout every time we see a Perils panel.
    // Perils panels need to be displayed full width and not inside a grid layout
    setLayout(generateSplitLayout(layout, ['PerilsPanel', 'CreditCardPanel']));
  }, [layout]);

  return splitLayout ? (
    <form onSubmit={onSubmit}>
      {Object.values(splitLayout).map((layout, indexLayout) =>
        Array.isArray(layout) ? (
          <Layout sx={{ mb: 3 }} key={`layout-${indexLayout}`}>
            {layout.map((elem, index) => (
              <Box mb={2} key={`component-${index}`}>
                <GenericFormField
                  type={elem.component}
                  formApi={formApi}
                  props={elem.props}
                  verbiages={elem.verbiages}
                  setIsSubmitting={setIsSubmitting}
                  StepSubmitButton={StepSubmitButton}
                />
              </Box>
            ))}
          </Layout>
        ) : (
          <Box mb={4} key={`layout-${indexLayout}`}>
            <GenericFormField
              type={layout.component}
              formApi={formApi}
              props={layout.props}
              verbiages={layout.verbiages}
              setIsSubmitting={setIsSubmitting}
              StepSubmitButton={StepSubmitButton}
            />
          </Box>
        )
      )}
    </form>
  ) : null;
};

export default FormBuilder;
