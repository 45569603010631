// redux
import { lsReduxKey } from 'store/browserStorage';

// utils
import { getFlowTypeAndProduct, getUrlDomains } from 'utils/helpers';

export const asyncResolve = () =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve('resolved');
    }, 10);
  });

export const clear = async (): Promise<boolean> => {
  localStorage.clear();

  await asyncResolve();

  return !has(lsReduxKey);
};

export const get = (key: string): string | null =>
  localStorage.getItem(key + prefixKey);

const getNameSpace = () => {
  const { client, environment } = getUrlDomains();

  const { product, type } = getFlowTypeAndProduct(window.location.pathname);

  return environment
    ? `-${product}-${type}-${client}-${environment}`
    : `-${product}-${type}-${client}`;
};

export const getToken = (): string | null => localStorage.getItem('token');

export const has = (key: string): boolean =>
  localStorage.getItem(key + prefixKey) !== null;

const prefixKey = getNameSpace();

export const remove = async (key: string): Promise<boolean> => {
  localStorage.removeItem(key + prefixKey);

  await asyncResolve();

  return !has(key + prefixKey);
};

export const set = (key: string, value: any): boolean => {
  if (typeof value === 'string') localStorage.setItem(key + prefixKey, value);
  else localStorage.setItem(key + prefixKey, JSON.stringify(value));
  return has(key + prefixKey);
};

export const setToken = (value: any): boolean => {
  if (typeof value === 'string') localStorage.setItem('token', value);
  else localStorage.setItem('token', JSON.stringify(value));
  return has('token');
};
