// mocks
import { shopGGeneralConfig } from 'mocks/products/shopguarantee';

// types
import type { ConfigFileTypes, ConfigStep } from 'types';

const shopGCertificateSteps: ConfigStep[] = [
  {
    name: 'CertificatePage',
    backButtonText: '',
    fields: [
      {
        component: 'CertificatePage',
        verbiages: {},
      },
    ],
  },
];

export const shopGCertificateConfig: ConfigFileTypes = {
  ...shopGGeneralConfig,
  steps: shopGCertificateSteps,
};
