// packages
import { createSlice } from '@reduxjs/toolkit';

// redux
import { getConfig } from 'store/modules/config/thunks';

// configs
import calculateHasConfigType from 'configs';

// types
import type { ConfigFileState } from 'store/modules/config/types';

/* eslint-disable no-param-reassign */

const initialState: ConfigFileState = {
  status: 'loading',
  config: {
    theme: undefined,
    logo: '',
    steps: [],
    submissionMapping: {},
    translationOverride: {
      general: {},
      underwriter: {},
      product: {},
    },
    links: {
      helpUrl: '',
      helpEmail: '',
      privacyPolicyUrl: '',
      contactUsUrl: '',
    },
  },
  productInUrl: undefined,
  flow: 'refund',
  startOver: false,
  productId: '',
  claimsApi: '',
};

export const ConfigSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    clearState: () => initialState,
    setStartOver: (state, { payload }) => {
      state.startOver = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getConfig.fulfilled,
      (state, { payload: { config, productInUrl, productId, type } }) => {
        const localConfig = calculateHasConfigType({
          product: productInUrl,
          type,
        });

        state.config = {
          ...config,
          ...localConfig,
        };
        state.productInUrl = productInUrl;
        state.flow = type;
        state.productId = productId;
        state.status = 'success';
        state.claimsApi =
          process.env.REACT_APP_API_V4_URL ?? 'missingClaimEnvVariable';
      }
    );
    builder.addCase(getConfig.rejected, (state) => {
      state.status = 'error';
    });
    builder.addCase(getConfig.pending, (state) => {
      state.status = 'loading';
    });
  },
});

export const { clearState, setStartOver } = ConfigSlice.actions;

export default ConfigSlice.reducer;
/* eslint-enable no-param-reassign */
