const canceledVerbiage = {
  text: 'Protection Canceled',
  description: 'Protection has been canceled and is no longer valid.',
};

const protectedVerbiage = {
  text: 'Protected',
  description: 'Item is protected.',
};

export const statusList = {
  accepted: protectedVerbiage,
  awaiting_fulfillment: {
    text: 'Awaiting Fulfillment',
    description: 'Protection request is currently being processed.',
  },
  canceled: canceledVerbiage,
  documentation_pending: {
    text: 'Waiting for Documentation',
    description: 'Documentation has been requested to complete refund request.',
  },
  documentation_review: {
    text: 'Documentation in Review',
    description: 'Documentation has been provided and is awaiting review.',
  },
  downgraded: {
    text: 'Protection Downgraded',
    description: 'Protection has been downgraded to a new item.',
  },
  exchanged: {
    text: 'Protection Exchanged',
    description: 'Protection has been exchanged for a new item.',
  },
  insufficient_address: {
    text: 'Insufficient Address',
    description: 'Address information is not sufficient to issue protection.',
  },
  issued: protectedVerbiage,
  on_hold: {
    text: 'Protection On Hold',
    description: 'Payment required to issue protection.',
  },
  protected: protectedVerbiage,
  refund_issued: {
    text: 'Refund Issued',
    description: 'Refund request approved, refund has been issued.',
  },
  refund_request_closed: {
    text: 'Refund Request Closed',
    description:
      'Documentation was requested and not provided. Refund request will be re-opened if/when documentation is provided.',
  },
  refund_request_denied: {
    text: 'Refund Request Denied',
    description: 'Refund request has been denied based on refund terms.',
  },
  refund_request_review: {
    text: 'Refund Request in Review',
    description: 'Refund request is awaiting review.',
  },
  refund_requested: {
    text: 'Refund Requested',
    description: 'Refund request has been submitted.',
  },
  rejected: {
    text: 'Protection Unavailable',
    description: 'Protection is not available at this time.',
  },
  review: {
    text: 'Protection Request in Review',
    description:
      'Protection has not been issued. Protection request is in review.',
  },
  upgraded: {
    text: 'Protection Upgraded',
    description: 'Protection has been upgraded to a new item.',
  },
  void_initiated: canceledVerbiage,
  voided: canceledVerbiage,
};
