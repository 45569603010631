import React, { VFC } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

const ValueTooltip: VFC<{ value: string | undefined }> = function ({ value }) {
  return (
    <Tooltip
      disableFocusListener
      enterTouchDelay={10}
      title={<Box fontSize={12}>{value}</Box>}
      color="action"
      placement="top"
    >
      <span
        style={{
          width: ' 100%',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        {value}
      </span>
    </Tooltip>
  );
};

export default ValueTooltip;
