// mocks
import {
  raasSteps,
  raasTheme,
  raasSubmissionMapping,
  raasGeneralConfig,
} from 'mocks/products/raasNoClient';

// types
import type { ConfigFileTypes, ConfigStep, ConfigTheme } from 'types';

export const raasClientTheme: ConfigTheme = raasTheme;
export const raasClientSteps: ConfigStep[] = raasSteps;
export const raasClientSubmissionMapping = raasSubmissionMapping;

export const raasClientConfig: ConfigFileTypes = {
  ...raasGeneralConfig,
  theme: raasClientTheme,
  steps: raasClientSteps,
  submissionMapping: raasClientSubmissionMapping,
};
