// mocks
import { raasGeneralConfig } from 'mocks/products/raasNoClient';

// types
import type { ConfigFileTypes, ConfigStep } from 'types';

/* eslint-disable no-template-curly-in-string */

export const raasCancellationSteps: ConfigStep[] = [
  {
    name: 'Protection Cancellation',
    backButtonText: '',
    fields: [
      {
        component: 'HeaderDescription',
        verbiages: {
          title: 'Why Are You Cancelling?',
          description:
            'Cancelling your certificates will prevent you from protecting your purchase from unexpected circumstances. Are you sure you want to cancel your Refund Protection?',
          footer: 'Before you cancel your protection, let us know why?',
        },
      },
      {
        component: 'MultiSelect',
        props: {
          required: true,
          name: 'cancellation',
          optionsEndpoint: 'policies/actions-reasons',
        },
        verbiages: {
          reviewPageTitle: 'Cancellation',
          label: 'Write your description',
          options: [],
        },
      },
      {
        component: 'StepSubmitButton',
        verbiages: {
          text: 'Next',
          allCompleteText: 'Update',
        },
      },
    ],
  },
  {
    name: 'Review',
    backButtonText: 'Protection Cancellation',
    fields: [
      {
        component: 'ReviewPanel',
        verbiages: {},
      },
    ],
  },
  {
    name: 'ThankYouPage',
    backButtonText: '',
    fields: [
      {
        component: 'ThankYouPage',
        verbiages: {},
      },
    ],
  },
];

export const raasCancellationSubmissionMapping = {
  reason: '${GLOBAL.formsReducer.forms.step1.cancellation}',
};

export const raasCancellationConfig: ConfigFileTypes = {
  ...raasGeneralConfig,
  steps: raasCancellationSteps,
  submissionMapping: raasCancellationSubmissionMapping,
};

/* eslint-enable no-template-curly-in-string */
