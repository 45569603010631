import React from 'react';

import PrintRoundedIcon from '@mui/icons-material/PrintRounded';
import Box from '@mui/material/Box';

const PrintButton = function () {
  const click = () => {
    window.print();
  };

  return (
    <span
      onClick={click}
      onKeyPress={click}
      role="button"
      style={{ cursor: 'pointer' }}
      tabIndex={0}
    >
      <Box pt={2}>
        <PrintRoundedIcon style={{ verticalAlign: 'middle' }} /> Print
      </Box>
    </span>
  );
};

export default PrintButton;
