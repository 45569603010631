import React, { VFC, useState, useEffect, useCallback } from 'react';
import { useBoolean } from 'react-use';
import { useHistory } from 'react-router-dom';
import { generateTranslation } from 'utils/translation/i18nextTranslation';
import { OrdersTypes } from 'utils/translation/types';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getLastUncompletedStep,
  selectAllStepsComplete,
} from 'store/modules/forms';
import {
  selectOrders,
  selectSelectedItems,
  selectSelectedOrder,
  selectFileUploadConsent,
  selectOrdersAreLoading,
  selectCustomer,
} from 'store/modules/orders/selectors';
import {
  selectFlowType,
  selectUrlProduct,
} from 'store/modules/config/selectors';
import { setCustomer } from 'store/modules/customer/slice';
import { FormattedItem, FormattedOrder } from 'store/modules/orders/types';
import {
  setSelectedItemsAndOrder,
  setConsent,
} from 'store/modules/orders/slice';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import LoadingButton from 'components/LoadingButton';
import CircularProgress from 'components/CircularProgress';
import HeaderDescription from 'components/HeaderDescription';
import { stepName } from 'globalVariables';
import ItemTableWrapper from './ItemTableWrapper';

const CancellationOrdersPage: VFC<{ pagination?: JSX.Element }> = function ({
  pagination,
}) {
  const history = useHistory();
  const [unique_id, setUnique_id] = useState('');
  const selectedItems = useAppSelector(selectSelectedItems);
  const selectedOrder = useAppSelector(selectSelectedOrder);
  const orders = useAppSelector(selectOrders);
  const allStepsComplete = useAppSelector(selectAllStepsComplete);
  const fileUploadConsent = useAppSelector(selectFileUploadConsent);
  const ordersAreLoading = useAppSelector(selectOrdersAreLoading);
  const customer = useAppSelector(selectCustomer(unique_id));
  const type = useAppSelector(selectFlowType);
  const productInUrl = useAppSelector(selectUrlProduct);
  const lastStep = useAppSelector(getLastUncompletedStep);
  const dispatch = useAppDispatch();

  const [userSelection, setUserSelection] = useState({
    items: selectedItems,
    order: selectedOrder,
    tracking_number: selectedItems[0]?.tracking_number || undefined,
  });
  const [submitting, setSubmitting] = useBoolean(false);

  const cannotSubmit = userSelection.items.length === 0 || !fileUploadConsent;

  const ordersFound = orders.length > 0;

  const startClaim = useCallback(() => {
    setSubmitting(true);
    if (userSelection.order) {
      setUnique_id(userSelection.order.uuid);
      dispatch(
        setSelectedItemsAndOrder({
          order: userSelection.order,
          items: userSelection.items,
        })
      );
    }
  }, [dispatch, setSubmitting, userSelection.items, userSelection.order]);

  const updateSelected = useCallback(
    (items: FormattedItem[], order: FormattedOrder) => {
      dispatch(setConsent(true));
      setUserSelection((state) => {
        if (items.length === 0) {
          return { items: [], order: undefined, tracking_number: undefined };
        }
        if (
          (state.order?.created === order.created &&
            state.tracking_number === items[0].tracking_number) ||
          state.order?.created === undefined
        ) {
          return {
            items,
            order,
            tracking_number: items[0].tracking_number || undefined,
          };
        }
        return state;
      });
    },
    [dispatch]
  );

  useEffect(() => {
    if (unique_id) {
      dispatch(setCustomer(customer));
      if (allStepsComplete) {
        history.push(`/${productInUrl}/${type}${lastStep}`);
      } else {
        history.push(`/${productInUrl}/${type}/${stepName}1`);
      }
    }
  }, [
    unique_id,
    customer,
    history,
    allStepsComplete,
    type,
    dispatch,
    productInUrl,
    lastStep,
  ]);

  const translation = generateTranslation('orders') as OrdersTypes;

  return (
    <Stack px={2}>
      <HeaderDescription title={translation.header.title}>
        <Typography mt={2} mb={4}>
          {translation.header.row2}
        </Typography>
      </HeaderDescription>
      <Box className="text-center">
        {ordersFound ? (
          <>
            {orders.map((order, index) => {
              const isSelectable =
                userSelection.order?.created !== undefined &&
                userSelection.order?.created !== order.created;
              const isExpired = Boolean(
                order.items[0].look_period &&
                  Boolean(new Date(order.items[0].look_period) <= new Date())
              );
              const isDisabled = isSelectable || isExpired;
              return (
                <Box key={index} sx={{ mb: 3 }}>
                  <ItemTableWrapper
                    key={`${order.uuid}-${index}`}
                    isDisabled={isDisabled}
                    isExpired={isExpired}
                    items={order.items}
                    order={order}
                    selected={isDisabled ? [] : userSelection.items}
                    updateSelected={updateSelected}
                  />
                </Box>
              );
            })}
            {/* {pagination} */}
            <Stack alignItems="center" className="text-left">
              <Box mt={3} className="text-center" width="100%">
                <LoadingButton
                  variant="contained"
                  disabled={cannotSubmit || submitting}
                  onClick={startClaim}
                  loading={submitting}
                  className="btn-style"
                >
                  {allStepsComplete ? 'Update' : translation.button}
                </LoadingButton>
              </Box>
            </Stack>
          </>
        ) : (
          !ordersAreLoading && (
            <Box className="text-center">{translation.noOrders}</Box>
          )
        )}
        {!ordersFound && ordersAreLoading && (
          <CircularProgress
            size={25}
            thickness={5}
            sx={{ display: 'inline-flex', verticalAlign: 'middle' }}
          />
        )}
      </Box>
    </Stack>
  );
};

export default CancellationOrdersPage;
