// utils
import { get, set } from 'utils/localstorage';

// types
import type { RootState } from 'store';

export const lsReduxKey = 'redux';

export function loadState(): RootState | undefined {
  try {
    // look up persisted state in local storage, if available
    const serializedState = get(lsReduxKey);

    if (!serializedState) return undefined;

    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
}

export function saveState(state: RootState): boolean {
  const { configFileReducer, alertReducer, authReducer, ...stateToSave } =
    state;
  try {
    return set(lsReduxKey, {
      authReducer: { singleUseToken: authReducer.singleUseToken },
      ...stateToSave,
    });
  } catch (e) {
    return false;
  }
}
