// react
import React, { useCallback, useState } from 'react';

// packages
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {
  FormControl,
  MenuItem,
  Popover,
  Select,
  useTheme,
} from '@mui/material';
import { DateRangePicker, DateRange } from 'react-date-range';

// hooks
import { SearchDates } from 'hooks/useSearchDates';

// components
import LoadingButton from 'components/LoadingButton';

// assets
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'styles/app.css';

// types
import type { OrdersTypes } from 'utils/translation/types';

type Option = {
  value: string;
  label: string;
};

export type ColumnFilter = keyof typeof ColumnFilterEnum;

export enum ColumnFilterEnum {
  'created' = 'Order Date',
  'start_date' = 'Event Date',
}

const options: Option[] = Object.keys(ColumnFilterEnum).map((key) => ({
  label: ColumnFilterEnum[key],
  value: key,
}));

export const DateSearchPanel: React.FC<{
  anchorEl: HTMLButtonElement | null;
  translation: OrdersTypes;
  defaultDates: DateRange[];
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  setSearchDate: (newSearchTerm: SearchDates) => void;
  clearSearchDate: () => void;
}> = function ({
  anchorEl,
  translation,
  defaultDates,
  setAnchorEl,
  setSearchDate,
  clearSearchDate,
  children,
}) {
  // hooks
  const theme = useTheme();

  // state
  const [columnFilter, setColumnFilter] = useState<ColumnFilter>(
    defaultDates[0].column
  );
  const [dateRange, setDateRange] = useState<DateRange[]>(defaultDates);

  // functions
  const handleSelect = useCallback((ranges: { selection: DateRange }) => {
    setDateRange([ranges.selection]);
  }, []);
  const handleOptionChange = useCallback((event) => {
    setColumnFilter(event.target.value as ColumnFilter);
  }, []);

  return (
    <>
      <Popover
        id={anchorEl ? 'simple-popover' : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 50,
          horizontal: -620,
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <Stack
            sx={{
              flexDirection: 'column',
              padding: '1rem',
              minWidth: '15rem',
            }}
            spacing={3}
          >
            <Typography component="span" fontSize="0.8rem">
              Search by
            </Typography>
            <FormControl>
              <Select
                value={columnFilter}
                onChange={handleOptionChange}
                sx={{
                  '& .MuiOutlinedInput-input': {
                    paddingX: 3,
                    paddingY: 2,
                  },
                }}
              >
                {options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Stack
              sx={{
                flexDirection: 'row',
                justifyContent: { xs: 'flex-end', md: 'space-between' },
              }}
            >
              <LoadingButton
                variant="outlined"
                onClick={() => {
                  clearSearchDate();
                  return setAnchorEl(null);
                }}
              >
                {translation.dateFilter.buttons.cancel}
              </LoadingButton>
              <LoadingButton
                variant="contained"
                onClick={() => {
                  const ranges = [...dateRange];
                  setSearchDate({
                    endDate: ranges[0].endDate,
                    startDate: ranges[0].startDate,
                    column: columnFilter,
                  });
                  return setAnchorEl(null);
                }}
                sx={{ marginLeft: 2 }}
              >
                {translation.dateFilter.buttons.filter}
              </LoadingButton>
            </Stack>
          </Stack>
          <Stack
            sx={{
              overflowX: { xs: 'scroll', sm: 'auto' },
            }}
          >
            <DateRangePicker
              ranges={dateRange}
              onChange={handleSelect}
              color={theme.palette.primary.main}
              rangeColors={[theme.palette.primary.main]}
            />
          </Stack>
        </Stack>
      </Popover>
      {children}
    </>
  );
};
