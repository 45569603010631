import { isAfter, isSameDay, isBefore } from 'date-fns';
import { useState, useEffect } from 'react';
import { FormattedOrder } from 'store/modules/orders/types';
import { setDate } from 'utils/date';
import { SearchDates } from './useSearchDates';

export default function useFilteredOrders(
  orders: FormattedOrder[],
  debouncedSearchTerm: string,
  searchDate: SearchDates,
  setDefaultDates: React.Dispatch<React.SetStateAction<any[]>>
) {
  const [filteredOrders, setFilteredOrders] = useState<FormattedOrder[]>([
    ...orders,
  ]);

  useEffect(() => {
    if (!searchDate.column) {
      const filtered = orders.map((order) => ({
        ...order,
        items: order.items.filter(
          (item: { name: string }) =>
            item.name &&
            item.name
              .toLocaleLowerCase()
              .indexOf(debouncedSearchTerm.toLocaleLowerCase()) >= 0
        ),
      }));
      return setFilteredOrders([...filtered]);
    }

    if (searchDate.column === 'created') {
      const filtered = orders
        .filter((order) => {
          if (!order.created) return false;
          const start = searchDate.startDate as Date;
          const end = searchDate.endDate as Date;
          const created = setDate(order.created);
          if (!created) return false;
          return (
            (isAfter(created, start) || isSameDay(created, start)) &&
            (isBefore(created, end) || isSameDay(created, end))
          );
        })
        .map((order) => ({
          ...order,
          items: order.items.filter(
            (item) =>
              item.name &&
              item.name
                .toLocaleLowerCase()
                .indexOf(debouncedSearchTerm.toLocaleLowerCase()) >= 0
          ),
        }));
      setFilteredOrders([...filtered]);
      return setDefaultDates([
        {
          ...searchDate,
          key: 'selection',
        },
      ]);
    }

    const filtered = orders
      .map((order) => ({
        ...order,
        items: order.items.filter((item) => {
          if (!item.event?.start_date) return false;
          const start = searchDate.startDate as Date;
          const end = searchDate.endDate as Date;
          const eventDate = setDate(item.event?.start_date);
          if (!eventDate) return false;
          return (
            (isAfter(eventDate, start) || isSameDay(eventDate, start)) &&
            (isBefore(eventDate, end) || isSameDay(eventDate, end))
          );
        }),
      }))
      .map((order) => ({
        ...order,
        items: order.items.filter(
          (item) =>
            item.name &&
            item.name
              .toLocaleLowerCase()
              .indexOf(debouncedSearchTerm.toLocaleLowerCase()) >= 0
        ),
      }));
    setFilteredOrders([...filtered]);
    setDefaultDates([
      {
        ...searchDate,
        key: 'selection',
      },
    ]);
  }, [
    debouncedSearchTerm,
    orders,
    searchDate,
    searchDate.column,
    searchDate.endDate,
    searchDate.startDate,
    setDefaultDates,
  ]);

  return filteredOrders;
}
