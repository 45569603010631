/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { getPerils } from './thunks';
import { PerilsState } from './types';

const initialState: PerilsState = {
  perils: {
    status: 'loading'
  }
};

export const PerilsSlice = createSlice({
  name: 'perils',
  initialState,
  reducers: {
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getPerils.fulfilled, (state, { payload }) => {
      state.perils = {
        data: payload.items,
        status: 'success'
      }
    })
    builder.addCase(getPerils.rejected, (state, action) => {
      const error = action.payload as string;
      state.perils = {
        status: 'error',
        errorMessage: error
      }
    })
    builder.addCase(getPerils.pending, (state, _) => {
      state.perils = {
        status: 'loading'
      }
    })
  }
});

export const { clearState } = PerilsSlice.actions;

export default PerilsSlice.reducer;
/* eslint-enable no-param-reassign */
