import React from 'react';

// components
import StatusHeader from 'components/OrdersPage/StatusHeader';
import ValueTooltip from 'components/ValueTooltip';

// utils
import { generateTranslation } from 'utils/translation/i18nextTranslation';

// types
import type { GridRenderCellParams } from '@mui/x-data-grid';
import {
  ClaimStatusType,
  ItemStatusType,
  ClaimStatusEnum,
  ItemStatusEnum,
} from 'store/modules/orders/types';
import type { StatusColumnType } from 'utils/translation/types';

export const getStatus = (
  status: ItemStatusType | '',
  claimStatus: ClaimStatusType
) => {
  try {
    if (!status) return '-';

    const translation = generateTranslation('statusColumn') as StatusColumnType;

    if (
      (status && !claimStatus) ||
      (status === ItemStatusEnum.Voided && claimStatus)
    )
      return translation.status[status].text;

    if (claimStatus === ClaimStatusEnum.Accepted)
      return translation.status.initiated.text;

    if (claimStatus === ClaimStatusEnum['On Hold']) return '-';

    return translation.status[claimStatus].text ?? '-';
  } catch (error) {
    return '-';
  }
};

export const statusColumn = {
  field: 'status',
  renderHeader: () => <StatusHeader />,
  valueGetter: ({ row }) => getStatus(row.status, row.claim_status),
  renderCell: (params: GridRenderCellParams<string>) => (
    <ValueTooltip value={params.value} />
  ),
};
