import React, { VFC } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, CircularProgress } from '@mui/material';

export type PaginationState =
  | undefined
  | {
      page: number;
      limit: number;
      offset: number;
      prev: boolean;
      next: boolean;
    };

export type PaginationProps = {
  data: PaginationState;
  title: string;
  count: number | undefined;
  goPrevious: () => void;
  goNext: () => void;
};

export const Pagination: VFC<PaginationProps> = function ({
  data,
  count,
  title,
  goNext,
  goPrevious,
}) {
  if (!data) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }
  const maxPage = count ? Math.floor(count / (data.limit + 1)) + 1 : 1;
  return (
    <Stack
      direction="row"
      spacing={2}
      style={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <IconButton disabled={!data.prev || data.page <= 1} onClick={goPrevious}>
        <ChevronLeftIcon />
      </IconButton>
      <Typography>
        {title}
        {data.page}/{maxPage}
      </Typography>
      <IconButton
        disabled={!data.next || data.page >= maxPage}
        onClick={goNext}
      >
        <ChevronRightIcon />
      </IconButton>
    </Stack>
  );
};
