import { RootState } from 'store';
import { Theme } from 'styles';
import { ProductInUrlType } from 'types';
import { FlowType } from './types';

export const selectConfigLoading = (state: RootState) =>
  state.configFileReducer.status === 'loading';
export const selectSteps = (state: RootState) =>
  state.configFileReducer.config.steps;
export const selectTheme = (state: RootState) =>
  state.configFileReducer.config.theme as Partial<Theme>;
export const selectUrlProduct = (
  state: RootState
): ProductInUrlType | undefined => state.configFileReducer.productInUrl;
export const selectFlowType = (state: RootState): FlowType =>
  state.configFileReducer.flow;
export const selectStartOver = (state: RootState) =>
  state.configFileReducer.startOver;
export const selectProductID = (state: RootState) =>
  state.configFileReducer.productId;
export const selectSubmissionMapping = (state: RootState) =>
  state.configFileReducer.config.submissionMapping;
export const selectTranslationOverride = (state: RootState) =>
  state.configFileReducer.config.translationOverride;
export const selectLinks = (state: RootState) =>
  state.configFileReducer.config.links;
export const selectLogo = (state: RootState) =>
  state.configFileReducer.config.logo;
export const selectNumberOfSteps = (state: RootState) =>
  state.configFileReducer.config.steps.length;
export const selectIsConfigStatusError = (state: RootState) =>
  state.configFileReducer.status === 'error';
