import { StepComponent } from 'components/FormBuilder/types';
import { Breakpoint } from '@mui/material';
import {
  HeaderDescriptionType,
  InputPanelType,
  DatePanelType,
  DecisionPanelType,
  AddressPanelType,
  FileUploadPanelType,
  StepSubmitButtonType,
  DescriptionPanelType,
  PerilsPanelType,
  TextAreaType,
  MultiSelectType,
  CreditCardType,
  UpdateBillingAddressPanelType,
  ReviewPanelType,
} from 'utils/translation/types';

export type ConfigTheme = {
  palette: {
    primary: {
      main: string;
      contrastText: string;
    };
    text: {
      primary: string;
    };
  };
};

export type ConfigFileTypes = {
  theme?: ConfigTheme;
  steps: ConfigStep[];
  submissionMapping: object; // we should definitely set some defaults structures there, to navigate through types
  translationOverride: {
    general: any;
    underwriter: any;
    product: any;
  };
  links: Links;
  logo?: string;
};

export type Links = {
  helpUrl: string;
  helpEmail: string;
  privacyPolicyUrl: string;
  contactUsUrl: string;
  termsAndConditions?: string; // raas
  helpPhone?: string; // recharge flow
};

export type Underwriter = keyof typeof UnderwriterEnum;

export enum UnderwriterEnum {
  'markel' = 'markel',
  'jsv' = 'jsv',
  'JSV' = 'JSV',
}

export enum ProductEnum {
  'fanshield' = 'fanshield',
  'regshield' = 'regshield',
  'tourshield' = 'tourshield',
  'shopguarantee' = 'shopguarantee',
  'enhancedrefundprogram' = 'raas',
  'optionalRAAS' = 'oraas',
}

export type ProductType =
  | 'fanshield'
  | 'regshield'
  | 'tourshield'
  | 'shopguarantee'
  | 'raas'
  | 'oraas';

export type ProductInUrlType = keyof typeof ProductEnum;

export type ConfigStep = {
  name: string;
  backButtonText: string;
  fields: ComponentStructure[];
  conditional?: string;
};

export type ComponentStructure = {
  component: StepComponent;
  props?: ComponentProps;
  verbiages: VerbiageTypes;
};

export type VerbiageTypes =
  | HeaderDescriptionType
  | InputPanelType
  | DatePanelType
  | DecisionPanelType
  | AddressPanelType
  | FileUploadPanelType
  | StepSubmitButtonType
  | DescriptionPanelType
  | PerilsPanelType
  | TextAreaType
  | MultiSelectType
  | CreditCardType
  | UpdateBillingAddressPanelType
  | ReviewPanelType;

export type ComponentProps =
  | InputPanelConfigProps
  | DatePanelConfigProps
  | DecisionPanelConfigProps
  | AddressPanelConfigProps
  | DescriptionPanelConfigProps
  | FileUploadPanelConfigProps
  | PerilsPanelConfigProps
  | TextAreaConfigProps
  | CreditCardConfigProps
  | ConditionalSubmitProps
  | MultiSelectProps;

export type ConditionalSubmitProps = {
  endpoint: string;
  requestType: RequestType;
  submissionMapping: object;
};

enum RequestTypeEnum {
  'PUT' = 'PUT',
  'POST' = 'POST',
  'PATCH' = 'PATCH',
  'GET' = 'GET',
  'DELETE' = 'DELETE',
}

export type RequestType = keyof typeof RequestTypeEnum;

export type AddressPanelConfigProps = FormFieldConfigProps;

export type DatePanelConfigProps = FormFieldConfigProps & {
  displayTime?: boolean;
};

export type InputPanelConfigProps = FormFieldConfigProps;

export type DecisionPanelConfigProps = {
  displayChildrenOnYes: boolean;
  displayCourtesyMessageOnYes?: boolean;
  childrenComponents: ComponentStructure[];
  name: string;
  defaultValue?: boolean;
};

export type DescriptionPanelConfigProps = FormFieldConfigProps & {
  title: string;
};

export type TextAreaPanelConfigProps = FormFieldConfigProps;

export type FileUploadPanelConfigProps = FormFieldConfigProps & {
  document_type: string;
};

export type PerilsPanelConfigProps = FormFieldConfigProps;

export type TextAreaConfigProps = FormFieldConfigProps;

export type CreditCardConfigProps = FormFieldConfigProps;

export type FormFieldConfigProps = {
  name: string;
  defaultValue?: string | object;
  required?: boolean;
  excludeFromReview?: true;
  reviewPageGrid?: { [key in Breakpoint]?: number };
  characterLimit?: number;
};

export type MultiSelectProps = {
  name: string;
  optionsEndpoint?: string;
  required: boolean;
};
