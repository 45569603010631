// react
import React, { useEffect, useState, VFC } from 'react';

// utils
import { stepName } from 'globalVariables';
import { generateSplitLayout } from 'utils/layout';

// hooks
import { useAppSelector } from 'store/hooks';

// redux
import { getForm } from 'store/modules/forms';
import {
  selectFlowType,
  selectUrlProduct,
} from 'store/modules/config/selectors';

// types
import type {
  ComponentStructure,
  ConfigStep,
  DatePanelConfigProps,
  InputPanelConfigProps,
} from 'types';

// hooks
import { useHistory } from 'react-router-dom';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { DatePanelType, InputPanelType } from 'utils/translation/types';
import EditPanel from '../EditPanel';
import ReviewBuilder from './ReviewBuilder';

const ReviewPanel: VFC<{
  layout: ConfigStep['fields'];
  stepId: number;
}> = function ({ layout, stepId }) {
  const step = `${stepName}${stepId}`;
  const stepForm = useAppSelector((state) => getForm(state, step));
  const type = useAppSelector(selectFlowType);
  const productInUrl = useAppSelector(selectUrlProduct);
  const [splitLayout, setLayout] =
    useState<Record<number, ComponentStructure[] | ComponentStructure>>();

  // hooks
  const history = useHistory();

  // constants
  const isRefundReviewPage =
    history.location.pathname.includes('/refund/step4');

  useEffect(() => {
    // split the layout every time we see a Perils panel.
    // Perils panels need to be displayed full width and not inside a grid layout
    setLayout(
      generateSplitLayout(
        layout.filter((component) => component.component !== 'ReviewPanel'),
        ['PerilsPanel'],
        ['HeaderDescription', 'StepSubmitButton']
      )
    );
  }, [layout]);

  const generateGroupedItems = () => {
    const eventName = layout.find(
      (item: any) => item?.props?.name === 'eventName'
    );
    const eventLocation = layout.find(
      (item: any) => item?.props?.name === 'location'
    );
    const eventDate = layout.find((item: any) => item?.props?.name === 'date');

    if (!eventName || !eventLocation || !eventDate) return null;

    const eventNameProps = eventName.props as InputPanelConfigProps;
    const eventLocationProps = eventLocation.props as InputPanelConfigProps;
    const eventDateProps = eventDate.props as DatePanelConfigProps;

    const eventNameVerbiages = eventName.verbiages as InputPanelType;
    const eventLocationVerbiages = eventLocation.verbiages as InputPanelType;
    const eventDateVerbiages = eventDate.verbiages as DatePanelType;

    return (
      <Grid item xs={12} sm={4}>
        <Box pb={3}>
          <Typography fontWeight="bold" pb={1}>
            {eventNameVerbiages.reviewPageTitle}:
          </Typography>
          <Typography>{stepForm[eventNameProps.name]}</Typography>
        </Box>
        <Box pb={3}>
          <Typography fontWeight="bold" pb={1}>
            {eventLocationVerbiages.reviewPageTitle}:
          </Typography>
          <Typography>{stepForm[eventLocationProps.name]}</Typography>
        </Box>
        <Box>
          <Typography fontWeight="bold" pb={1}>
            {eventDateVerbiages.reviewPageTitle}:
          </Typography>
          <Typography>{stepForm[eventDateProps.name]}</Typography>
        </Box>
      </Grid>
    );
  };

  if (!splitLayout || !stepForm) return null;

  return (
    <div>
      {Object.values(splitLayout).map((layout, indexLayout) => {
        if (!Array.isArray(layout)) {
          return (
            <EditPanel
              to={`/${productInUrl}/${type}/${step}`}
              key={indexLayout}
            >
              <ReviewBuilder
                type={layout.component}
                key={`reviewSplitComponent${indexLayout}`}
                props={layout.props}
                verbiages={layout.verbiages}
                formValues={stepForm}
              />
            </EditPanel>
          );
        }

        return (
          layout.length !== 0 && (
            <EditPanel
              to={`/${productInUrl}/${type}/${step}`}
              key={indexLayout}
            >
              <Grid
                container
                columnSpacing={1}
                rowSpacing={3}
                key={`reviewComponent${indexLayout}`}
                sx={{
                  justifyContent: 'space-evenly',
                  justifyItems: 'center',
                }}
              >
                {isRefundReviewPage && generateGroupedItems()}
                {layout.map((elem, index) => (
                  <ReviewBuilder
                    type={elem.component}
                    key={`reviewComponent${index}`}
                    verbiages={elem.verbiages}
                    props={elem.props}
                    formValues={stepForm}
                  />
                ))}
              </Grid>
            </EditPanel>
          )
        );
      })}
    </div>
  );
};

export default ReviewPanel;
