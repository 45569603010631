/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isEmptyObject } from 'utils/object';
import { getOrders } from './thunks';
import {
  OrdersState,
  OrdersDTO,
  FormattedItem,
  FormattedOrder,
  Pagination,
  EventDetails,
} from './types';

const initialState: OrdersState = {
  items: {
    status: 'idle',
  },
  selectedItems: [],
  email: '',
  cert_num: undefined,
  fileUploadConsent: false,
  selectedOrder: undefined,
};

export const OrdersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setSelectedItemsAndOrder: (
      state,
      action: PayloadAction<{
        items: FormattedItem[];
        order: FormattedOrder;
        cert_num?: string;
      }>
    ) => {
      const { items, order, cert_num } = action.payload;
      state.selectedItems = items;
      state.selectedOrder = order;
      state.cert_num = cert_num;
      const event = { ...items[0].event } as EventDetails | null | undefined;
      if (event && !isEmptyObject(event)) {
        state.selectedOrder.event = {
          date: event.start_date!,
          name: event.name!,
          location: event.location!.city,
        };
      } else state.selectedOrder.event = undefined;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setConsent: (state, action: PayloadAction<boolean>) => {
      state.fileUploadConsent = action.payload;
    },
    clearState: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getOrders.fulfilled, (state, { payload }) => {
      const data = payload.data as Pagination & OrdersDTO;
      state.items = {
        status: 'success',
        data: data.items,
        count: data.count,
      };
    });
    builder.addCase(getOrders.rejected, (state, action) => {
      const error = action.payload as string;
      state.items = {
        status: 'error',
        errorMessage: error,
      };
    });
    builder.addCase(getOrders.pending, (state, _) => {
      state.items = {
        status: 'loading',
      };
    });
  },
});

export const { setSelectedItemsAndOrder, setConsent, setEmail, clearState } =
  OrdersSlice.actions;

export default OrdersSlice.reducer;

/* eslint-enable no-param-reassign */
