export default {
  filePage: {
    title: 'Refund Protection Terms',
    description: 'Please enter the email you used for your purchase.',
  },
  appComponent: {
    title: 'Continue With Your Unfinished Refund request?',
    appTitle: 'Refund Protection Terms',
    footer: {
      title: 'Need to Start',
      disclaimer: '',
    },
  },
  emailSent: {
    description:
      "Click the link, and you'll be signed in. This is a one-time use link and expires in 4 hours.",
  },
  orderSelectionPage: {
    noOrders: 'No Refund Protection Terms found.',
    rules: {
      row1: '',
      row2: '',
    },
    header: {
      title: 'Your Refund Protection Terms',
      row2: "Please select the ID for which you'd like to view your Refund Protection Terms.",
    },
  },
  certificateOfCoveragePage: {
    description: {
      product: 'RegShield',
      part2:
        ' to protect your upcoming experience. Below is information regarding your Refund Protection. If you have any questions, please ',
    },
    block1: {
      cardTitle: 'Refund Protection Details',
      row1: 'Refund Protection',
      row2: 'Total Cost of Protection',
      title: 'Click here to view your Refund Protection Terms',
    },
  },
};
