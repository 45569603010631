import {
  createTheme,
  ThemeProvider,
  Theme,
  useTheme,
} from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';
import { deepmerge } from '@mui/utils';

const composeTheme = (theme?: Partial<Theme>): Theme => {
  const globalTheme = createTheme(
    deepmerge(
      {
        breakpoints: {
          values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1264,
            xl: 1904,
          },
        },
        palette: {
          primary: {
            main: '#00a3e0',
            contrastText: '#fff',
            light: 'rgba(0, 163, 224, 0.10)',
          },
          error: {
            main: '#ff5252',
          },
          success: {
            main: '#4caf50',
          },
          text: {
            primary: '#000',
          },
        },
        shape: {
          borderRadius: 0,
        },
        typography: {
          fontFamily: "'gotham', 'proxima-nova', 'Roboto', sans-serif",
          h1: {
            fontFamily: "'futura-pt', 'proxima-nova', 'Roboto', sans-serif",
          },
          h2: {
            fontFamily: "'futura-pt', 'proxima-nova', 'Roboto', sans-serif",
          },
          h3: {
            fontFamily: "'futura-pt', 'proxima-nova', 'Roboto', sans-serif",
          },
          h4: {
            fontFamily: "'futura-pt', 'proxima-nova', 'Roboto', sans-serif",
          },
          h5: {
            fontFamily: "'futura-pt', 'proxima-nova', 'Roboto', sans-serif",
          },
          h6: {
            fontFamily: "'futura-pt', 'proxima-nova', 'Roboto', sans-serif",
          },
          button: {
            fontSize: '1rem',
            textTransform: 'none',
          },
        },
        spacing: [0, 4, 8, 16, 24, 48],
      },
      theme
    )
  );
  return createTheme({
    ...globalTheme,
    components: {
      MuiLink: {
        styleOverrides: {
          root: {
            fontSize: '1rem',
            fontFamily: "'gotham', 'proxima-nova', 'Roboto', sans-serif",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            minWidth: '88px',
            height: '36px',
            '&:(.MuiLink-root)': {
              margin: '6px 8px',
            },
            '&, &.MuiLoadingButton-root': {
              transition: globalTheme.transitions.create(
                [
                  'background-color',
                  'box-shadow',
                  'border-color',
                  'color',
                  'width',
                ],
                {
                  duration: globalTheme.transitions.duration.short,
                }
              ),
            },
          },
        },
      },
      MuiFormControl: {
        styleOverrides: {
          root: {
            display: 'flex',
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            '.MuiDialogTitle-root + &': {
              paddingTop: '16px',
            },
            justifyContent: 'center',
          },
        },
      },
      MuiDialogContentText: {
        styleOverrides: {
          root: {
            color: globalTheme.palette.text.primary,
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            justifyContent: 'center',
            '.MuiButton-root': {
              margin: '0',
            },
            '.MuiButton-root:not(:first-of-type)': {
              marginLeft: '8px',
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            backgroundColor: 'white',
            border: '1px solid rgb(234, 234, 232)',
            boxShadow: '0px 1px 1px 0px rgba(0, 0, 0, 0.24)',
            '&:hover, &.Mui-focused': {
              backgroundColor: 'white',
            },
            '&:hover:not(.Mui-disabled):before': {
              borderBottom: '1px solid transparent',
            },
            '&:before': {
              borderBottom: '1px solid transparent',
            },
            '& .MuiFilledInput-input:not(.MuiInputBase-inputHiddenLabel):not(.MuiSelect-select):not(.MuiInputBase-inputMultiline)':
              {
                paddingTop: '23px',
                paddingBottom: '5px',
              },
            '& .MuiInputBase-inputHiddenLabel': {
              padding: '14px 12px',
            },
            '& .MuiSelect-select.MuiFilledInput-input': {
              paddingTop: '23px',
              paddingBottom: '5px',
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            margin: '8px 0',
            fontSize: '12px',
          },
        },
      },
      MuiListSubheader: {
        styleOverrides: {
          root: {
            fontSize: '14px',
            textAlign: 'center',
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          root: {
            minWidth: '888px',
            fontSize: '14px',
            borderBottomStyle: 'none',
            '& .MuiDataGrid-columnHeaders': {
              bgcolor: 'primary.light',
            },
            '& .MuiDataGrid-columnHeader:not(.MuiDataGrid-columnHeaderCheckbox), & .MuiDataGrid-cell:not(.MuiDataGrid-cellCheckbox)':
              {
                padding: (theme) => theme.spacing(0, 4),
              },
            '& .MuiDataGrid-cell': {
              whiteSpace: 'break-spaces',
              textAlign: 'left',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              fontWeight: 'bold',
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '& .MuiDataGrid-row.Mui-selected': {
              bgcolor: 'transparent',
            },
            '& .MuiDataGrid-row:hover, & MuiDataGrid-row.Mui-hovered, & .MuiDataGrid-row.Mui-selected:hover':
              {
                bgcolor: '#eee',
              },
            '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within':
              {
                outline: 'none',
              },
          },
        },
      },
    },
  });
};

export { composeTheme, ThemeProvider, useTheme };
export type { Theme };
