import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from 'components/LoadingButton';
import Rating from '@mui/material/Rating';
import TextArea from 'components/TextArea';
import { FooterProps } from './types';
import { characterLimit } from './index';

const Footer: React.VFC<FooterProps> = function ({
  comment,
  isFeedbackLoading,
  rating,
  setComment,
  setRating,
  submitRating,
  translation,
}) {
  return (
    <>
      <>
        <div className="text-center">
          <Box mb={4}>
            <Typography className="font-weight-black" mb={2}>
              {translation.rateText}
            </Typography>

            <Rating
              name="half-rating"
              precision={0.5}
              value={rating}
              onChange={(e, value) => {
                setRating(value);
              }}
              size="large"
              sx={{
                fontSize: { xs: '40px', sm: '60px' },
                '& .MuiRating-iconFilled': {
                  color: 'primary.main',
                },
                '& .MuiRating-iconHover': {
                  color: 'primary.main',
                },
                '& .MuiRating-iconEmpty': {
                  color: 'primary.main',
                },
              }}
            />
          </Box>

          <Typography className="font-weight-black" mt={2}>
            {translation.commentText}
          </Typography>
        </div>

        <Box width={{ xs: '100%', sm: '50%' }}>
          <TextArea
            characterLimit={characterLimit}
            setValue={setComment}
            value={comment}
            label={translation.textAreaDescription}
            name="description"
          />
        </Box>
      </>

      <LoadingButton
        variant="contained"
        loading={isFeedbackLoading}
        disabled={comment.length > characterLimit}
        onClick={submitRating}
        className="btn-style"
      >
        {translation.feedback.button}
      </LoadingButton>
    </>
  );
};

export default Footer;
