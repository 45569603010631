import { useState } from 'react';

const useSearchTerm = (): [string, (newSearchTerm: string) => void] => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  const setRouterSearchTerm = (newSearchTerm: string) => {
    setSearchTerm(newSearchTerm);
  };

  return [searchTerm, setRouterSearchTerm];
};

export default useSearchTerm;
