import React, { Dispatch, SetStateAction, VFC } from 'react';

import TextField from '@mui/material/TextField';

const TextArea: VFC<{
  characterLimit: number;
  value: string;
  name: string;
  label: string;
  setValue: Dispatch<SetStateAction<string>>;
}> = function ({ characterLimit, value, setValue, label, name }) {
  const handleChange = (newValue: string) => {
    setValue(newValue);
  };

  return (
    <TextField
      label={label}
      inputProps={{
        maxLength: characterLimit,
      }}
      sx={{
        '& .MuiFormHelperText-root': {
          color: 'text.primary',
          textAlign: 'right',
          mx: 0,
          mt: 1,
        },
      }}
      value={value}
      helperText={`${value.length}/${characterLimit}`}
      onChange={(e) => handleChange(e.target.value)}
      multiline
      minRows={5}
      variant="filled"
      name={name}
    />
  );
};

export default TextArea;
