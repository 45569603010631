import React, { useEffect, useState } from 'react';
import {
  selectFlowType,
  selectSteps,
  selectUrlProduct,
} from 'store/modules/config/selectors';
import { useAppSelector } from 'store/hooks';
import { useLocation } from 'react-router-dom';
import { generateProgressBarSteps } from 'utils/progressBar';
import { stepName } from 'globalVariables';

import Stack from '@mui/material/Stack';
import BackArrow from 'components/BackArrow';
import Stepper from 'components/Stepper';

export type ProgressBarStepProps = {
  text: string;
  stateName: string;
  to?: string;
  backText?: string;
};

const ProgressBar: React.FC = function () {
  const routes = useAppSelector(selectSteps);
  const type = useAppSelector(selectFlowType);
  const productInUrl = useAppSelector(selectUrlProduct);
  const location = useLocation();
  const [state, setState] = useState<{
    currentStep: ProgressBarStepProps | null;
    activeIndex: number;
  }>({
    currentStep: null,
    activeIndex: 0,
  });
  const [steps] = useState<ProgressBarStepProps[]>([
    ...generateProgressBarSteps(routes, stepName, type, productInUrl!),
  ]);
  const hideStepper =
    routes.some((route) => Boolean(route.conditional)) || steps.length === 1;

  useEffect(() => {
    const currentRouteName = location.pathname;
    const match = steps.some((step, index) => {
      if (step.stateName === currentRouteName) {
        setState({
          currentStep: step,
          activeIndex: index,
        });
        return true;
      }
      return false;
    });
    if (!match)
      setState({
        currentStep: null,
        activeIndex: 0,
      });
  }, [location.pathname, steps]);

  return state.currentStep && state.currentStep.text !== 'ThankYouPage' ? (
    <>
      <BackIcon currentStep={state.currentStep} />
      {!hideStepper && (
        <Stack
          direction="row"
          justifyContent="center"
          alignItems="center"
          mb={4}
        >
          <Stepper currentStep={state.activeIndex} steps={steps} />
        </Stack>
      )}
    </>
  ) : null;
};

const BackIcon: React.FC<{
  currentStep: ProgressBarStepProps;
}> = function ({ currentStep }) {
  if (currentStep.to)
    return <BackArrow to={currentStep.to ?? '/'} text={currentStep.backText} />;

  return null;
};

export default ProgressBar;
