import React, { VFC } from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import HeaderDescription from 'components/HeaderDescription';
import { FeedbackSubmittedProp } from './types';

export const FeedbackSubmitted: VFC<FeedbackSubmittedProp> = function ({
  translation,
  startOver,
}) {
  return (
    <>
      <HeaderDescription title={translation.header.title.submitted} />
      <Typography mb={2}>{translation.feedback.text}</Typography>
      <Button variant="contained" onClick={startOver} className="btn-style">
        {translation.button}
      </Button>
    </>
  );
};
