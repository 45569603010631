/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AlertColor } from '@mui/material/Alert';

type Alert = {
  type?: AlertColor | undefined;
  message: string;
};

export type AlertState = {
  alert: Alert;
};

const initialState: AlertState = {
  alert: {
    type: undefined,
    message: '',
  },
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    pushMessageAlert: (state, action: PayloadAction<Alert>) => {
      const { message, type = 'error' } = action.payload;
      state.alert = { message, type };
    },
  },
});

export const { pushMessageAlert } = alertSlice.actions;

export default alertSlice.reducer;
/* eslint-enable no-param-reassign */
