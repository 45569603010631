// react
import { useCallback } from 'react';

// packages
import { useDeepCompareEffect } from 'react-use';
import { useForm, SubmitHandler, SubmitErrorHandler } from 'react-hook-form';

// redux
import { updateForm, getForm, Form as FormType } from 'store/modules/forms';

// hooks
import { useAppSelector, useAppDispatch } from 'store/hooks';

const createPayloadByDirtyFields = (dirtyFields: any, changes: any) =>
  Object.keys(dirtyFields?.content).reduce(
    (result, key) => ({
      ...result,
      [key]: changes[key],
    }),
    {}
  );

// Updates form when Redux has changed
const useFormUpdater = (stepForm: any, setValue: Function) => {
  useDeepCompareEffect(() => {
    const setValueToKey = ([key, value]: [string, any]) => {
      setValue(key, value, { shouldDirty: false });
    };
    stepForm && Object.entries(stepForm).forEach(setValueToKey);
  }, [stepForm, setValue]);
};

// Updates redux when form has changed
const useReduxStepUpdater = (useFormApi: any, onChangeAction: any) => {
  const formChanges: any = useFormApi.watch();
  const { dirtyFields } = useFormApi.formState;
  useDeepCompareEffect(() => {
    if (Object.keys(formChanges).length > 0) {
      if (dirtyFields?.content) {
        const formUpdates = createPayloadByDirtyFields(
          dirtyFields,
          formChanges
        );
        onChangeAction(formUpdates);
      }
    }
  }, [formChanges]);
};

function useBuilderForm(formName: string, useFormProps: any) {
  const stepForm = useAppSelector((state) => getForm(state, formName));
  const dispatch = useAppDispatch();

  const useFormApi = useForm({
    ...useFormProps,
  });
  const { handleSubmit } = useFormApi;

  const updateFormReduxAction = useCallback(
    (updates: FormType) =>
      dispatch(updateForm({ name: formName, data: updates })),
    [dispatch, formName]
  );

  const onSubmitHandler = useCallback(
    (onValid: SubmitHandler<any>, onInvalid?: SubmitErrorHandler<any>) =>
      handleSubmit((data, event) => {
        // if the data submitted includes the attendance_decision key, but it's not selected, reset the arrival and departure dates to null
        if (
          typeof data === 'object' &&
          !Array.isArray(data) &&
          data !== null &&
          Object.prototype.hasOwnProperty.call(data, 'attendance_decision') &&
          !data.attendance_decision
        ) {
          // eslint-disable-next-line no-param-reassign
          data.attendance_arrival_date = null;
          // eslint-disable-next-line no-param-reassign
          data.attendance_departure_date = null;
        }
        updateFormReduxAction(data);
        onValid(data, event);
      }, onInvalid),
    [handleSubmit, updateFormReduxAction]
  );

  // when form mounts, check if step is in redux store, then update default form with values
  useFormUpdater(stepForm, useFormApi.setValue);

  // update form in redux on handleSubmit call
  useReduxStepUpdater(useFormApi, updateFormReduxAction);

  return {
    ...useFormApi,
    handleSubmit: onSubmitHandler,
  };
}

export default useBuilderForm;
