/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { stepName } from 'globalVariables';
import { selectNumberOfSteps } from '../config/selectors';
import { selectIsOrderPageComplete } from '../orders/selectors';

export type Form = {
  [key: string]: any;
};

export type FormsState = {
  forms: { [key: string]: Form };
};

const initialState: FormsState = {
  forms: {},
};

export const FormsSlice = createSlice({
  name: 'forms',
  initialState,
  reducers: {
    updateForm: (
      state,
      action: PayloadAction<{ name: string; data: Form }>
    ) => {
      const { name, data } = action.payload;
      state.forms[name] = { ...state.forms[name], ...data };
    },
    deleteAllFiles: (
      state,
      action: PayloadAction<{
        step: string;
        fieldName: string;
      }>
    ) => {
      const { fieldName, step } = action.payload;
      state.forms[step][fieldName] = [];
    },
    deleteSingleFile: (
      state,
      action: PayloadAction<{
        step: string;
        fieldName: string;
        fileId: string;
      }>
    ) => {
      const { fieldName, step, fileId } = action.payload;
      const files = [...state.forms[step][fieldName]];
      state.forms[step][fieldName] = [
        ...files.filter((file) => file.id !== fileId),
      ];
    },
    clearAllForms: () => initialState,
  },
});

export const { clearAllForms, updateForm, deleteAllFiles, deleteSingleFile } =
  FormsSlice.actions;

export const getForm = (state: RootState, name: string) =>
  state.formsReducer.forms[name];
export const getEntireForm = (state: RootState) => state.formsReducer.forms;

const isComplete = (state: RootState, name: string) =>
  Boolean(state.formsReducer.forms[name]);

export const selectAllStepsComplete = (state: RootState) => {
  const lastStepIndex = selectNumberOfSteps(state);
  const lastStepName = `${stepName}${lastStepIndex}`;
  return selectIsOrderPageComplete(state) && isComplete(state, lastStepName);
};

export const getLastUncompletedStep = (state: RootState): string => {
  const lastStepIndex = selectNumberOfSteps(state);
  const orderPageIsCompleted = selectIsOrderPageComplete(state);
  if (!orderPageIsCompleted) return '/';
  // eslint-disable-next-line no-plusplus
  for (let step = 1; step <= lastStepIndex; step++) {
    const stepComplete = isComplete(state, `${stepName}${step}`);
    if (!stepComplete) {
      return `/${stepName}${step}`;
    }
  }

  return `/${stepName}1`;
};

export default FormsSlice.reducer;
/* eslint-enable no-param-reassign */
