import React, { memo, VFC } from 'react';
import {
  GridRowsProp,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import { formattedCost } from 'utils/helpers';
// eslint-disable-next-line import/no-extraneous-dependencies
import isEqual from 'lodash/isEqual';
import { statusColumn } from 'utils/itemTable';
import ValueTooltip from 'components/ValueTooltip';
import { ItemTableProps } from '../types';
import GenericItemTable from './GenericItemTable';

const ItemTable: VFC<ItemTableProps> = memo(
  ({
    order,
    items,
    selected,
    handleSelectionUpdate,
    handleAttemptToSelect,
  }) => {
    const hasSelections = selected.length > 0;

    const rows: GridRowsProp = items.map((item, index) => ({
      id: index,
      status: item.status,
      claim_status: item.claim_status,
      number: item.reference_id,
      name: item.name,
      coverage_amount: item.unit_cost,
    }));

    const defaultGridColDef = {
      sortable: false,
      hideable: false,
    };

    const columns: GridColDef[] = [
      {
        field: 'number',
        headerName: 'Certificate ID',
        renderCell: (params: GridRenderCellParams<string>) => (
          <ValueTooltip value={params.value} />
        ),
        ...defaultGridColDef,
        flex: 1,
      },
      {
        field: 'name',
        headerName: 'Description',
        renderCell: (params: GridRenderCellParams<string>) => (
          <ValueTooltip value={params.value} />
        ),
        ...defaultGridColDef,
        flex: 3,
      },
      {
        field: 'coverage_amount',
        headerName: 'Covered Amount',
        flex: 1,
        valueFormatter: (params) =>
          formattedCost(
            parseFloat(params.value as string),
            order.locale,
            order.currency
          ),
        ...defaultGridColDef,
      },
      {
        ...statusColumn,
        ...defaultGridColDef,
        flex: 1,
      },
    ];
    return (
      <GenericItemTable
        order={order}
        items={items}
        rows={rows}
        columns={columns}
        hasSelections={hasSelections}
        selected={selected}
        handleSelectionUpdate={handleSelectionUpdate}
        handleAttemptToSelect={handleAttemptToSelect}
      />
    );
  },
  (prevProps, nextProps) => isEqual(prevProps.selected, nextProps.selected)
);

export default ItemTable;
