// react
import React from 'react';

// packages
import Box from '@mui/material/Box';

// components
import V1Layout from 'layouts/V1';

const style = {
  text: {
    position: 'absolute',
    textAlign: 'center',
    fontWeight: 'bold',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
};

const FourOFourPage = function () {
  return (
    <V1Layout>
      <Box sx={style.text}>404 Webpage Not Found</Box>
    </V1Layout>
  );
};

export default FourOFourPage;
