import { ColumnFilter } from 'components/OrdersPage/DateSearchPanel';
import { useState } from 'react';

export type SearchDates = {
  startDate: Date | null;
  endDate: Date | null;
  column: ColumnFilter | null;
};

const useSearchDates = (): [
  SearchDates,
  (newSearchTerm: SearchDates) => void
] => {
  const [searchDate, setSearchDate] = useState<SearchDates>({
    column: null,
    endDate: null,
    startDate: null,
  });

  const setRouterSearchTerm = (newSearchDate: SearchDates) => {
    setSearchDate(newSearchDate);
  };

  return [searchDate, setRouterSearchTerm];
};

export default useSearchDates;
