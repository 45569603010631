// react
import React, { ReactNode, VFC } from 'react';

// packages
import Box from '@mui/material/Box';

const V2Layout: VFC<{ children: ReactNode }> = function ({ children }) {
  return <Box component="main">{children}</Box>;
};

export default V2Layout;
