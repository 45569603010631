import React, { useEffect, VFC } from 'react';

import { Controller, UseFormReturn } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectPerils,
  selectPerilsLoading,
  selectPerilsErrorMessage,
} from 'store/modules/perils/selectors';
import { getPerils } from 'store/modules/perils/thunks';

import Typography from '@mui/material/Typography';
import FormHelperText from '@mui/material/FormHelperText';
import Panel from 'components/Panel';
import PerilSelection from 'components/PerilSelection';
import CircularProgress from 'components/CircularProgress';
import { generateTranslation } from 'utils/translation/i18nextTranslation';
import { PerilsPage } from 'utils/translation/types';

export const PerilsPanel: VFC<{
  name: string;
  formApi: UseFormReturn<any, any>;
}> = function ({ name, formApi: { control } }) {
  const perils = useAppSelector(selectPerils);
  const perilsLoading = useAppSelector(selectPerilsLoading);
  const perilsErrorMessage = useAppSelector(selectPerilsErrorMessage);

  const dispatch = useAppDispatch();
  const translation = generateTranslation('perilsPage') as PerilsPage;

  useEffect(() => {
    if (perils.length < 1) {
      dispatch(getPerils());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <Panel>
            <Typography
              sx={{
                pt: {
                  sm: 2,
                },
                pl: {
                  sm: 3,
                },
              }}
            >
              {translation.title}
            </Typography>

            {perilsErrorMessage && (
              <Typography
                color="error.main"
                align="center"
                variant="body1"
                sx={{ my: 5 }}
              >
                {perilsErrorMessage}
              </Typography>
            )}

            {perilsLoading && (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </div>
            )}

            {perils.length > 0 && (
              <PerilSelection
                perils={perils}
                selectedPeril={value}
                updateSelection={onChange}
              />
            )}
          </Panel>

          {error?.message && (
            <FormHelperText error className="text-center">
              {error?.message}
            </FormHelperText>
          )}
        </>
      )}
    />
  );
};

export default PerilsPanel;
