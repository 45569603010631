import { RootState } from 'store';

const selectEmailLoading = (state: RootState) => state.emailReducer.loading;
const selectEmailError = (state: RootState) => state.emailReducer.errorMessage;
const selectEmailSuccess = (state: RootState) =>
  state.emailReducer.successMessage;
const selectLinkEmail = (state: RootState) => state.emailReducer.email;
const selectFileComplete = (state: RootState) => !!selectLinkEmail(state);

export {
  selectEmailLoading,
  selectEmailError,
  selectEmailSuccess,
  selectLinkEmail,
  selectFileComplete,
};
