import { formatServerError } from 'utils/response';
import { AxiosError } from 'axios';
import { pushMessageAlert } from 'store/modules/alert/slice';
import { ProductType, ProductEnum } from 'types';
import { selectUrlProduct } from './modules/config/selectors';
import { getProductValue } from './utils/hooks';

export type ErrorDetails = {
  loc: string[];
  msg: string;
  type: string;
};

export type ErrorData = {
  originalMessage: string;
  detail: string | ErrorDetails[];
  error: {
    message: string;
    errors: { message: string }[];
  };
  msg?: string;
  type?: string;
};

export type ErrorResponse = {
  status: number;
  statusText: string;
  data: ErrorData | string;
};

export type Error = {
  response: ErrorResponse;
};

interface ErrorCode {
  statusCode: number;
  customMessage: string;
}

export const sessionExpiredError =
  'Your login link is single-use and has expired. Please request a new login link via email to proceed.';

export const customErrorMessageInterceptorByStatusCodes = (
  error: AxiosError,
  errorCodes: ErrorCode[],
  dispatch
) => {
  const errorStatus = error.response?.status;
  const customError = { ...error };

  const matchingErrorCode = errorCodes.find(
    (errorCode) => errorCode.statusCode === errorStatus
  );

  if (matchingErrorCode && customError.response) {
    customError.response.data.detail = matchingErrorCode.customMessage;
  }

  return errorInterceptor(customError, dispatch);
};

export const errorInterceptor = (error: AxiosError, dispatch, getState?) => {
  // For normal API responses assign here
  const errorStatus = error.response?.status || 0;
  let pushMessage = error.response?.statusText || '';

  // Respond to 500-level errors
  if (errorStatus >= 500) {
    pushMessage = 'An error has occurred';
    formatServerError(error.response, pushMessage);
  }

  // Respond to 400-level errors
  if (errorStatus >= 400 && errorStatus < 500) {
    const responseObj = error.response?.data as ErrorData;
    if (responseObj?.detail) {
      if (typeof responseObj.detail === 'string') {
        pushMessage = responseObj.detail;
      } else {
        const [topError] = responseObj.detail;
        pushMessage = `${topError.loc[topError.loc.length - 1]}: ${
          topError.msg
        }`;
      }
    } else if (responseObj?.msg) {
      pushMessage = responseObj?.msg;
    } else if (responseObj?.error?.message) {
      pushMessage = responseObj?.error?.message;
    } else {
      pushMessage = 'Not found';
    }
  }

  if (errorStatus === 401 || errorStatus === 403)
    pushMessage = sessionExpiredError;

  if (getState) {
    const product = getProductValue(selectUrlProduct(getState()));

    if (!raasOrderErrorMessage(pushMessage, product, getState))
      dispatch(pushMessageAlert({ message: pushMessage }));
  } else dispatch(pushMessageAlert({ message: pushMessage }));

  return Promise.reject(error);
};

const raasOrderErrorMessage = (
  pushMessage: string,
  product: ProductType | undefined,
  getState: any
): boolean =>
  Boolean(getState) &&
  pushMessage.toLowerCase().indexOf('order') >= 0 &&
  product === ProductEnum.enhancedrefundprogram;
