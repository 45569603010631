// mocks
import {
  raasGeneralConfig,
  raasSteps,
  raasSubmissionMapping,
} from 'mocks/products/raasNoClient';

// types
import type { ConfigFileTypes } from 'types';

export const raasRefundConfig: ConfigFileTypes = {
  ...raasGeneralConfig,
  steps: raasSteps,
  submissionMapping: raasSubmissionMapping,
};
