import React, { useRef, VFC, Dispatch, SetStateAction, useEffect } from 'react';
import { today } from 'utils/date';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import CloseIcon from '@mui/icons-material/Close';
import SignaturePad from 'react-signature-pad-wrapper';

const styles = {
  signatureContainer: {
    border: '2px solid rgb(234, 234, 232)',
    borderRadius: '6px',
    position: 'relative',
    height: '200px',
    width: {
      xs: '300px',
      sm: '499px',
    },
  },
  contactInfo: {
    position: 'absolute',
    textAlign: 'center',
    bottom: '10px',
    width: '100%',
  },
  clear: {
    position: 'absolute',
    top: '5px',
    right: '5px',
    cursor: 'pointer',
    color: 'grey.700',
  },
};

const Signature: VFC<{
  name: string;
  updateSignature: Dispatch<SetStateAction<string>>;
}> = function ({ name, updateSignature }) {
  const signaturePad = useRef<SignaturePad>(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (signaturePad.current && !signaturePad.current.isEmpty()) {
        const signature = signaturePad.current.toDataURL('image/png');
        updateSignature(signature);
      }
    }, 500);
    return () => clearInterval(intervalId);
  }, [updateSignature]);

  const clear = () => {
    if (signaturePad.current) {
      signaturePad.current.clear();
      updateSignature('');
    }
  };

  return (
    <Box sx={styles.signatureContainer}>
      <SignaturePad
        ref={signaturePad}
        canvasProps={{
          className: 'signature',
        }}
      />
      <Box sx={styles.contactInfo}>
        <Divider sx={{ mx: 2, border: '1px solid rgba(234, 234, 232, 0.5)' }} />
        {name && (
          <Box component="span" sx={{ pr: 1 }}>
            {name},
          </Box>
        )}
        <span>{today()}</span>
      </Box>
      <CloseIcon sx={styles.clear} onClick={clear} />
    </Box>
  );
};

export default Signature;
