/* eslint-disable no-param-reassign */
import * as ls from 'utils/localstorage';
import { createSlice } from '@reduxjs/toolkit';
import { extendAccessToken, getAccessToken } from './thunk';
import { AuthState } from './types';

const initialState: AuthState = {
  accessToken: null,
  singleUseToken: null,
  status: 'idle',
};

export const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    clearState: () => initialState,
    setSingleUseToken: (state, { payload }) => {
      state.singleUseToken = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAccessToken.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(getAccessToken.rejected, (state, action) => {
      state.status = 'error';
      state.accessToken = null;
    });
    builder.addCase(getAccessToken.fulfilled, (state, action) => {
      state.status = 'success';
      const token = action.payload;
      state.accessToken = token;
      ls.setToken(token);
    });
    builder.addCase(extendAccessToken.pending, (state, action) => {
      state.status = 'loading';
    });
    builder.addCase(extendAccessToken.rejected, (state, action) => {
      state.status = 'error';
      state.accessToken = null;
    });
    builder.addCase(extendAccessToken.fulfilled, (state, action) => {
      state.status = 'success';
      state.accessToken = action.payload;
    });
  },
});

export const { clearState, setSingleUseToken } = AuthSlice.actions;

export default AuthSlice.reducer;
/* eslint-enable no-param-reassign */
