// react
import React from 'react';

// packages
import Box from '@mui/material/Box';

// components
import LandingPageItem from 'components/LandingPageItem';

// layouts
import V2Layout from 'layouts/V2';

// utils
import { getEnvShortname } from 'utils/env';

const envShortname = getEnvShortname();

const url =
  process.env.REACT_APP_MOCK === 'true'
    ? // if we're mocking data, set the landing page links to be relative
      `/enhancedrefundprogram`
    : // otherwise use a URL scoped to the current environment and without client-specific subdomains
      `https://${
        envShortname ? `${envShortname}.` : ''
      }requestmyrefund.com/enhancedrefundprogram`;

const LandingPage = function () {
  const items = [
    {
      description:
        'To submit a refund request for your event ticket, registration, or reservation cost due to a covered reason, please click below.',
      href: `${url}/refund`,
      path: (
        <>
          <circle cx="17.5" cy="17.5" r="17.5" fill="#0B82EF" />
          <g clipPath="url(#a)">
            <path
              fill="#fff"
              d="M17.5 16.977c-1.078-.387-1.598-.63-1.598-1.247 0-.67.672-.912 1.096-.912.793 0 1.084.65 1.15.879l.958-.44c-.09-.295-.497-1.26-1.539-1.47v-.813h-1.211v.826c-1.503.368-1.509 1.877-1.509 1.943 0 1.49 1.363 1.91 2.03 2.172.957.368 1.38.702 1.38 1.332 0 .742-.635 1.057-1.199 1.057-1.102 0-1.417-1.227-1.453-1.372l-1.006.44c.382 1.437 1.381 1.824 1.757 1.942v.847h1.211v-.814c.243-.059 1.757-.387 1.757-2.113 0-.912-.37-1.713-1.823-2.257Zm-5.99 6.497h-1.212v-3.938h3.635v1.313H12.43c.976 1.581 2.642 2.625 4.532 2.625 3.01 0 5.451-2.645 5.451-5.907h1.212c0 3.99-2.98 7.22-6.663 7.22-2.254 0-4.247-1.215-5.452-3.066v1.753Zm-1.212-5.907c0-3.99 2.98-7.218 6.664-7.218 2.253 0 4.246 1.214 5.451 3.064v-1.752h1.212V15.6H19.99v-1.313h1.503c-.976-1.582-2.641-2.625-4.531-2.625-3.011 0-5.452 2.645-5.452 5.906h-1.212Z"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M9.692 9.692h14.54v15.75H9.691z" />
            </clipPath>
          </defs>
        </>
      ),
      linkTitle: 'Request a Refund',
      title:
        "I'd like to submit a refund request because I cannot use my ticket or reservation for one of the covered reasons.",
    },
    {
      description:
        'You can void your refund protection and receive a refund for only the protection cost within the terms provided in your coverage.  We will not cancel your ticket or reservation, but it will not be refundable anymore. To void your refund protection, please click below.',
      href: `${url}/cancellation`,
      path: (
        <>
          <circle cx="17.5" cy="17.5" r="17.5" fill="#0B82EF" />
          <path
            fill="#fff"
            d="m17.098 8.48-7.048 2.727v5.533c0 4.59 3.004 8.869 7.048 9.914 4.043-1.045 7.047-5.325 7.047-9.914v-5.533L17.098 8.48Zm3.083 10.986-1.242 1.282-1.841-1.89-1.841 1.89-1.242-1.282 1.84-1.899-1.84-1.899 1.242-1.281 1.84 1.9 1.842-1.9 1.242 1.281-1.832 1.9 1.832 1.898Z"
          />
        </>
      ),
      linkTitle: 'Void Refund Protection',
      title:
        'I changed my mind or accidentally purchased refund protection and would like a refund for only the refund protection cost.',
    },
    {
      description:
        'To review your confirmation of coverage, please click below.',
      href: `${url}/certificate`,
      path: (
        <>
          <circle cx="17.5" cy="17.5" r="17.5" fill="#0B82EF" />
          <g clipPath="url(#a)">
            <path
              fill="#fff"
              d="M18.833 9.596h-6.274c-.863 0-1.56.706-1.56 1.569l-.009 12.548c0 .862.698 1.568 1.561 1.568h9.419c.863 0 1.569-.706 1.569-1.568v-9.411l-4.706-4.706Zm.784 7.843H16.48v.784h2.353c.431 0 .784.353.784.784v2.353a.787.787 0 0 1-.784.784h-.784v.785H16.48v-.785h-1.568v-1.568h3.137v-.785h-2.353a.787.787 0 0 1-.784-.784v-2.353c0-.43.353-.784.784-.784h.784v-.784h1.569v.784h1.568v1.569Zm-1.568-3.137v-3.53l3.529 3.53h-3.53Z"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M9.692 7h18.173v18.173H9.692z" />
            </clipPath>
          </defs>
        </>
      ),
      linkTitle: 'Confirmation of Coverage',
      title: "I'd like to review my refund protection order details.",
    },
    {
      description:
        'To view answers to frequently asked questions, please click below.',
      href: 'https://help.requestmyrefund.com/general-raas',
      path: (
        <>
          <circle cx="17.5" cy="17.5" r="17.5" fill="#0B82EF" />
          <g clipPath="url(#a)">
            <path
              fill="#fff"
              d="M22.615 10.904H11.308c-.897 0-1.616.623-1.616 1.385v9.692c0 .761.72 1.384 1.616 1.384h3.23l2.424 2.077 2.423-2.077h3.23c.889 0 1.616-.623 1.616-1.384v-9.693c0-.761-.727-1.384-1.616-1.384ZM17.77 21.98h-1.615v-1.385h1.615v1.385Zm1.672-5.366-.727.637c-.581.506-.945.921-.945 1.96h-1.615v-.347c0-.761.363-1.453.945-1.959l1.001-.872c.3-.25.477-.595.477-.976 0-.762-.727-1.385-1.615-1.385-.889 0-1.616.623-1.616 1.385h-1.615c0-1.53 1.446-2.77 3.23-2.77 1.786 0 3.231 1.24 3.231 2.77 0 .609-.29 1.163-.75 1.557Z"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" d="M8.48 9.692h18.174v15.75H8.48z" />
            </clipPath>
          </defs>
        </>
      ),
      linkTitle: 'Frequently Asked Questions',
      title: 'Additional Information',
    },
  ];
  return (
    <V2Layout>
      <Box
        component="section"
        sx={{
          backgroundColor: '#eee',
          paddingX: { xs: '1rem', md: '2rem' },
          paddingY: { xs: '3rem', md: '5rem' },
        }}
      >
        <Box
          component="ul"
          sx={{
            listStyleType: 'none',
            margin: 0,
            padding: 0,
            '& .MuiBox-root:first-child': {
              marginTop: 0,
            },
          }}
        >
          {items.map((item, i) => (
            <LandingPageItem key={i} item={item} />
          ))}
        </Box>
      </Box>
    </V2Layout>
  );
};

export default LandingPage;
