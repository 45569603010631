import { ErrorData } from 'store/errorInterceptor';
import { AxiosResponse } from 'axios';

const formatServerError = (
  response?: AxiosResponse,
  message = 'An error has occurred'
) => {
  const errorResponseTemplate: ErrorData = {
    originalMessage: '',
    detail: '',
    error: {
      message: '',
      errors: [{ message }],
    },
  };

  if (typeof response?.data === 'string') {
    errorResponseTemplate.originalMessage = response.data;
    response.data = errorResponseTemplate;
  }
};

export { formatServerError };
