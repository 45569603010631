import { iso31661, iso31662 } from 'iso-3166';

interface Subdivision {
  code: string;
  name: string;
  parent: string;
}

export interface SubdivisionWithChildren extends Subdivision {
  children?: SubdivisionWithChildren[];
}

export const getCountriesList = () =>
  iso31661
    .filter(
      (c) =>
        c.alpha2 !== 'AS' && // American Samoa
        c.alpha2 !== 'GU' && // Guam
        c.alpha2 !== 'MP' && // Northern Mariana Islands
        c.alpha2 !== 'PR' && // Puerto Rico
        c.alpha2 !== 'VI'
    ) // US Virgin Islands
    .sort((a, b) => a.name.localeCompare(b.name));

export const getCountryAlpha2FromAlphaCode = (alphaCode: string) =>
  getCountriesList().find(
    (c) => c.alpha2 === alphaCode || c.alpha3 === alphaCode
  )?.alpha2 || '';

export const getCountrySubdivisions = (
  countryCode: string
): SubdivisionWithChildren[] | ['n/a'] => {
  const country = getCountriesList().find((c) => c.alpha2 === countryCode);
  if (!country) {
    return [];
  }

  const subdivisionsInCountry = iso31662.filter(
    (s) => s.parent === countryCode
  );

  if (subdivisionsInCountry.length === 0) return ['n/a'];

  let result: SubdivisionWithChildren[] = [];
  subdivisionsInCountry.forEach((subdivision: Subdivision) => {
    const subdivisionWithChildren: SubdivisionWithChildren = { ...subdivision };
    const childSubdivisions = iso31662
      .filter((s) => s.parent === subdivision.code)
      .sort((a, b) => a.name.localeCompare(b.name));
    if (childSubdivisions.length > 0) {
      subdivisionWithChildren.children = childSubdivisions;
    }
    result.push(subdivisionWithChildren);
  });

  if (country.name === 'United States of America') {
    const usTerritories = {
      name: 'US Territories',
      code: 'US-TERR',
      parent: 'US',
      children: [
        { code: 'US-AS', name: 'American Samoa', parent: 'US' },
        { name: 'Guam', code: 'US-GU', parent: 'US' },
        { name: 'Northern Mariana Islands', code: 'US-MP', parent: 'US' },
        { name: 'Puerto Rico', code: 'US-PR', parent: 'US' },
        { name: 'US Virgin Islands', code: 'US-VI', parent: 'US' },
        // { WE may one day only have this as an option. For now, we will roll with the library implmentation
        //   code: 'US-UM',
        //   name: 'United States Minor Outlying Islands',
        //   parent: 'US',
        // },
      ],
    };

    const armedForces = {
      name: 'Armed Forces',
      code: 'US-ARMED',
      parent: 'US',
      children: [
        {
          name: 'Armed Forces Americas',
          code: 'US-AA',
          parent: 'US',
        },
        { name: 'Armed Forces Europe', code: 'US-AE', parent: 'US' },
        { name: 'Armed Forces Pacific', code: 'US-AP', parent: 'US' },
      ],
    };

    const stateList = result.filter((state) => {
      const { code, name } = state;
      return (
        !usTerritories.children.some((c) => c.code === code) &&
        !armedForces.children.some((c) => c.code === code) &&
        name !== 'United States Minor Outlying Islands'
      );
    });

    result = [
      usTerritories,
      armedForces,
      {
        name: 'State List',
        code: 'US-STATE',
        parent: 'US',
        children: stateList,
      },
    ];
  }
  return result.sort((a, b) => a.name.localeCompare(b.name));
};

export const getChildSubdivisionMatchingCountryAndState = (
  country: string,
  codePrefix: string
): Subdivision[] | ['n/a'] => {
  const subdivisions = getCountrySubdivisions(country);

  if (subdivisions && subdivisions.length === 1 && subdivisions[0] === 'n/a')
    return subdivisions;

  for (const subdivision of subdivisions as SubdivisionWithChildren[]) {
    if (subdivision.children) {
      const matchingChildren = subdivision.children.filter((child) =>
        child.code.includes(`-${codePrefix}`)
      );
      if (matchingChildren.length > 0) {
        return matchingChildren;
      }
    }
  }

  return subdivisions;
};
