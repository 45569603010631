export default {
  footer: {
    backgroundColor: '#262626',
  },
  layoutContainer: {
    position: 'relative',
    top: '-30px',
  },
  footerText: {
    fontSize: { xs: '28px', sm: '50px' },
    fontFamily: 'futura-pt',
    textTransform: 'capitalize',
  },
  btnStyle: {
    fontFamily: 'Open Sans Condensed',
  },
  privacyContainer: {
    position: 'relative',
    top: { xs: '50px', sm: '57px' },
    right: { xs: '20px', sm: '200px' },
  },
  privacyText: {
    paddingBottom: '5px',
    borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
    color: '#fff',
    '&:hover': {
      cursor: 'pointer',
      opacity: '0.6',
    },
  },
  privacyAnchor: {
    textDecoration: 'none',
    color: '#fff',
    marginLeft: '20px',
  },
  disclaimer: {
    fontSize: { xs: '12px', sm: '14px' },
    marginTop: '30px',
  },
};
