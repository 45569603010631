import React, { forwardRef } from 'react';
import Info from '@mui/icons-material/Info';
import { SvgIconProps } from '@mui/material';

const InfoIcon = forwardRef<SVGSVGElement, SvgIconProps>(
  ({ sx, ...rest }, ref) => (
    <Info
      ref={ref}
      {...rest}
      sx={{
        color: 'primary.main',
        fontSize: '2em',
        cursor: 'pointer',
        ...sx,
      }}
    />
  )
);

export default InfoIcon;
