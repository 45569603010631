import React, { VFC } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type ModalProps = {
  show: boolean;
  title: string;
  declineText: string;
  submitText: string;
  modalText: string;
  onDecline: () => void;
  onSubmit: () => void;
};

const Modal: VFC<ModalProps> = function ({
  show,
  title,
  modalText,
  declineText,
  submitText,
  onDecline,
  onSubmit,
}) {
  return (
    <Dialog
      open={show}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      PaperProps={{ sx: { maxWidth: { xs: '300px', sm: '500px' } } }}
    >
      <DialogTitle
        id="dialog-title"
        className="headline text-center font-weight-black"
      >
        {title}
      </DialogTitle>
      <DialogContent sx={{ mb: 3 }}>
        <DialogContentText id="dialog-description">
          {modalText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDecline}>{declineText}</Button>
        <Button variant="contained" onClick={onSubmit} autoFocus>
          {submitText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
