// mocks
import { raasGeneralConfig } from 'mocks/products/raasNoClient';

// types
import type { ConfigFileTypes, ConfigStep } from 'types';

/* eslint-disable no-template-curly-in-string */

export const raasRechargeSubmissionMapping = {
  cardholder: {
    address_1: '${GLOBAL.formsReducer.forms.step1.creditCard_address}',
    city: '${GLOBAL.formsReducer.forms.step1.creditCard_city}',
    country: '${GLOBAL.formsReducer.forms.step1.creditCard_country}',
    name: '${GLOBAL.formsReducer.forms.step1.creditCard_name}',
    postal_code: '${GLOBAL.formsReducer.forms.step1.creditCard_zip_code}',
    state: '${GLOBAL.formsReducer.forms.step1.creditCard_state}',
  },
  payment: {
    cvv: '${GLOBAL.formsReducer.forms.step1.creditCard_cvc}',
    expire_month: '${GLOBAL.formsReducer.forms.step1.creditCard_month}',
    expire_year: '${GLOBAL.formsReducer.forms.step1.creditCard_year}',
    name: '${GLOBAL.formsReducer.forms.step1.creditCard_name}',
    number: '${GLOBAL.formsReducer.forms.step1.creditCard_card_number}',
    type: 'card',
  },
};

export const raasRechargeSteps: ConfigStep[] = [
  {
    name: 'Billing Information',
    backButtonText: '',
    fields: [
      {
        component: 'HeaderDescription',
        verbiages: {
          title: "Let's Get You Protected",
          footer: '',
          description:
            "We were unable to complete your transaction to protect your purchase on the selected order. Let's retry again. Please re-enter your billing information below.",
        },
      },
      {
        component: 'CreditCardPanel',
        verbiages: {
          description: 'Please complete all fields below.',
          reviewPageTitle: 'Billing Information',
        },
        props: {
          reviewPageGrid: {
            xs: 12,
            sm: 12,
            md: 12,
          },
          name: 'creditCard',
          defaultValue: {
            name: '',
            card_number: '',
            month: '',
            year: '',
            cvc: '',
          },
        },
      },
      {
        component: 'StepSubmitButton',
        verbiages: {
          text: 'Next',
          allCompleteText: 'Update',
        },
        props: {
          endpoint:
            '${GLOBAL.configFileReducer.claimsApi}/orders/${GLOBAL.ordersReducer.selectedOrder.uuid}/recharge',
          requestType: 'POST',
          submissionMapping: raasRechargeSubmissionMapping,
        },
      },
    ],
  },
  {
    name: 'ThankYouPage',
    backButtonText: '',
    fields: [
      {
        component: 'ThankYouPage',
        verbiages: {},
      },
    ],
  },
];

export const raasRechargeConfig: ConfigFileTypes = {
  ...raasGeneralConfig,
  steps: raasRechargeSteps,
};
/* eslint-enable no-template-curly-in-string */
