import React, { useCallback, useState, VFC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import objectIsEmpty from 'lodash/isEmpty';
import { generateTranslation } from 'utils/translation/i18nextTranslation';
import { ReviewType } from 'utils/translation/types';
import { Flow, FlowType } from 'store/modules/config/types';
import { selectClaimLoading } from 'store/modules/claims/selectors';
import {
  selectEmail,
  selectSelectedItems,
  selectSelectedOrder,
  selectSignatureLegalVerbiage,
} from 'store/modules/orders/selectors';
import * as ls from 'utils/localstorage';
import { clearState as claimsClearState } from 'store/modules/claims/slice';
import { clearState as ordersClearState } from 'store/modules/orders/slice';
import { clearState as perilsClearState } from 'store/modules/perils/slice';
import { clearState as customerClearState } from 'store/modules/customer/slice';
import { clearState as authClearState } from 'store/modules/auth/slice';
import { clearThanksState } from 'store/modules/thanks/slice';
import { clearAllForms } from 'store/modules/forms';
import {
  selectFlowType,
  selectNumberOfSteps,
  selectSteps,
  selectUrlProduct,
} from 'store/modules/config/selectors';
import { selectSelectedCustomer } from 'store/modules/customer/selectors';
import { createClaim } from 'store/modules/claims/thunks';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LoadingButton from 'components/LoadingButton';
import HeaderDescription from 'components/HeaderDescription';
import Layout from 'components/Layout';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import Link from '@mui/material/Link';
import { ParseContent } from 'utils/parser/StringToHtml';
import { stepName } from 'globalVariables';
import ReviewTable from './ReviewTable';
import Signature from '../Signature';
import ReviewPanel from './ReviewPanel';

const style = {
  signatureText: {
    margin: '0 auto',
    width: {
      xs: '300px',
      sm: '499px',
    },
  },
};

const ReviewPage: VFC = function () {
  const selectedItems = useAppSelector(selectSelectedItems);
  const selectedOrder = useAppSelector(selectSelectedOrder);
  const customer = useAppSelector(selectSelectedCustomer);
  const email = useAppSelector(selectEmail);
  const claimLoading = useAppSelector(selectClaimLoading);
  const routes = useAppSelector(selectSteps);
  const signatureLegalVerbiage = useAppSelector(selectSignatureLegalVerbiage);
  const type = useAppSelector(selectFlowType);
  const productInUrl = useAppSelector(selectUrlProduct);
  const lastStepIndex = useAppSelector(selectNumberOfSteps);
  const { pathname } = useLocation();
  const lastStepName = `/${productInUrl}/${type}/${stepName}${lastStepIndex}`;
  const dispatch = useAppDispatch();

  const [signature, setSignature] = useState('');

  const history = useHistory();
  const translation = generateTranslation('review') as ReviewType;

  const submitData = useCallback(() => {
    dispatch(createClaim({ signature }))
      .unwrap()
      .then(() =>
        pathname === lastStepName
          ? history.push(`/${productInUrl}/${type}/`)
          : history.push(lastStepName)
      );
  }, [
    dispatch,
    history,
    lastStepName,
    pathname,
    productInUrl,
    signature,
    type,
  ]);

  const resetClaim = useCallback(() => {
    dispatch(claimsClearState());
    dispatch(perilsClearState());
    dispatch(ordersClearState());
    dispatch(customerClearState());
    dispatch(clearThanksState());
    dispatch(clearAllForms());
    dispatch(authClearState());

    ls.clear().then((_) => history.push(`/${productInUrl}/${type}`));
  }, [type, history, dispatch, productInUrl]);

  const customerLoaded = useCallback(
    () => !objectIsEmpty(customer),
    [customer]
  );

  const fullName = useCallback(
    () =>
      customerLoaded()
        ? `${customer?.first_name} ${customer?.last_name}`
        : email,
    [customer?.first_name, customer?.last_name, customerLoaded, email]
  );

  if (!selectedOrder) return null;

  return (
    <Stack>
      <Layout sx={{ mb: 3 }}>
        <HeaderDescription
          title={translation.header.title}
          description={translation.header.description}
        />
      </Layout>
      <Box mb={2}>
        <ReviewTable
          {...((type === Flow.recharge ||
            type === Flow.billing ||
            type === Flow.cancellation) && {
            displayPremiumTotal: true,
          })}
          selectedItems={selectedItems}
          selectedOrder={selectedOrder!}
        />
      </Box>
      {routes.map((step, index) => {
        const stepId = index + 1;

        return (
          <Box mb={3} key={`reviewPanel${index}`}>
            <ReviewPanel layout={step?.fields} stepId={stepId} />
          </Box>
        );
      })}
      <Footer
        translation={translation}
        signatureLegalVerbiage={signatureLegalVerbiage}
        claimLoading={claimLoading}
        signature={signature}
        setSignature={setSignature}
        fullName={fullName}
        submitData={submitData}
        resetClaim={resetClaim}
        type={type}
      />
    </Stack>
  );
};

export default ReviewPage;

type FooterProps = {
  translation: ReviewType;
  signatureLegalVerbiage: string;
  claimLoading: boolean;
  signature: string;
  type: FlowType;
  fullName: () => string;
  setSignature: React.Dispatch<React.SetStateAction<string>>;
  submitData: () => void;
  resetClaim: () => void;
};

const Footer: VFC<FooterProps> = function ({
  translation,
  signature,
  signatureLegalVerbiage,
  claimLoading,
  type,
  fullName,
  setSignature,
  submitData,
  resetClaim,
}) {
  switch (type) {
    case Flow.cancellation:
      return (
        <>
          <Typography p={5}> {translation.sign.note} </Typography>
          <Box
            sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            <Link
              component="button"
              variant="body2"
              onClick={submitData}
              underline="none"
              sx={{
                display: 'flex',
                height: '44px',
                textTransform: 'capitalize',
                alignItems: 'center',
                marginRight: '30px',
                alignSelf: 'center',
              }}
            >
              {translation.sign.button}
            </Link>
            <LoadingButton
              variant="contained"
              loading={claimLoading}
              onClick={resetClaim}
              sx={{
                display: 'flex',
                height: '44px',
                textTransform: 'capitalize',
              }}
            >
              {translation.sign.cancel}
            </LoadingButton>
          </Box>
        </>
      );

    case Flow.recharge:
      return (
        <>
          <Typography mb={3} className="text-center">
            <ParseContent content={signatureLegalVerbiage} />
          </Typography>
          <Box className="text-center">
            <LoadingButton
              variant="contained"
              loading={claimLoading}
              onClick={submitData}
              className="btn-style"
            >
              {translation.sign.button}
            </LoadingButton>
          </Box>
        </>
      );

    case Flow.billing:
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <LoadingButton
            variant="contained"
            onClick={submitData}
            loading={claimLoading}
            sx={{
              display: 'flex',
              height: '44px',
              textTransform: 'capitalize',
            }}
          >
            {translation.sign.button}
          </LoadingButton>
        </Box>
      );
    default:
      return (
        <>
          <Stack mb={3} alignItems="center">
            <Typography sx={style.signatureText}>
              {translation.sign.title}
            </Typography>
            <Signature name={fullName()} updateSignature={setSignature} />
            <Typography
              sx={{ ...style.signatureText, mt: 4 }}
              className="text-center"
            >
              <ParseContent
                content={`${signatureLegalVerbiage} If your refund request is approved, your ticket or registration no longer belongs to you and any attempt to use the ticket or registration may lead to prosecution under your state's fraud laws.`}
              />
            </Typography>
          </Stack>
          <Box className="text-center">
            <LoadingButton
              variant="contained"
              loading={claimLoading}
              disabled={!signature}
              onClick={submitData}
              className="btn-style"
            >
              {translation.sign.button}
            </LoadingButton>
          </Box>
        </>
      );
  }
};
