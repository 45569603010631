import React, { VFC } from 'react';
import Layout from 'components/Layout';
import Panel from 'components/Panel';
import UploadFiles from 'components/UploadFiles';
import { Controller, UseFormReturn } from 'react-hook-form';
import { UploadedFileFormat } from 'components/UploadFiles/types';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';

export const FileUploadPanel: VFC<{
  description?: string;
  formApi: UseFormReturn<any, any>;
  name: string;
  document_type: string;
  contactInfoText: string;
  acceptance: string;
  footer: string;
  setIsSubmitting: (value: boolean) => void;
  storedValue: any;
}> = function ({
  description,
  contactInfoText,
  name,
  document_type,
  formApi: { control },
  acceptance,
  footer = '',
  setIsSubmitting,
  storedValue,
}) {
  return (
    <Panel>
      <Layout>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <>
              <UploadFiles
                description={description || ''}
                acceptance={acceptance}
                footer={footer}
                uploads={value}
                fieldName={name}
                removeFile={(fileIdToRemove: UploadedFileFormat) => {
                  const filteredFiles = value.filter(
                    (file: UploadedFileFormat) => file.id !== fileIdToRemove.id
                  );
                  onChange(filteredFiles);
                }}
                setUploadedFile={(uploadedValue) =>
                  onChange([...value, uploadedValue])
                }
                setIsSubmitting={setIsSubmitting}
                params={{
                  document_type,
                }}
                storedValue={storedValue}
              />
              {error?.message && (
                <FormHelperText error className="text-center">
                  {error?.message}
                </FormHelperText>
              )}
            </>
          )}
        />
        {contactInfoText && <Typography>{contactInfoText}</Typography>}
      </Layout>
    </Panel>
  );
};
