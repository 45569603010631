import React from 'react';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { StepIconProps } from '@mui/material/StepIcon';
import Check from '@mui/icons-material/Check';

export const StepperConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 32,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.primary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey[300],
    borderTopWidth: 5,
  },
}));

const AppStepperIconRoot = styled('div')<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  zIndex: 1,
  color: theme.palette.grey[300],
  width: 35,
  height: 35,
  fontWeight: 'bold',
  display: 'flex',
  borderRadius: '50%',
  border: '3px solid',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    borderColor: theme.palette.primary.main,
  }),
  ...(ownerState.completed && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: `0 0 10px 0 ${theme.palette.primary.main}`,
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  }),
}));

export const AppStepperIcon = function (props: StepIconProps) {
  const { active, completed, className, icon } = props;
  return (
    <AppStepperIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {completed ? (
        <Check className="AppStepperIcon-completed" />
      ) : (
        <div className="AppStepperIcon-icon">{icon}</div>
      )}
    </AppStepperIconRoot>
  );
};
