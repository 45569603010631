import { ProgressBarStepProps } from 'components/ProgressBar';
import { FlowType } from 'store/modules/config/types';
import { ConfigStep, ProductInUrlType } from 'types';

export const generateProgressBarSteps = (
  routes: ConfigStep[],
  stepName: string,
  type: FlowType,
  product: ProductInUrlType
): ProgressBarStepProps[] =>
  routes.reduce(
    (prev: ProgressBarStepProps[], current: ConfigStep, index: number) => {
      if (index === 0) {
        return [
          {
            text: current.name,
            stateName: `/${product}/${type}/${stepName}${index + 1}`,
          },
        ];
      }
      return [
        ...prev,
        {
          to: prev[index - 1].stateName,
          backText: current?.backButtonText || prev[index - 1].text,
          text: current.name,
          stateName: `/${product}/${type}/${stepName}${index + 1}`,
        },
      ];
    },
    []
  );
