// react
import React, { VFC } from 'react';

// layouts
import V1Layout from 'layouts/V1';

// components
import Content from 'components/FilePage/Content';

const FilePage: VFC = function () {
  return (
    <V1Layout>
      <Content />
    </V1Layout>
  );
};

export default FilePage;
