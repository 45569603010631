export default {
  appComponent: {
    title: 'Continue With Your Unfinished Claim?',
    appTitle: 'Claims Form',
    footer: {
      title: 'Need to Start Your Claim',
      disclaimer: '',
    },
  },
  filePage: {
    title: 'File a Claim',
  },
  orderSelectionPage: {
    consentText:
      'By checking this box you acknowledge that you will be asked to upload documentation.',
    button: 'Start Claim',
    noOrders: 'No policies found.',
    rules: {
      row1: '1) You may select one or more item(s) for each claim.',
      row2: '2) You cannot select item(s) from separate shipments.',
    },
    header: {
      row2: "Please select the items(s) for which you'd like to start a claim.",
    },
  },
  emailSent: {
    description:
      "Click the link, and you'll be signed in. This is a one-time use link and expires in 4 hours.",
  },
  reviewPage: {
    header: {
      description:
        'Below, please review the information you provided for accuracy. Providing detailed information assists us in expediting your claim.  Once you confirm all is correct, please enter your name in the "Sign Here" box and then click "Submit Claim".',
    },
    sign: {
      button: 'Submit Claim',
      cancel: '',
      note: '',
      claims: {
        text: "By clicking 'Submit Claim' you certify that all information on this claim is accurate and truthful under Federal Law and under penalty of perjury. ",
        floridaText:
          'For Florida Residents: Any person who knowingly and with intent to injure, defraud, or deceive any insurer files a statement of claim or an application containing any false, incomplete, or misleading information is guilty of a felony (of the third degree). ',
        footer:
          "FanShield, LLC is a licensed insurance agency NPM: 18828928 wholly owned by Protecht, Inc. Fanshield, RegShield, and TourShield are marketing product names for the ticket event insurance policy underwritten by Markel Insurance Company. Terms, Conditions, and Coverage may vary by State, Event, and other factors. See Application and Policy for full details. You can view Protecht, inc's full Privacy Policy ",
        link: {
          text: {
            row2: 'updates concerning your policy.',
          },
        },
      },
    },
    reviewTable: {
      total: 'Total claim amount: ',
      title: 'Description',
      covered: 'Covered Amount',
    },
  },
  addressForm: {
    labels: {
      city: 'City/Town',
      state: 'Province/Region',
      zip_code: 'Postal Code',
    },
  },
  thankYouPage: {
    header: {
      title: {
        submitted: 'Thank You for filling your claim.',
        notSubmitted: 'Thank You for filling your claim.',
      },
      description: {
        row2: 'claim',
      },
    },
    description:
      'Please retain your claim number above for future reference.  You will receive a follow-up email within the next 24 hours. If you have additional questions after receiving that email, please ',
  },
};
