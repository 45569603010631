import { ConfigFileTypes, ProductInUrlType } from 'types';

export type ConfigFileState = {
  config: ConfigFileTypes;
  productInUrl: ProductInUrlType | undefined;
  productId: string;
  status: 'error' | 'success' | 'loading';
  flow: FlowType;
  startOver: boolean;
  claimsApi: string;
};

export type GetConfigResponse = {
  configuration: ConfigFileTypes;
  client: string;
  product: string; // Product id
  type: FlowType;
};

export type FlowType = keyof typeof Flow;

export enum Flow {
  'billing' = 'billing',
  'cancellation' = 'cancellation',
  'certificate' = 'certificate',
  'recharge' = 'recharge',
  'refund' = 'refund',
}
