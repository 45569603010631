// react
import React, { ElementType, useEffect, useRef, useState, VFC } from 'react';

// packages
import { Controller, UseFormReturn } from 'react-hook-form';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Fade from '@mui/material/Fade';
import Slide from '@mui/material/Slide';
import Typography from '@mui/material/Typography';

// redux
import { useAppSelector } from 'store/hooks';
import { selectSelectedItems } from 'store/modules/orders/selectors';

// components
import GenericFormField from 'components/FormBuilder/components/GenericFormField';
import Layout from 'components/Layout';
import Panel from 'components/Panel';

// utils
import { ParseContent } from 'utils/parser/StringToHtml';
import { kebabCase } from 'utils/string';

// types
import type { ComponentStructure, DatePanelConfigProps } from 'types';

export const DecisionPanel: VFC<{
  storedValue: boolean | null;
  description?: string;
  childrenComponents: ComponentStructure[];
  displayChildrenOnYes: boolean;
  displayCourtesyMessageOnYes?: boolean;
  courtesyMessage?: string;
  formApi: UseFormReturn<any, any>;
  name: string;
  setIsSubmitting: (value: boolean) => void;
  StepSubmitButton: ElementType;
  reject: string;
  confirm: string;
}> = function ({
  storedValue = null,
  description,
  childrenComponents,
  displayChildrenOnYes,
  displayCourtesyMessageOnYes = true,
  courtesyMessage,
  formApi,
  name,
  setIsSubmitting,
  StepSubmitButton,
  confirm,
  reject,
}) {
  // redux state
  const selectedItems = useAppSelector(selectSelectedItems);

  // state
  const [isYesSelected, setIsYesSelected] = useState<boolean | null>(
    storedValue
  );

  // ref
  const cancelledContainerRef = useRef(null);

  // constants
  const policy =
    selectedItems && selectedItems.length > 0 && selectedItems[0]?.policy
      ? selectedItems[0].policy
      : null;

  const firstChildComponent: ComponentStructure | null =
    childrenComponents.length ? childrenComponents[0] : null;

  const shouldNotShowPanel =
    childrenComponents.length &&
    firstChildComponent?.component === 'DatePanel' &&
    (firstChildComponent.props as DatePanelConfigProps).displayTime &&
    !policy?.is_event_interruption &&
    !policy?.is_weather_interruption;

  // if this is the attendance type date panel, but there are no event or weather interruptions
  // associated with the primary policy for the item, then we need to exclude the required decision field
  useEffect(() => {
    if (!policy || !shouldNotShowPanel) return;

    formApi.setValue('attendance_decision', false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policy, shouldNotShowPanel]);

  // if this is the attendance type date panel, but there are no event or weather interruptions
  // associated with the primary policy for the item, then we don't need to show this panel
  if (
    childrenComponents.length &&
    firstChildComponent?.component === 'DatePanel' &&
    (firstChildComponent.props as DatePanelConfigProps).displayTime &&
    !policy?.is_event_interruption &&
    !policy?.is_weather_interruption
  )
    return null;

  return (
    <>
      <Box mb={2}>
        <Panel>
          <Layout>
            {description && <Typography>{description}</Typography>}
            <CardActions sx={{ justifyContent: 'center' }}>
              <Controller
                name={name}
                control={formApi.control}
                render={({
                  field: { onChange, ...rest },
                  fieldState: { invalid },
                }) => (
                  <Box>
                    <Box sx={{ alignContent: 'center', display: 'flex' }}>
                      <Button
                        {...rest}
                        data-testid={`${kebabCase(name)}-reject`}
                        onClick={() => {
                          setIsYesSelected(false);
                          onChange(false);
                        }}
                        variant={
                          isYesSelected === true || isYesSelected === null
                            ? 'text'
                            : 'contained'
                        }
                      >
                        {reject}
                      </Button>
                      <Button
                        {...rest}
                        data-testid={`${kebabCase(name)}-confirm`}
                        color="primary"
                        onClick={() => {
                          setIsYesSelected(true);
                          onChange(true);
                        }}
                        variant={
                          isYesSelected === false || isYesSelected === null
                            ? 'text'
                            : 'contained'
                        }
                      >
                        {confirm}
                      </Button>
                    </Box>
                    {invalid && (
                      <Typography
                        sx={{ color: '#ff5252', fontSize: '12px', mt: 2 }}
                      >
                        This selection is required.
                      </Typography>
                    )}
                  </Box>
                )}
              />
            </CardActions>
            <div ref={cancelledContainerRef} style={{ overflow: 'hidden' }}>
              <Slide
                direction="down"
                in={
                  Boolean(isYesSelected) &&
                  Boolean(courtesyMessage) &&
                  displayCourtesyMessageOnYes
                }
                container={cancelledContainerRef.current}
                mountOnEnter
                unmountOnExit
              >
                <CardContent
                  sx={{
                    '& a': {
                      color: 'primary.main',
                      textDecorationColor: (theme) =>
                        theme.palette.primary.main,
                    },
                  }}
                >
                  {courtesyMessage && (
                    <ParseContent content={courtesyMessage} />
                  )}
                </CardContent>
              </Slide>
            </div>
          </Layout>
        </Panel>
      </Box>
      <Fade
        in={displayChildrenOnYes ? Boolean(isYesSelected) : !isYesSelected}
        unmountOnExit
      >
        <div>
          {childrenComponents.map((elem, index) => (
            <Box mb={2} key={`component-${index}`}>
              <GenericFormField
                type={elem.component}
                formApi={formApi}
                props={elem.props}
                verbiages={elem.verbiages}
                setIsSubmitting={setIsSubmitting}
                StepSubmitButton={StepSubmitButton}
              />
            </Box>
          ))}
        </div>
      </Fade>
    </>
  );
};
