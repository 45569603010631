import React, { VFC, useState, useEffect } from 'react';
import { generateTranslation } from 'utils/translation/i18nextTranslation';
import { ItemTableWrapperType } from 'utils/translation/types';
import Typography from '@mui/material/Typography';
import { FormattedItem, FormattedOrder } from 'store/modules/orders/types';
import { dateSlashes } from 'utils/date';
import Card from 'components/Card';
import Box from '@mui/material/Box';
import { filterAcceptableItems } from 'utils/helpers';
import { ItemTableWrapperProps } from '../types';
import ItemTable from './ItemTable';

const ItemTableWrapper: VFC<ItemTableWrapperProps> = function ({
  isDisabled,
  isExpired,
  order,
  selected,
  updateSelected,
  items,
}) {
  const [showNonSelectableError, setShowNonSelectableError] = useState(false);
  const [isTableLocked, setIsTableLocked] = useState(false);
  const [attemptSelection, setAttemptSelection] = useState(false);
  const [fireSelectionUpdate, setFireSelectionUpdate] = useState<{
    trigger: boolean;
    items: FormattedItem[];
    order: FormattedOrder;
  }>();

  const acceptableItems = items.filter(filterAcceptableItems); // TODO Andrea voidable statuses are ['Accepted', 'Issued', 'OnHold', 'OFAC Hold', 'No Underwriter', 'Insufficient Address', 'Split']

  useEffect(() => {
    if (isDisabled || acceptableItems.length === 0) {
      setIsTableLocked(true);
      setAttemptSelection(false);
    } else {
      setIsTableLocked(false);
    }
    setShowNonSelectableError(false);
  }, [isDisabled, acceptableItems.length]);

  useEffect(() => {
    if (isTableLocked) {
      if (attemptSelection) {
        setShowNonSelectableError(true);
      } else {
        setShowNonSelectableError(false);
      }
    }
  }, [attemptSelection, isTableLocked]);

  useEffect(() => {
    if (fireSelectionUpdate?.trigger) {
      if (!isTableLocked) {
        updateSelected(fireSelectionUpdate?.items, fireSelectionUpdate.order);
      }
      setFireSelectionUpdate(undefined);
    }
  }, [fireSelectionUpdate, isTableLocked, updateSelected, attemptSelection]);

  const handleSelectionUpdate = (
    items: FormattedItem[],
    order: FormattedOrder
  ) => {
    setFireSelectionUpdate({
      trigger: true,
      items,
      order,
    });
  };

  const handleAttemptToSelect = () => {
    setAttemptSelection(true);
  };

  const translation = generateTranslation(
    'itemTableWrapper'
  ) as ItemTableWrapperType;

  return (
    <>
      {isDisabled && showNonSelectableError && (
        <Typography className="text-xs-center red--text">
          {translation.error}
        </Typography>
      )}

      <Card
        sx={{
          ...(isTableLocked && {
            opacity: '0.4',
            cursor: 'not-allowed',
          }),
        }}
        headerComponent={
          <Box
            sx={{
              textAlign: 'left',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              sx={{ fontSize: 16, color: 'white', fontWeight: 'bold' }}
            >
              {`${order.client_name}`}
            </Typography>
            <Typography
              sx={{ fontSize: 16, color: 'white', fontWeight: 'bold' }}
            >
              {isExpired
                ? 'Expired'
                : order?.items[0]?.look_period &&
                  `Exp. Date: ${dateSlashes({
                    item: order.items[0].look_period,
                  })}`}
            </Typography>
          </Box>
        }
      >
        <ItemTable
          order={order}
          items={items}
          selected={selected}
          handleSelectionUpdate={handleSelectionUpdate}
          handleAttemptToSelect={handleAttemptToSelect}
        />
      </Card>
    </>
  );
};

export default ItemTableWrapper;
