import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AnyAction, ThunkDispatch, Dispatch } from '@reduxjs/toolkit';
import { store } from 'store';
import { ComponentStructure, ConfigStep, VerbiageTypes } from 'types';
import {
  containsTranslationOverride,
  takeTranslationFromObject,
} from 'utils/translation/merge';
import { isObject } from 'utils/object';
import {
  containsHookFormStateTags,
  containsReduxStateTags,
  formatProperty,
} from './utils/hooks';
import type { RootState, AppDispatch } from '.';

export type AppDispatchedAction = ThunkDispatch<
  RootState,
  undefined,
  AnyAction
> &
  Dispatch<AnyAction>;

export type AppTask<T extends unknown[]> = (...args: T) => AppDispatchedAction;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useAppDispatch: () => AppDispatch = useDispatch;

export const resolvePropFromConfigToReduxState = (prop: string | undefined) => {
  const state = store.getState();
  if (containsReduxStateTags(prop)) {
    const start = prop!.split('${GLOBAL.');
    const appendResolvedString = (acc: string, curr: string, index: number) => {
      if (index > 0) return formatProperty(state, acc, curr);

      return curr;
    };
    return start.reduce(appendResolvedString, '');
  }
  return prop;
};

export const resolveObjectFromConfigToReduxState = (obj: any) => {
  const resolved = { ...obj };
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      resolved[key] = resolvePropFromConfigToReduxState(obj[key]);
    }
  }
  return resolved;
};

export const resolveObjectsFromConfigToReduxState = (obj: any) => {
  const resolved = { ...obj };
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const val = obj[key];
      if (isObject(val))
        resolved[key] = resolveObjectsFromConfigToReduxState(val);
      else resolved[key] = resolvePropFromConfigToReduxState(val);
    }
  }
  return resolved;
};

export const resolveFormComponentVerbiages = (
  verbiage: any,
  state: { [x: string]: any }
) => {
  const formatWithComponentName = (
    acc: { [x: string]: VerbiageTypes },
    curr: ComponentStructure
  ) => {
    acc[curr.component] = curr.verbiages;
    return acc;
  };

  const divideForStep = (
    acc: { [x: string]: any },
    curr: ConfigStep,
    index: number
  ) => {
    acc[`step${index + 1}`] = curr.fields.reduce(formatWithComponentName, {});
    return acc;
  };

  const resolved = { ...verbiage };
  const globalState = store.getState();
  const translation = globalState.configFileReducer.config.steps.reduce(
    divideForStep,
    {}
  );

  for (const key in verbiage) {
    if (Object.prototype.hasOwnProperty.call(verbiage, key)) {
      const element = verbiage[key];

      if (isObject(element))
        resolved[key] = resolveFormComponentVerbiages(element, state);
      else if (containsTranslationOverride(element))
        resolved[key] = takeTranslationFromObject(element, translation);
      else
        resolved[key] = resolvePropFromConfigToHookFormState(
          resolvePropFromConfigToReduxState(element),
          state
        );
    }
  }

  return resolved;
};

const resolvePropFromConfigToHookFormState = (
  verbiage: string | undefined,
  state: { [x: string]: any }
) => {
  if (containsHookFormStateTags(verbiage)) {
    const start = verbiage!.split('${LOCAL.');
    const appendResolvedString = (acc: string, curr: string, index: number) => {
      if (index > 0) return formatProperty(state, acc, curr);

      return curr;
    };
    return start.reduce(appendResolvedString, '');
  }
  return verbiage;
};
