// packages
import { isSameDay } from 'date-fns';
import { DateTime } from 'luxon';

// utils
import { isEmptyObject } from 'utils/object';

// type guard
const isDate = (item: Date | unknown): item is Date => {
  // if the item can get a time that's valid (not-NaN), it's a date type
  if (!Number.isNaN((item as any)?.getTime())) return true;

  return false;
};

export const dateFilterLabel = (
  startDate: Date | null,
  endDate: Date | null
) => {
  if (!endDate && !startDate) return '';

  if (!startDate) return formatFilterDate(endDate);

  if (!endDate) return formatFilterDate(startDate);

  if (!isSameDay(startDate, endDate))
    return `${formatFilterDate(startDate)} - ${formatFilterDate(endDate)}`;

  return formatFilterDate(startDate);
};

export const dateSlashes = ({
  item,
  placeholder = '',
}: {
  item: unknown;
  placeholder?: string;
}) => {
  try {
    if (!item || isEmptyObject(item)) return placeholder;

    const date = new Date(item as string | number | Date);

    // if the date is invalid, return an empty string instead of "Invalid Date"
    if (!isDate(date)) return placeholder;

    const extractedDateTime = extractLuxonDateTime(date);

    // if the date time is invalid, return an empty string instead of "Invalid DateTime"
    if (!DateTime.isDateTime(extractedDateTime)) return placeholder;

    return extractedDateTime.toFormat('MM/dd/yyyy');
  } catch (error) {
    return placeholder;
  }
};

export const diffInDaysToNow = (prevDate: string) =>
  DateTime.now()
    .diff(extractLuxonDateTime(new Date(prevDate)), 'days')
    .toObject().days;

const extractLuxonDateTime = (date: Date) =>
  DateTime.fromJSDate(date, { zone: 'utc' });

const formatFilterDate = (date?: Date | null) => {
  if (!date) return '';

  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export const formatDateToTwentyFourHourTime = (dateString: string) =>
  DateTime.fromFormat(dateString, 'yyyy-MM-dd t').toFormat('yyyy-MM-dd HH:mm');

export const fromFormatDate = (
  date: string,
  preserveTime: boolean | undefined = false
) => {
  if (!date || DateTime.isDateTime(date)) return date;

  return DateTime.fromFormat(
    date,
    preserveTime ? 'yyyy-MM-dd t' : 'yyyy-MM-dd'
  );
};

export const formatBrowserCompatibleDate = (dateString: string) =>
  dateString.replace(/-/g, '/');

export const getDaysInTheFuture = (days: number) =>
  DateTime.utc().toLocal().plus({ days });

export const setDate = (dateString?: string | null) => {
  if (!dateString) return;

  const splitDate = dateString.split('T')[0];

  if (!splitDate) return new Date(dateString);

  return new Date(`${splitDate}T00:00:00`);
};

export const today = () => DateTime.utc().toLocal().toFormat('MMM. dd yyyy');
