import { ProductType } from 'types';
import { FlowType } from 'store/modules/config/types';
import translations from 'translations';

export const loadProductTranslations = function (
  product: ProductType,
  type: FlowType
) {
  if (translations[type][product]) {
    return translations[type][product];
  }

  throw new Error('Unsupported product translation');
};
