import React, { VFC } from 'react';
import Typography from '@mui/material/Typography';
import Layout from 'components/Layout';
import Panel from 'components/Panel';
import { UseFormReturn } from 'react-hook-form';
import { UpdateBillingAddressPanelType } from 'utils/translation/types';
import AddressForm from './AddressForm';

export const UpdateBillingAddressPanel: VFC<{
  name: string;
  translation: UpdateBillingAddressPanelType;
  formApi: UseFormReturn<any, any>;
}> = function ({
  translation,
  name,
  formApi: { resetField, setValue, control, watch },
}) {
  return (
    <Panel>
      <Layout>
        <Typography mb={2} alignSelf="flex-start" fontWeight="bold">
          {translation.title}
        </Typography>
        <Typography mb={2} alignSelf="flex-start">
          {translation.footer}
        </Typography>
        <AddressForm
          control={control}
          name={name}
          watch={watch}
          resetField={resetField}
          setValue={setValue}
        />
      </Layout>
    </Panel>
  );
};
