import { createAsyncThunk } from '@reduxjs/toolkit';
import api from './api';
import { Feedback } from './types';

export const createFeedback = createAsyncThunk('feedback/create', async (params: Feedback, thunkApi) => {
  try {
    const response = await api.createFeedback(params);
    return response.data;
  } catch (e) {
    return thunkApi.rejectWithValue('Error creating feedback');
  }
});