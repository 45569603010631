// react
import React, { useEffect, useState } from 'react';

// packages
import { Controller } from 'react-hook-form';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';

// redux
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  selectReasons,
  selectReasonsError,
  selectReasonsLoading,
} from 'store/modules/reasons/selectors';
import { getReasons } from 'store/modules/reasons/thunks';

// components
import CircularProgress from 'components/CircularProgress';
import Layout from 'components/Layout';
import Panel from 'components/Panel';

// utils
import { initMultiselect } from 'utils/radioGroup';

// types
import type { FC } from 'react';
import type { MultiSelectOptionType } from 'utils/translation/types';
import type { UseFormReturn } from 'react-hook-form';

export const RadioGroupPanel: FC<{
  formApi: UseFormReturn<any, any>;
  label: string;
  name: string;
  optionsEndpoint?: string;
}> = function ({ formApi: { control }, label, name, optionsEndpoint }) {
  // redux
  const dispatch = useAppDispatch();

  // state
  const [showTextInput, setShowTextInput] = useState(false);

  // selected state
  const reasons = useAppSelector(selectReasons);
  const reasonsError = useAppSelector(selectReasonsError);
  const reasonsLoading = useAppSelector(selectReasonsLoading);

  useEffect(() => {
    if (!optionsEndpoint) return;

    // get the reasons if the option items are dynamically populated
    if (optionsEndpoint === 'policies/actions-reasons') dispatch(getReasons());
  }, [dispatch, optionsEndpoint]);

  useEffect(() => {
    initMultiselect(reasons);
  }, [reasons]);

  // set loading spinner if options endpoint is present and reasons state is loading
  if (optionsEndpoint === 'policies/actions-reasons' && reasonsLoading)
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </div>
    );

  return (
    <Panel>
      <Layout>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl>
              {/* if there are options to display, show them  */}
              {!reasonsLoading && !reasonsError && reasons.length && (
                <RadioGroup
                  name={name}
                  onChange={onChange}
                  sx={{ marginTop: 4 }}
                  value={value}
                >
                  {reasons.map((item, i) => (
                    <RadioGroupItem
                      key={i}
                      item={item}
                      setShowTextInput={setShowTextInput}
                    />
                  ))}
                </RadioGroup>
              )}
              {/* if there are no options to display (due to error on endpont or no items in the response), fall back to a free-form text input */}
              {(reasonsError || !reasons.length || showTextInput) && (
                <Controller
                  name={`${name}_description`}
                  control={control}
                  render={({ field: { onChange, value, ref, ...rest } }) => (
                    <TextField
                      sx={{ marginTop: 3 }}
                      variant="filled"
                      inputRef={ref}
                      {...rest}
                      onChange={onChange}
                      value={value ?? ''}
                      label={label}
                    />
                  )}
                />
              )}
              <FormHelperText error={Boolean(error?.message)}>
                {error?.message ?? ' '}
              </FormHelperText>
            </FormControl>
          )}
        />
      </Layout>
    </Panel>
  );
};

const RadioGroupItem: FC<{
  item: MultiSelectOptionType;
  setShowTextInput: any;
}> = function ({ item, setShowTextInput }) {
  return (
    <Box sx={{ textAlign: 'left' }}>
      <FormControlLabel
        control={<Radio />}
        key={item.key}
        label={item.value}
        onChange={() => {
          if (item.hasComment) return setShowTextInput(true);

          setShowTextInput(false);
        }}
        sx={{
          '& .MuiFormControlLabel-label': {
            fontSize: { xs: '0.7rem', md: '1rem' },
          },
        }}
        value={item.key}
      />
    </Box>
  );
};
