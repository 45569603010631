import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import EditIcon from '@mui/icons-material/Edit';

const styles = {
  panel: {
    backgroundColor: 'rgba(250, 250, 250, 0.902)',
    minHeight: '70px',
  },
  editContainer: {
    position: 'relative',
    top: '-10px',
  },
};

const EditPanel: FC<{ to: string }> = function ({ to, children }) {
  return (
    <Card sx={{ ...styles.panel, p: 3 }}>
      <Box className="text-right" sx={styles.editContainer}>
        <Chip
          label="Edit"
          size="small"
          clickable
          icon={<EditIcon sx={{ color: 'text.primary' }} fontSize="small" />}
          component={RouterLink}
          to={to}
          sx={{
            '& .MuiChip-icon': {
              color: 'text.primary',
            },
          }}
        />
      </Box>
      <Box>{children}</Box>
    </Card>
  );
};

export default EditPanel;
