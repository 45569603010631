import React, { VFC } from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { Peril as PerilType } from 'store/modules/perils/types';
import InfoIcon from 'components/InfoIcon';
import PerilIcon from 'components/PerilIcon';
import { useAppSelector } from 'store/hooks';
import { getForm } from 'store/modules/forms';
import { selectSelectedItems } from 'store/modules/orders/selectors';
import styles from './styles';

const PerilSelection: VFC<{
  perils: PerilType[];
  selectedPeril: PerilType;
  updateSelection: (peril: PerilType) => void;
}> = function ({ perils, selectedPeril, updateSelection }) {
  // redux state
  const selectedItems = useAppSelector(selectSelectedItems);
  const stepForm = useAppSelector((state) => getForm(state, 'step1'));

  // constants
  const activePeril = (id: string) => id === selectedPeril.id;
  const filterByEventInterruption =
    stepForm?.attendance_arrival_date || stepForm?.attendance_departure_date;
  const policy =
    selectedItems && selectedItems.length > 0 && selectedItems[0]?.policy
      ? selectedItems[0].policy
      : null;
  const filteredPerils =
    policy?.is_event_interruption || policy?.is_weather_interruption
      ? perils.filter((peril) =>
          filterByEventInterruption
            ? peril.type !== 'standard'
            : peril.type === 'standard'
        )
      : perils;

  return (
    <Grid container spacing={2} p={{ sm: 2, xs: 3 }} justifyContent="center">
      {/* filter perils by primary policy type and user selection */}
      {filteredPerils.map(
        (peril, index) =>
          peril.legal_description && (
            <Grid item xs={6} sm={4} md={4} key={index}>
              <Card
                sx={styles.card}
                onClick={() => updateSelection(peril)}
                className={activePeril(peril.id) ? 'active' : ''}
              >
                {peril.legal_description && (
                  <Tooltip
                    disableFocusListener
                    enterTouchDelay={10}
                    title={peril.legal_description}
                  >
                    <InfoIcon
                      sx={{
                        ...styles.icon,
                      }}
                    />
                  </Tooltip>
                )}
                <Stack
                  justifyContent="flex-end"
                  alignItems="center"
                  flex={1}
                  p={{
                    sm: 1,
                  }}
                >
                  <Box sx={{ ...styles.iconContainer, mt: 3 }}>
                    <PerilIcon titleAccess={peril.name} icon={peril.icon} />
                  </Box>
                  <Box sx={styles.descriptionContainer}>
                    <Typography
                      className="text-center"
                      sx={{
                        ...styles.descriptionText,
                        px: 1,
                        mb: 3,
                        fontSize: {
                          xs: '14px',
                          sm: 'inherit',
                        },
                        top: {
                          xs: '15px',
                          sm: '10px',
                        },
                      }}
                    >
                      {peril.description}
                    </Typography>
                  </Box>
                </Stack>
              </Card>
            </Grid>
          )
      )}
    </Grid>
  );
};

export default PerilSelection;
