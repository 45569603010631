export default {
  container: {
    flex: '1 1 100%',
    margin: 'auto',
    maxWidth: '100%',
  },
  headerContainer: {
    minHeight: '184px !important',
  },
  imageContainer: {
    width: '550px',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    backgroundPositionY: 'center',
    backgroundPositionX: 'center',
    zIndex: -2,
  },
  navbarContainer: {
    paddingTop: '50px',
  },
  mobileNavbarContainer: {
    paddingTop: '50px',
  },
  logo: {
    margin: 'auto',
    width: '100%',
    padding: '20px',
  },
  mobileLogo: {
    margin: 'auto',
    padding: '5px',
    width: '90%',
  },
  raasLogo: {
    margin: 'auto',
    width: '50%',
    padding: '5px'
  },
  raasMobileLogo: {
    margin: 'auto',
    width: '70%',
    padding: '10px'
  },
  listContainer: {
    justifyContent: 'flex-end',
    display: 'flex',
    padding: '5px',
  },
  listItems: {
    listStyle: 'none',
    display: 'inline-block',
    fontFamily: 'Open Sans Condensed',
    margin: '0px 8.652px 0px 8.652px',
    fontSize: '13px',
    letterSpacing: '0.5px',
  },
  mobileListItems: {
    fontFamily: 'futura-pt',
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: '20px',
    letterSpacing: '0.2em',
    lineHeight: '20px',
    textTransform: 'uppercase',
    color: '#fff',
    padding: '0 0 12px 0',
    '&:hover': {
    cursor: 'pointer',
    },
  },
  listAnchor: {
    textDecoration: 'none',
    color: 'black',
  },
  menuIconContainer: {
    justifyContent: 'flex-end',
    display: 'flex',
    top: '0px',
  },
  menuIconButton: {
    position: 'relative',
    transform: 'scale(1.5)',
  },
  menuIcon: {
    color: 'black',
  },
  mobileListClosedContainer: {
    backgroundColor: '#fafafa',
    zIndex: 1000,
    width: '100%',
    top: 0,
    bottom: 0,
    position: 'fixed',
    left: '-10000px',
    transition:
      'left 500ms, background-color 350ms cubic-bezier(0.55, 0, 0.1, 1)',
  },
  mobileListOpenContainer: {
    zIndex: 1000,
    width: '100%',
    top: 0,
    bottom: 0,
    left: 0,
    backgroundColor: '#262626',
    transition:
      'left 250ms, background-color 350ms cubic-bezier(0.55, 0, 0.1, 1)',
  },
  mobileMenuContainer: {
    padding: '36px',
    width: 'calc(100% - 60px)',
  },
  mobileCloseContainer: {
    justifyContent: 'flex-end',
    display: 'flex',
  },
  mobileCloseIcon: {
    color: '#ff6c45',
    padding: '18px',
    height: '60px',
    width: '60px',
    transform: 'scale(1.25)',
    cursor: 'pointer',
  },
};
