import { RootState } from 'store';

export const selectPerils = (state: RootState) =>
  state.perilsReducer.perils.status === 'success'
    ? state.perilsReducer.perils.data.filter((item) => item.name !== 'Death')
    : [];
export const selectPerilsLoading = (state: RootState) =>
  state.perilsReducer.perils.status === 'loading';
export const selectPerilsErrorMessage = (state: RootState) =>
  state.perilsReducer.perils.status === 'error' &&
  state.perilsReducer.perils.errorMessage;
