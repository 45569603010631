// types
import type { ProductOrder } from 'store/modules/orders/types';
import type { ConfigFileTypes, ConfigTheme } from 'types';

export const shopGTheme: ConfigTheme = {
  palette: {
    primary: {
      main: '#00a3e0',
      contrastText: '#fff',
    },
    text: {
      primary: '#000',
    },
  },
};

export const shopGGeneralConfig: ConfigFileTypes = {
  theme: shopGTheme,
  steps: [],
  submissionMapping: {},
  translationOverride: {
    general: {},
    product: {},
    underwriter: {},
  },
  links: {
    helpUrl: 'https://www.shopguarantee.com/',
    helpEmail: 'help@shopguarantee.com',
    privacyPolicyUrl: 'https://protecht.com/privacy-policy/',
    contactUsUrl: 'https://www.shopguarantee.com/contact-us',
  },
};

const shopGProdOrder: ProductOrder[] = [
  {
    id: 'F9BF752D-3654-479F-A3E7-5B0182CAF20C',
    reference_id: '#1234567',
    created: new Date().toISOString(),
    currency: 'USD',
    order_subtotal: '69.69',
    order_subtotal_literal: 'USD 69.69',
    order_premium_total: '0.00',
    order_premium_total_literal: 'USD 6.99',
    jurisdiction: {
      country: 'US',
      region: 'LA',
    },
    client: {
      name: 'Fanatics',
      id: '0428b167-a964-443b-bce1-b8fbd646e195',
    },
    customer: {
      id: 'E2FDD7E1-CD30-4A60-88F0-89D1B43CF2C3',
      first_name: 'Billy',
      last_name: 'Bob',
      email: 'billy@bob.com',
      phone: '5555555555',
      locale: 'en_US',
      address_1: '123 Test St',
      address_2: '',
      city: 'Newport Beach',
      state: 'CA',
      country: 'US',
      postal_code: '92663',
    },
    items: [
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB1',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E3',
        name: 'Russell Wilson Denver Broncos Nike Game Jersey',
        description: 'Orange Size: M',
        reference_number: 'some_reference_number',
        unit_cost: '129.99',
        unit_cost_literal: 'USD 129.99',
        image_url: 'string',
        external_url: 'string',
        claim_status: '',
        status: 'Issued',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: new Date(Date.now() + 864e5).toISOString(),
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB1a',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E3',
        name: 'Russell Wilson Denver Broncos Nike Game Jersey',
        description: 'Orange Size: M',
        reference_number: 'some_reference_number',
        unit_cost: '129.99',
        unit_cost_literal: 'USD 129.99',
        image_url: 'string',
        external_url: 'string',
        claim_status: 'Accepted',
        status: 'Issued',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: new Date(Date.now() + 864e5).toISOString(),
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB1b',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E3',
        name: 'Russell Wilson Denver Broncos Nike Game Jersey',
        description: 'Orange Size: M',
        reference_number: 'some_reference_number',
        unit_cost: '129.99',
        unit_cost_literal: 'USD 129.99',
        image_url: 'string',
        external_url: 'string',
        claim_status: 'Initiated',
        status: 'Issued',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: new Date(Date.now() + 864e5).toISOString(),
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB1c',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E3',
        name: 'Russell Wilson Denver Broncos Nike Game Jersey',
        description: 'Orange Size: M',
        reference_number: 'some_reference_number',
        unit_cost: '129.99',
        unit_cost_literal: 'USD 129.99',
        image_url: 'string',
        external_url: 'string',
        claim_status: 'Closed',
        status: 'Issued',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: new Date(Date.now() + 864e5).toISOString(),
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB1d',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E3',
        name: 'Russell Wilson Denver Broncos Nike Game Jersey',
        description: 'Orange Size: M',
        reference_number: 'some_reference_number',
        unit_cost: '129.99',
        unit_cost_literal: 'USD 129.99',
        image_url: 'string',
        external_url: 'string',
        claim_status: 'On Hold',
        status: 'Issued',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: new Date(Date.now() + 864e5).toISOString(),
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB1e',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E3',
        name: 'Russell Wilson Denver Broncos Nike Game Jersey',
        description: 'Orange Size: M',
        reference_number: 'some_reference_number',
        unit_cost: '129.99',
        unit_cost_literal: 'USD 129.99',
        image_url: 'string',
        external_url: 'string',
        claim_status: 'Pending Documents',
        status: 'Issued',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: new Date(Date.now() + 864e5).toISOString(),
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB1f',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E3',
        name: 'Russell Wilson Denver Broncos Nike Game Jersey',
        description: 'Orange Size: M',
        reference_number: 'some_reference_number',
        unit_cost: '129.99',
        unit_cost_literal: 'USD 129.99',
        image_url: 'string',
        external_url: 'string',
        claim_status: 'Pending Document Review',
        status: 'Issued',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: new Date(Date.now() + 864e5).toISOString(),
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB1g',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E3',
        name: 'Russell Wilson Denver Broncos Nike Game Jersey',
        description: 'Orange Size: M',
        reference_number: 'some_reference_number',
        unit_cost: '129.99',
        unit_cost_literal: 'USD 129.99',
        image_url: 'string',
        external_url: 'string',
        claim_status: 'Approved',
        status: 'Issued',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: new Date(Date.now() + 864e5).toISOString(),
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB1h',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E3',
        name: 'Russell Wilson Denver Broncos Nike Game Jersey',
        description: 'Orange Size: M',
        reference_number: 'some_reference_number',
        unit_cost: '129.99',
        unit_cost_literal: 'USD 129.99',
        image_url: 'string',
        external_url: 'string',
        claim_status: 'Good Will Payout',
        status: 'Issued',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: new Date(Date.now() + 864e5).toISOString(),
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB1i',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E3',
        name: 'Russell Wilson Denver Broncos Nike Game Jersey',
        description: 'Orange Size: M',
        reference_number: 'some_reference_number',
        unit_cost: '129.99',
        unit_cost_literal: 'USD 129.99',
        image_url: 'string',
        external_url: 'string',
        claim_status: 'Denied',
        status: 'Issued',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: new Date(Date.now() + 864e5).toISOString(),
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB1l',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E3',
        name: 'Russell Wilson Denver Broncos Nike Game Jersey',
        description: 'Orange Size: M',
        reference_number: 'some_reference_number',
        unit_cost: '129.99',
        unit_cost_literal: 'USD 129.99',
        image_url: 'string',
        external_url: 'string',
        claim_status: '',
        status: 'Accepted',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: new Date(Date.now() + 864e5).toISOString(),
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB1m',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E3',
        name: 'Russell Wilson Denver Broncos Nike Game Jersey',
        description: 'Orange Size: M',
        reference_number: 'some_reference_number',
        unit_cost: '129.99',
        unit_cost_literal: 'USD 129.99',
        image_url: 'string',
        external_url: 'string',
        claim_status: '',
        status: 'Insufficient Address',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: new Date(Date.now() + 864e5).toISOString(),
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB1n',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E3',
        name: 'Russell Wilson Denver Broncos Nike Game Jersey',
        description: 'Orange Size: M',
        reference_number: 'some_reference_number',
        unit_cost: '129.99',
        unit_cost_literal: 'USD 129.99',
        image_url: 'string',
        external_url: 'string',
        claim_status: '',
        status: 'On Hold',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: new Date(Date.now() + 864e5).toISOString(),
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB1o',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E3',
        name: 'Russell Wilson Denver Broncos Nike Game Jersey',
        description: 'Orange Size: M',
        reference_number: 'some_reference_number',
        unit_cost: '129.99',
        unit_cost_literal: 'USD 129.99',
        image_url: 'string',
        external_url: 'string',
        claim_status: '',
        status: 'OFAC Hold',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: new Date(Date.now() + 864e5).toISOString(),
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB1p',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E3',
        name: 'Russell Wilson Denver Broncos Nike Game Jersey',
        description: 'Orange Size: M',
        reference_number: 'some_reference_number',
        unit_cost: '129.99',
        unit_cost_literal: 'USD 129.99',
        image_url: 'string',
        external_url: 'string',
        claim_status: '',
        status: 'Void Initiated',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: new Date(Date.now() + 864e5).toISOString(),
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB1q',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E3',
        name: 'Russell Wilson Denver Broncos Nike Game Jersey',
        description: 'Orange Size: M',
        reference_number: 'some_reference_number',
        unit_cost: '129.99',
        unit_cost_literal: 'USD 129.99',
        image_url: 'string',
        external_url: 'string',
        claim_status: '',
        status: 'Voided',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: new Date(Date.now() + 864e5).toISOString(),
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB1r',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E3',
        name: 'Russell Wilson Denver Broncos Nike Game Jersey',
        description: 'Orange Size: M',
        reference_number: 'some_reference_number',
        unit_cost: '129.99',
        unit_cost_literal: 'USD 129.99',
        image_url: 'string',
        external_url: 'string',
        claim_status: '',
        status: 'Pending Surcharge',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: new Date(Date.now() + 864e5).toISOString(),
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB1s',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E3',
        name: 'Russell Wilson Denver Broncos Nike Game Jersey',
        description: 'Orange Size: M',
        reference_number: 'some_reference_number',
        unit_cost: '129.99',
        unit_cost_literal: 'USD 129.99',
        image_url: 'string',
        external_url: 'string',
        claim_status: '',
        status: 'Upgrade Initiated',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: new Date(Date.now() + 864e5).toISOString(),
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB1t',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E3',
        name: 'Russell Wilson Denver Broncos Nike Game Jersey',
        description: 'Orange Size: M',
        reference_number: 'some_reference_number',
        unit_cost: '129.99',
        unit_cost_literal: 'USD 129.99',
        image_url: 'string',
        external_url: 'string',
        claim_status: '',
        status: 'Upgraded',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: new Date(Date.now() + 864e5).toISOString(),
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB1u',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E3',
        name: 'Russell Wilson Denver Broncos Nike Game Jersey',
        description: 'Orange Size: M',
        reference_number: 'some_reference_number',
        unit_cost: '129.99',
        unit_cost_literal: 'USD 129.99',
        image_url: 'string',
        external_url: 'string',
        claim_status: '',
        status: 'Downgrade Initiated',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: new Date(Date.now() + 864e5).toISOString(),
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB1w',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E3',
        name: 'Russell Wilson Denver Broncos Nike Game Jersey',
        description: 'Orange Size: M',
        reference_number: 'some_reference_number',
        unit_cost: '129.99',
        unit_cost_literal: 'USD 129.99',
        image_url: 'string',
        external_url: 'string',
        claim_status: '',
        status: 'Downgraded',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: new Date(Date.now() + 864e5).toISOString(),
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB1x',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E3',
        name: 'Russell Wilson Denver Broncos Nike Game Jersey',
        description: 'Orange Size: M',
        reference_number: 'some_reference_number',
        unit_cost: '129.99',
        unit_cost_literal: 'USD 129.99',
        image_url: 'string',
        external_url: 'string',
        claim_status: '',
        status: 'Uploaded',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: new Date(Date.now() + 864e5).toISOString(),
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB2',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E2',
        name: 'Colorado Avalanche Fanatics Authentic 2022 Stanley Cup Champions Crystal Stanley',
        description:
          'Cup Filled with Game-Used Ice From the 2022 Stanley Cup Final',
        reference_number: 'some_reference_number',
        unit_cost: '79.99',
        unit_cost_literal: 'USD 79.99',
        image_url: 'string',
        external_url: 'string',
        claim_status: '',
        status: 'Issued',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: new Date(Date.now() + 864e5).toISOString(),
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB3',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E1',
        name: 'Colorado Avalanche WinCraft 2022 Stanley Cup Champions',
        description: '3 x 5 1-Sided Deluxe Flag',
        reference_number: 'some_reference_number',
        unit_cost: '42.99',
        unit_cost_literal: 'USD 42.99',
        image_url: 'string',
        external_url: 'string',
        claim_status: '',
        status: 'Issued',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: new Date(Date.now() + 864e5).toISOString(),
          number: '456QWERTY789',
          carrier: 'USPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB4',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E4',
        name: 'Colorado Rockies Nike Wordmark Legend T-Shirt',
        description: 'Black Size: XL',
        reference_number: 'some_reference_number',
        unit_cost: '42.99',
        unit_cost_literal: 'USD 42.99',
        image_url: '',
        external_url: '',
        claim_status: '',
        status: 'Issued',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: null,
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFC4',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E4',
        name: 'Colorado Rockies Nike Wordmark Legend T-Shirt',
        description: 'Black Size: XL',
        reference_number: 'some_reference_number',
        unit_cost: '42.99',
        unit_cost_literal: 'USD 42.99',
        image_url: 'string',
        external_url: 'string',
        claim_status: '',
        status: 'Issued',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: null,
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
    ],
    impressions_count: 69,
  },
  {
    id: 'F9BF752D-3654-479F-A3E7-5B0182CAF20D',
    reference_id: '#456789790',
    created: new Date(Date.now() - 864e5).toISOString(),
    currency: 'USD',
    order_subtotal: '69.69',
    order_subtotal_literal: 'USD 69.69',
    order_premium_total: '0.00',
    order_premium_total_literal: 'USD 6.99',
    jurisdiction: {
      country: 'US',
      region: 'LA',
    },
    client: {
      name: 'Fanatics',
      id: '0428b167-a964-443b-bce1-b8fbd646e195',
    },
    customer: {
      id: 'E2FDD7E1-CD30-4A60-88F0-89D1B43CF2C3',
      first_name: 'Lance',
      last_name: 'Bob',
      email: 'billy@bob.com',
      phone: '5555555555',
      locale: 'en_US',
      address_1: '456 Test St',
      address_2: '',
      city: 'Something Beach',
      state: 'CA',
      country: 'US',
      postal_code: '92665',
    },
    items: [
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB5',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E5',
        name: 'Colorado Rockies New Era 2022 City Connect 59FIFTY Fitted Hat ',
        description: 'White Size: 7 1/8',
        reference_number: 'some_reference_number',
        unit_cost: '45.99',
        unit_cost_literal: 'USD 45.99',
        image_url: '',
        external_url: '',
        claim_status: '',
        status: 'Issued',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: new Date(Date.now() + 864e5).toISOString(),
          number: '346LMNOPWRS789',
          carrier: 'UPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
    ],
    impressions_count: 70,
  },
  {
    id: 'F9BF752D-3654-479F-A3E7-5B0182CAF20E',
    reference_id: '#9874',
    created: '2022-08-01T16:27:38.635939Z',
    currency: 'USD',
    order_subtotal: '69.69',
    order_subtotal_literal: 'USD 69.69',
    order_premium_total: '0.00',
    order_premium_total_literal: 'USD 6.99',
    jurisdiction: {
      country: 'US',
      region: 'LA',
    },
    client: {
      name: 'NHLShop.com',
      id: '0428b167-a964-443b-bce1-b8fbd646e196',
    },
    customer: {
      id: 'E2FDD7E1-CD30-4A60-88F0-89D1B43CF2C5',
      first_name: 'Lancette',
      last_name: 'Bob',
      email: 'billy@bob.com',
      phone: '5555555555',
      locale: 'en_US',
      address_1: '895 Test St',
      address_2: '',
      city: 'Another Beach',
      state: 'CA',
      country: 'US',
      postal_code: '92668',
    },
    items: [
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB6',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E6',
        name: "Men's Colorado Avalanche JH Design Navy Jacket",
        description: 'Size L',
        reference_number: 'some_reference_number',
        unit_cost: '1500.00',
        unit_cost_literal: 'USD 1,500.00',
        image_url: 'image.com/?id=123',
        external_url: 'coolbeans.com',
        claim_status: 'Approved',
        status: 'Issued',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: '2022-07-02T16:27:38.635939Z',
          number: '876SDFLKJSF777',
          carrier: 'UPS',
        },
        policy: {
          certificate_of_coverage: '',
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
    ],
    impressions_count: 71,
  },
  {
    id: 'F9BF752D-3654-479F-A3E7-5B0182CAF20F',
    reference_id: '#456789791',
    created: '2021-08-18T16:27:38.635939Z',
    currency: 'USD',
    order_subtotal: '69.69',
    order_subtotal_literal: 'USD 69.69',
    order_premium_total: '0.00',
    order_premium_total_literal: 'USD 6.99',
    jurisdiction: {
      country: 'US',
      region: 'LA',
    },
    client: {
      name: 'Fanatics',
      id: '0428b167-a964-443b-bce1-b8fbd646e195',
    },
    customer: {
      id: 'E2FDD7E1-CD30-4A60-88F0-89D1B43CF2C5',
      first_name: 'Lancette',
      last_name: 'Bob',
      email: 'billy@bob.com',
      phone: '5555555555',
      locale: 'en_US',
      address_1: '895 Test St',
      address_2: '',
      city: 'Another Beach',
      state: 'CA',
      country: 'US',
      postal_code: '92668',
    },
    items: [
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB7',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E7',
        name: 'Colorado Rockies New Era 2022 City Connect 59FIFTY Fitted Hat ',
        description: 'White Size: 7 1/8',
        reference_number: 'some_reference_number',
        unit_cost: '45.99',
        unit_cost_literal: 'USD 45.99',
        image_url: '',
        external_url: '',
        claim_status: '',
        status: 'Issued',
        issued: '2022-06-02T16:27:38.635939Z',
        tracking: {
          created: '2022-08-20T16:27:38.635939Z',
          number: '346LMNOPWRS790',
          carrier: 'UPS',
        },
        policy: {
          id: '5df9ac9a-8917-42b4-9e0e-95bcc2aa0221',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          certificate_of_coverage: '',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
      },
    ],
    impressions_count: 72,
  },
];

export const shopGOrders = {
  count: 4,
  next: 'https://newdomain.protecht.com/shopguarantee/orders/?limit=20&offset=20',
  previous: null,
  items: shopGProdOrder,
};
