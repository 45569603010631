import React, { FC, KeyboardEvent } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import LoadingButton from 'components/LoadingButton';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { FileClaimProps, FormInputs } from './types';

const schema = yup
  .object({
    email: yup.string().required().email(),
  })
  .required();

const FileClaim: FC<FileClaimProps> = function ({
  onSubmit,
  loading,
  error,
  translation,
  children,
}) {
  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });

  const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleSubmit(proceed)();
    }
  };

  const proceed = (data: FormInputs) => {
    onSubmit(data);
  };
  return (
    <Stack className="text-center">
      <Grid container justifyContent="center">
        <Grid item sm={8} xs={12} px={{ xs: 4, sm: 0 }}>
          <Controller
            name="email"
            control={control}
            render={({
              field: { onChange, value },
              fieldState: { error: formError },
            }) => (
              <TextField
                variant="filled"
                hiddenLabel
                onChange={onChange}
                value={value}
                error={Boolean(error || formError)}
                helperText={formError?.message || error || ' '}
                onKeyPress={handleKeyPress}
                name="email"
                disabled={loading}
                placeholder={translation.placeholder}
              />
            )}
          />
        </Grid>
      </Grid>

      <Box>{children}</Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          my: 3,
          mt: {
            xs: 4,
            sm: 3,
          },
        }}
      >
        <LoadingButton
          variant="contained"
          loading={loading}
          disabled={!isValid || loading}
          onClick={handleSubmit(proceed)}
          className="btn-style"
        >
          {translation.buttonName}
        </LoadingButton>
      </Box>
    </Stack>
  );
};

export default FileClaim;
