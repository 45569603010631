// env
export const isProduction = process.env.REACT_APP_NODE_ENV === 'production';
export const isDevelop = process.env.REACT_APP_NODE_ENV === 'development';
export const isSandbox = process.env.REACT_APP_NODE_ENV === 'sandbox';
export const isUat = process.env.REACT_APP_NODE_ENV === 'uat';
export const isQa = process.env.REACT_APP_NODE_ENV === 'qa';
export const testingEnv = ['qa', 'dev', 'sandbox', 'uat', 'local'];

export const getEnvShortname = () => {
  let envShortname = '';

  switch (process.env.REACT_APP_NODE_ENV) {
    case 'development': {
      envShortname = 'dev';
      break;
    }
    case 'qa': {
      envShortname = 'qa';
      break;
    }
    case 'uat': {
      envShortname = 'uat';
      break;
    }
    case 'sandbox': {
      envShortname = 'sandbox';
      break;
    }
    default: {
      break;
    }
  }

  return envShortname;
};
