// react
import React from 'react';

// packages
import { Stack } from '@mui/material';

// utils
import { generateTranslation } from 'utils/translation/i18nextTranslation';

// layout
import Layout from 'components/Layout';

// components
import HeaderDescription from 'components/HeaderDescription';

// types
import { ReviewType } from 'utils/translation/types';

export const CancellationErrorPageContent: React.FC = function () {
  // translations
  const translation = generateTranslation('review') as ReviewType;

  return (
    <Stack>
      <Layout sx={{ mb: 3 }}>
        <HeaderDescription
          title={translation.header.title}
          description="This order has already shipped so coverage can no longer be cancelled."
        />
      </Layout>
    </Stack>
  );
};

export default CancellationErrorPageContent;
