import React, { VFC } from 'react';
import { generateTranslation } from 'utils/translation/i18nextTranslation';
import { FormattedOrder, FormattedItem } from 'store/modules/orders/types';
import { ReviewTableType } from 'utils/translation/types';
import { formattedCost } from 'utils/helpers';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { dateSlashes } from 'utils/date';
import { isEmptyObject } from 'utils/object';
import Loader from 'components/Loader';

const ReviewTable: VFC<{
  selectedItems: FormattedItem[];
  selectedOrder: FormattedOrder;
  displayPremiumTotal?: boolean;
}> = function ({ selectedItems, selectedOrder, displayPremiumTotal = false }) {
  const totalItemCoverage = () =>
    selectedItems
      .map((el) => parseFloat(el.premium || '0'))
      .reduce((total, num) => total + num);

  const coverageTotal = () =>
    selectedItems
      .map((el) => parseFloat(el.unit_cost))
      .reduce((total, num) => total + num);

  const translation = generateTranslation('reviewTable') as ReviewTableType;

  if (isEmptyObject(selectedOrder)) return <Loader />;

  return (
    <>
      <Card raised={false} sx={{ bgcolor: 'primary.main', p: 3 }}>
        <Typography
          style={{ fontSize: 16, color: 'white', fontWeight: 'bold' }}
        >
          {`${dateSlashes({ item: selectedOrder?.created })} - ${
            selectedOrder.client_name
          } - Order: ${selectedOrder.reference_id}`}
        </Typography>
      </Card>
      <TableContainer component={Paper} sx={{ mb: 3 }}>
        <Table
          style={{ backgroundColor: '#f9fafb' }}
          aria-label={selectedOrder?.client_name || selectedOrder?.reference_id}
        >
          <TableHead>
            <TableRow>
              <TableCell
                style={{ fontWeight: 'bold', textTransform: 'capitalize' }}
              >
                {translation.title}
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 'bold' }}>
                {translation.covered}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedItems.map((item, index) => (
              <TableRow
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell component="th" scope="row" size="small">
                  <Typography>{item.name}</Typography>
                </TableCell>
                <TableCell align="right">
                  {formattedCost(
                    parseFloat(item.unit_cost),
                    selectedOrder.locale,
                    selectedOrder.currency
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography className="text-right" fontSize="18px" pr={4} mb={3}>
        {translation.total}
        <strong>
          {displayPremiumTotal
            ? formattedCost(
                totalItemCoverage(),
                selectedOrder.locale,
                selectedOrder.currency
              )
            : formattedCost(
                coverageTotal(),
                selectedOrder.locale,
                selectedOrder.currency
              )}
        </strong>
      </Typography>
    </>
  );
};

export default ReviewTable;
