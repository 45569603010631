import React, { VFC } from 'react';
import Layout from 'components/Layout';
import Panel from 'components/Panel';
import Typography from '@mui/material/Typography';

export const DescriptionPanel: VFC<{
  description?: string;
  title?: string;
}> = function ({ description, title }) {
  return (
    <Panel>
      <Layout>
        {title && (
          <Typography
            variant="h5"
            className="headline font-weight-bold text-capitalize"
            mb={3}
          >
            {title}
          </Typography>
        )}
        {description && <Typography mb={2}>{description}</Typography>}
      </Layout>
    </Panel>
  );
};
