import React, { memo, VFC } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
// eslint-disable-next-line import/no-extraneous-dependencies
import isEqual from 'lodash/isEqual';
import { getStatusClass } from 'utils/helpers';
import { FormattedItem, FormattedOrder } from 'store/modules/orders/types';
import { GenericItemTableProps } from '../types';

const GenericItemTable: VFC<GenericItemTableProps> = memo(
  ({
    order,
    items,
    rows,
    columns,
    selected,
    handleSelectionUpdate,
    handleAttemptToSelect,
  }) => (
    <Box
      sx={{
        overflowX: 'auto',
        overflowY: 'hidden',
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        disableSelectionOnClick
        onSelectionModelChange={(selection) => {
          handleSelectionUpdate(
            selection.map((index) => items[Number(index)]) as FormattedItem[],
            order as FormattedOrder
          );
        }}
        selectionModel={selected.map((selection) =>
          items.findIndex((item) => item.id === selection.id)
        )}
        onCellClick={handleAttemptToSelect}
        onColumnHeaderClick={handleAttemptToSelect}
        checkboxSelection
        disableColumnFilter
        disableColumnSelector
        autoHeight
        hideFooter
        disableColumnMenu
        getRowClassName={(params) =>
          getStatusClass(params.row.status, params.row.claim_status)
        }
        sx={{
          '& .MuiDataGrid-cellCheckbox': {
            contentVisibility: 'hidden',
          },
        }}
      />
    </Box>
  ),
  (prevProps, nextProps) => isEqual(prevProps.selected, nextProps.selected)
);

export default GenericItemTable;
