// mocks
import { fanshieldGeneralConfig } from 'mocks/products/fanshield';

// types
import type { ConfigFileTypes, ConfigStep } from 'types';

const fanshieldCertificateSteps: ConfigStep[] = [
  {
    name: 'CertificatePage',
    backButtonText: '',
    fields: [
      {
        component: 'CertificatePage',
        verbiages: {},
      },
    ],
  },
];

export const fanshieldCertificateConfig: ConfigFileTypes = {
  ...fanshieldGeneralConfig,
  steps: fanshieldCertificateSteps,
};
