import { StepComponent } from 'components/FormBuilder/types';
import { ComponentStructure } from 'types';

export const generateSplitLayout = (
  layout: ComponentStructure[],
  elementsToSplit: StepComponent[],
  excludedElements?: StepComponent[]
): Record<number, ComponentStructure[] | ComponentStructure> => {
  const splitArrayObject: Record<
    number,
    ComponentStructure[] | ComponentStructure
  > = {};
  let indexToAddInto = 0;

  splitArrayObject[indexToAddInto] = [];

  /*
   * iterate through the layout and isolate elements which need to be displayed with another type of form layout,
   * otherwise add them to the same array of elements to they can be iterated on
   */
  layout.forEach((elem) => {
    if (elementsToSplit.includes(elem.component)) {
      if (
        Array.isArray(splitArrayObject[indexToAddInto]) &&
        (splitArrayObject[indexToAddInto] as ComponentStructure[]).length
      ) {
        indexToAddInto += 1;
      }

      splitArrayObject[indexToAddInto] = elem;
      indexToAddInto += 1;
      splitArrayObject[indexToAddInto] = [];
    } else {
      // add to array only if element is not on list of excluded elements
      const newAddition =
        !excludedElements || !excludedElements.includes(elem.component)
          ? [elem]
          : [];
      splitArrayObject[indexToAddInto] = [
        ...(splitArrayObject[indexToAddInto] as ComponentStructure[]),
        ...newAddition,
      ];
    }
  });

  return splitArrayObject;
};
