import React, { VFC, useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import Alert from '@mui/material/Alert';
import Fade from '@mui/material/Fade';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { AlertBarProps } from './types';

const styles = {
  fixedToTop: {
    position: 'fixed',
    width: '100%',
    zIndex: 100,
  },
};

const AlertBar: VFC<AlertBarProps> = function ({
  type,
  message,
  excludedRoutes,
  dismissAlertCb,
}) {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [localMessage, setLocalMessage] = useState(message);
  const [dismissAlertTimeoutQueue, setDismissAlertTimeoutQueue] = useState<
    ReturnType<typeof setTimeout>[]
  >([]);
  const [dismissAlertInterval, setDismissAlertInterval] = useState(false);
  const [previousRoute, setPreviousRoute] = useState(location.pathname);

  const clearAllTimeouts = useCallback(() => {
    if (dismissAlertTimeoutQueue.length) {
      dismissAlertTimeoutQueue.forEach((currentTimeoutId) => {
        clearTimeout(currentTimeoutId);
      });
      setDismissAlertTimeoutQueue([]);
    }
  }, [dismissAlertTimeoutQueue]);

  const dismissAlert = useCallback(() => {
    setShow(false);
    setLocalMessage('');
  }, []);

  useEffect(() => {
    if (
      previousRoute !== location.pathname &&
      !excludedRoutes.includes(location.pathname)
    ) {
      dismissAlert();
      clearAllTimeouts();
      setPreviousRoute(location.pathname);
    }
  }, [
    location.pathname,
    dismissAlert,
    clearAllTimeouts,
    excludedRoutes,
    previousRoute,
  ]);

  useEffect(() => {
    if (message) {
      setShow(true);
      setLocalMessage(message);
      setDismissAlertInterval(true);
    }
  }, [message]);

  useEffect(() => {
    if (!dismissAlertInterval) return;

    setDismissAlertInterval(false);

    clearAllTimeouts();

    const timeoutId = setTimeout(() => {
      dismissAlert();
    }, 5000);

    setDismissAlertTimeoutQueue((state) => [...state, timeoutId]);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [dismissAlertInterval, clearAllTimeouts, dismissAlert]);

  return (
    <Fade in={show} onExited={dismissAlertCb}>
      <Alert
        sx={styles.fixedToTop}
        variant="filled"
        severity={type}
        onClose={dismissAlert}
        iconMapping={{
          error: <ReportProblemIcon />,
          success: <CheckCircleIcon />,
        }}
      >
        {localMessage}
      </Alert>
    </Fade>
  );
};

export default AlertBar;
