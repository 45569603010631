import React from 'react';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowModel,
} from '@mui/x-data-grid';
import { generateTranslation } from 'utils/translation/i18nextTranslation';
import { ItemTableType } from 'utils/translation/types';
import { statusColumn } from 'utils/itemTable';
import ValueTooltip from 'components/ValueTooltip';
import { dateSlashes } from 'utils/date';
import { ItemTableProps } from '../types';
import GenericItemTable from './GenericItemTable';

export type ItemTableGridRow = {
  id: number;
  status: string;
  claim_status: string;
  name: string;
  coverage_amount: string;
  sold_by: string;
};

const ItemTable: React.FC<ItemTableProps> = function ({
  order,
  items,
  selected,
  handleSelectionUpdate,
  handleAttemptToSelect,
}) {
  const translation = generateTranslation('itemTable') as ItemTableType;
  const hasSelections = selected.length > 0;

  const rows: GridRowModel<ItemTableGridRow>[] = items.map((item, index) => ({
    id: index,
    status: item.status,
    claim_status: item.claim_status,
    name: item.name,
    coverage_amount: item.unit_cost_literal,
    sold_by: item.policy.sold_by || '-',
    event_date: item.event?.start_date || '-',
    event_name: item.event?.name || '-',
  }));

  const defaultGridColDef = {
    sortable: false,
    hideable: false,
  };

  const columns: GridColDef<ItemTableGridRow>[] = [
    {
      ...statusColumn,
      ...defaultGridColDef,
      width: 130,
    },
    {
      field: 'event_date',
      headerName: 'Event Date',
      renderCell: (params: GridRenderCellParams<string>) => (
        <ValueTooltip
          value={dateSlashes({ item: params.value, placeholder: '-' })}
        />
      ),
      ...defaultGridColDef,
      flex: 2,
    },
    {
      field: 'event_name',
      headerName: 'Event Name',
      renderCell: (params: GridRenderCellParams<string>) => (
        <ValueTooltip value={params.value} />
      ),
      ...defaultGridColDef,
      flex: 2.5,
    },
    {
      field: 'name',
      headerName: 'Description',
      renderCell: (params: GridRenderCellParams<string>) => (
        <ValueTooltip value={params.value} />
      ),
      ...defaultGridColDef,
      flex: 2.5,
    },
    {
      field: 'coverage_amount',
      headerName: translation.header,
      ...defaultGridColDef,
      width: 140,
    },
    {
      field: 'sold_by',
      headerName: 'Sold By',
      renderCell: (params: GridRenderCellParams<string>) => (
        <ValueTooltip value={params.value} />
      ),
      ...defaultGridColDef,
      flex: 2,
    },
  ];

  return (
    <GenericItemTable
      order={order}
      items={items}
      selected={selected}
      handleSelectionUpdate={handleSelectionUpdate}
      handleAttemptToSelect={handleAttemptToSelect}
      rows={rows}
      columns={columns}
      hasSelections={hasSelections}
    />
  );
};

export default ItemTable;
