import React, { VFC, memo } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import isEqual from 'lodash/isEqual';
import { FormattedItem, FormattedOrder } from 'store/modules/orders/types';
import { formattedCost } from 'utils/helpers';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { dateSlashes } from 'utils/date';

const UntrackedItemsTable: VFC<{
  rows: FormattedItem[];
  order: FormattedOrder;
  showHeader: boolean;
}> = memo(
  ({ rows, order, showHeader }) => (
    <TableContainer component={Paper}>
      <Table
        aria-label="Unshipped items"
        sx={{
          minWidth: '888px',
          '& .MuiTableCell-root': {
            fontSize: '14px',
            paddingX: (theme) => theme.spacing(3),
          },
          '& .MuiTableHead-root': {
            backgroundColor: (theme) => theme.palette.grey[300],
          },
        }}
      >
        <TableHead>
          <TableRow>
            {showHeader ? (
              <>
                <TableCell colSpan={2}>
                  Order Date: {dateSlashes({ item: order?.created })} -{' '}
                  {order.client_name} - Order ID: {order.reference_id}
                </TableCell>
                <TableCell colSpan={1}>Not Shipped yet</TableCell>
              </>
            ) : (
              <TableCell colSpan={3}>Not Shipped yet</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                display: 'flex',
              }}
            >
              <TableCell component="th" scope="row" sx={{ flex: 9 }}>
                {row.name} - {row.description}
              </TableCell>
              <TableCell sx={{ flex: 1 }}>
                {formattedCost(
                  parseInt(row.unit_cost, 10),
                  order.locale,
                  order.currency
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ),
  (prevProps, nextProps) => isEqual(prevProps.rows, nextProps.rows)
);

export default UntrackedItemsTable;
