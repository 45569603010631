import React from 'react';
import Typography from '@mui/material/Typography';
import HeaderDescription from 'components/HeaderDescription';
import { useAppSelector } from 'store/hooks';
import { selectLinks } from 'store/modules/config/selectors';
import Link from '@mui/material/Link';
import { Flow } from 'store/modules/config/types';
import { FeedbackNotSumbittedProps } from './types';
import Footer from './Footer';

export const FeedbackNotSubmitted: React.VFC<FeedbackNotSumbittedProps> =
  function ({
    type,
    claimNumber,
    comment,
    isFeedbackLoading,
    rating,
    setComment,
    setRating,
    submitRating,
    translation,
  }) {
    const links = useAppSelector(selectLinks);

    switch (type) {
      case Flow.recharge:
      case Flow.billing:
        return (
          <HeaderDescription title={translation.header.title.notSubmitted}>
            <Typography className="text-center" my={3} px={{ xs: 3, sm: 5 }}>
              {translation.description}
              <Link
                underline="always"
                href={translation?.links?.helpEmail || links.helpEmail}
                target="_blank"
              >
                {translation.link}
              </Link>
              {translation.footer}
            </Typography>
          </HeaderDescription>
        );

      case Flow.cancellation:
        return (
          <HeaderDescription title={translation.header.title.notSubmitted}>
            <>
              <Typography className="text-center" my={3}>
                {translation.header.description.row1}
                {translation.header.description.row2}
                {translation.header.description.row3} {claimNumber}
              </Typography>
              <Typography className="text-center" my={3} px={{ xs: 3, sm: 5 }}>
                {translation.description}
                <Link
                  underline="always"
                  href={translation?.links?.helpEmail || links.helpEmail}
                  target="_blank"
                >
                  {translation.link}
                </Link>
                {translation.footer}
              </Typography>
            </>
          </HeaderDescription>
        );

      default:
        return (
          <>
            <HeaderDescription title={translation.header.title.notSubmitted}>
              <>
                <Typography className="text-center" my={3}>
                  {translation.header.description.row1}
                  {translation.header.description.row2}
                  {translation.header.description.row3} {claimNumber}
                </Typography>

                <Typography
                  className="text-center"
                  my={3}
                  px={{ xs: 3, sm: 5 }}
                >
                  {translation.description}
                  <Link
                    underline="always"
                    href={translation?.links?.helpEmail || links.helpEmail}
                    target="_blank"
                  >
                    {translation.link}
                  </Link>
                  {translation.footer}
                </Typography>
              </>
            </HeaderDescription>
            <Footer
              translation={translation}
              rating={rating}
              setRating={setRating}
              setComment={setComment}
              comment={comment}
              isFeedbackLoading={isFeedbackLoading}
              submitRating={submitRating}
            />
          </>
        );
    }
  };
