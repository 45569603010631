import React, { VFC } from 'react';
import { SvgIconProps, SvgIconTypeMap } from '@mui/material/SvgIcon';
import * as MuiIcons from './icon_module';

const PerilIcon: VFC<
  SvgIconProps<SvgIconTypeMap['defaultComponent'], { icon: string }>
> = function ({ icon, ...rest }) {
  const IconName = MuiIcons[icon] || MuiIcons.HorizontalRule;

  return <IconName sx={{ height: '40px', width: '40px' }} {...rest} />;
};

export default PerilIcon;
