import React, { VFC } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { generateTranslation } from 'utils/translation/i18nextTranslation';
import { AppType } from "utils/translation/types";

type SessionModalProps = {
  show: boolean;
  onSubmit(): void;
  onDecline(): void;
};

const SessionModal: VFC<SessionModalProps> = function ({
  show,
  onDecline,
  onSubmit,
}) {

  const translation = generateTranslation('app') as AppType
  return (
    <Dialog
      open={show}
      aria-labelledby="session-title"
      aria-describedby="session-description"
    >
      <DialogTitle
        id="session-title"
        className="headline font-weight-bold text-center"
      >
        {translation.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{ mb: 3 }}
          id="session-description"
          className="text-center"
        >
          This will take you back to where you last left off!
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDecline} style={{ textTransform: 'uppercase' }}>
          No
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onSubmit}
          style={{ textTransform: 'uppercase' }}
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionModal;
