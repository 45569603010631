import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "./api";

export const getAccessToken = createAsyncThunk('auth/getAccessToken', async ({ token }: { token: string }, thunkApi) => {
    try {
        const response = await api.getAccessToken(token)
        return response.data
    } catch (e) {
        const error = e as any;
        return thunkApi.rejectWithValue(error.response.data)
    }
})

export const extendAccessToken = createAsyncThunk('auth/extendToken', async ({ accessToken }: { accessToken: string }, thunkApi) => {
    try {
        const response = await api.extendAccessToken(accessToken)
        return response.data
    } catch (e) {
        const error = e as any;
        return thunkApi.rejectWithValue(error.response.data)
    }
})