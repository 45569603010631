/* eslint-disable no-template-curly-in-string */
export default {
  appComponent: {
    title: 'Continue With Your Unfinished Refund request?',
    appTitle: 'Refunds Form',
    footer: {
      title: 'Need to Start',
      disclaimer: '',
    },
  },
  filePage: {
    title: 'Update Billing Information Request',
  },
  orderSelectionPage: {
    consentText:
      'By checking this box you consent to the processing of your personal information under our privacy policy (available below).',
    button: 'Update Billing Address',
    noOrders: 'No refund protection found.',
    rules: {
      row1: '',
      row2: '',
    },
    header: {
      row2: 'We were unable to complete your transaction to protect events listed below. Let\'s retry. Please select the order that you\'d like to update your billing address for and click the "Update Billing Address" button to re-enter your billing information.',
    },
    itemTable: {
      header: 'Refundable Amount',
    },
  },
  emailSent: {
    description:
      "Click the link, and you'll be signed in. This is a one-time use link and expires in 4 hours.",
  },
  reviewPage: {
    header: {
      description:
        'Below, please review the information you have provided us for accuracy. Updating your billing address will successfully complete your purchase and help us expedite the process. Once you confirm the information is correct, please click the "Update Address" button.',
    },
    sign: {
      button: 'Update Address',
      cancel: '',
      note: '',
      claims: {
        text: "By clicking 'Submit Refund' you certify that all information on this refund request is accurate and truthful under  penalty of perjury.  FanShield, RegShield, and TourShield are marketing product names for the ticket event programme Refund Protection. Terms, Conditions,",
        footer:
          ' and Coverage may vary. See Application and Refund Protection for full details. You can view Protecht, ',
        floridaText: '',
        link: {
          text: {
            row2: 'updates concerning your Refund Protection.',
          },
        },
      },
    },
    reviewTable: {
      total: 'Your Protection Quote Total: ',
      title: 'Description',
      covered: 'Refundable Amount',
    },
  },
  addressForm: {
    labels: {
      city: 'City/Town',
      state: 'Province/Region',
      zip_code: 'Postal Code',
    },
  },
  thankYouPage: {
    header: {
      description: {
        row2: 'Refund request',
      },
      title: {
        submitted: 'Thank You, Have a Great Day!',
        notSubmitted: 'Thank You. Your Order Has Been Submitted.',
      },
    },
    description:
      'Thank you, ${GLOBAL.customerReducer.customer.first_name}! You will receive a confirmation to ${GLOBAL.customerReducer.customer.email} within 24 hours, once your order has been processed, with your coverage  information and other details. Please ',
  },
};
/* eslint-enable no-template-curly-in-string */
