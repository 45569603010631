import React, { memo, VFC } from 'react';
import {
  GridRowsProp,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid';
// eslint-disable-next-line import/no-extraneous-dependencies
import isEqual from 'lodash/isEqual';
import { statusColumn } from 'utils/itemTable';
import { dateSlashes } from 'utils/date';
import ValueTooltip from 'components/ValueTooltip';
import GenericItemTable from './GenericItemTable';
import { ItemTableProps } from '../types';

const ItemTable: VFC<ItemTableProps> = memo(
  ({
    order,
    items,
    selected,
    handleSelectionUpdate,
    handleAttemptToSelect,
  }) => {
    const hasSelections = selected.length > 0;

    const rows: GridRowsProp = items.map((item, index) => ({
      id: index,
      status: item.status,
      claim_status: item.claim_status,
      number: item.reference_id,
      name: item.name,
      coverage_amount: item.unit_cost_literal,
      coverage_cost: item.premium_literal,
      purchase_date: dateSlashes({ item: order?.created }),
      sold_by: item.policy.sold_by,
    }));

    const defaultGridColDef = {
      sortable: false,
      hideable: false,
    };

    const columns: GridColDef[] = [
      {
        field: 'number',
        headerName: 'Certificate ID',
        renderCell: (params: GridRenderCellParams<string>) => (
          <ValueTooltip value={params.value} />
        ),
        ...defaultGridColDef,
        width: 150,
      },
      {
        field: 'name',
        headerName: 'Description',
        renderCell: (params: GridRenderCellParams<string>) => (
          <ValueTooltip value={params.value} />
        ),
        ...defaultGridColDef,
        width: 270,
      },
      {
        field: 'purchase_date',
        headerName: 'Purchase Date',
        ...defaultGridColDef,
        width: 150,
      },
      {
        field: 'coverage_amount',
        headerName: 'Coverage Amount',
        ...defaultGridColDef,
        width: 170,
      },
      {
        field: 'coverage_cost',
        headerName: 'Coverage Cost',
        ...defaultGridColDef,
        width: 150,
      },
      {
        ...statusColumn,
        ...defaultGridColDef,
        width: 130,
      },
      {
        field: 'sold_by',
        headerName: 'Sold By',
        renderCell: (params: GridRenderCellParams<string>) => (
          <ValueTooltip value={params.value} />
        ),
        ...defaultGridColDef,
      },
    ];
    return (
      <GenericItemTable
        order={order}
        items={items}
        rows={rows}
        columns={columns}
        hasSelections={hasSelections}
        selected={selected}
        handleSelectionUpdate={handleSelectionUpdate}
        handleAttemptToSelect={handleAttemptToSelect}
      />
    );
  },
  (prevProps, nextProps) => isEqual(prevProps.selected, nextProps.selected)
);

export default ItemTable;
