import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { HeaderDescriptionType } from 'utils/translation/types';

const HeaderDescription: FC<HeaderDescriptionType> = function ({
  title,
  description,
  footer,
  children,
}) {
  return (
    <Stack
      px={{
        xs: 2,
        sm: 0,
      }}
      className="text-center"
    >
      {title && (
        <Typography
          variant="h5"
          className="headline font-weight-bold text-capitalize"
        >
          {title}
        </Typography>
      )}
      {children || (
        <Box className="text-center" mt={2}>
          {description && <Typography mb={3}>{description}</Typography>}
          {footer && (
            <Typography component="span" className="caption">
              {footer}
            </Typography>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default HeaderDescription;
