// products
import { fanshieldOrders } from 'mocks/products/fanshield';
import { raasClientConfig } from 'mocks/products/raasClient';
import { raasOrders } from 'mocks/products/raasNoClient';
import { shopGOrders } from 'mocks/products/shopguarantee';

// flows
import { fanshieldBillingConfig } from 'mocks/billing/fanshield';
import { fanshieldCancellationConfig } from 'mocks/cancellation/fanshield';
import { raasCancellationConfig } from 'mocks/cancellation/raas';
import { shopGCancellationConfig } from 'mocks/cancellation/shopguarantee';
import { fanshieldCertificateConfig } from 'mocks/certificate/fanshield';
import { raasCertificateConfig } from 'mocks/certificate/raas';
import { shopGCertificateConfig } from 'mocks/certificate/shopguarantee';
import { fanshieldRechargeConfig } from 'mocks/recharge/fanshield';
import { raasRechargeConfig } from 'mocks/recharge/raas';
import { fanshieldRefundConfig } from 'mocks/refund/fanshield';
import { raasRefundConfig } from 'mocks/refund/raas';
import { shopGRefundConfig } from 'mocks/refund/shopguarantee';

export default {
  'billing/fanshield': {
    config: fanshieldBillingConfig,
  },
  'billing/regshield': {
    config: fanshieldBillingConfig,
  },
  'billing/tourshield': {
    config: fanshieldBillingConfig,
  },
  'cancellation/fanshield': {
    config: fanshieldCancellationConfig,
  },
  'cancellation/raas': {
    config: raasCancellationConfig,
  },
  'cancellation/raas/client': {
    config: raasCancellationConfig,
  },
  'cancellation/regshield': {
    config: fanshieldCancellationConfig,
  },
  'cancellation/shopguarantee': {
    config: shopGCancellationConfig,
  },
  'cancellation/tourshield': {
    config: fanshieldCancellationConfig,
  },
  'certificate/fanshield': {
    config: fanshieldCertificateConfig,
  },
  'certificate/raas': {
    config: raasCertificateConfig,
  },
  'certificate/regshield': {
    config: fanshieldCertificateConfig,
  },
  'certificate/shopguarantee': {
    config: shopGCertificateConfig,
  },
  'certificate/tourshield': {
    config: fanshieldCertificateConfig,
  },
  'recharge/fanshield': {
    config: fanshieldRechargeConfig,
  },
  'recharge/raas': {
    config: raasRechargeConfig,
  },
  'recharge/regshield': {
    config: fanshieldRechargeConfig,
  },
  'recharge/tourshield': {
    config: fanshieldRechargeConfig,
  },
  'refund/fanshield': {
    config: fanshieldRefundConfig,
    order: fanshieldOrders,
  },
  'refund/raas': {
    config: raasRefundConfig,
    order: raasOrders,
  },
  'refund/raas/client': {
    config: raasClientConfig,
    order: raasOrders,
  },
  'refund/regshield': {
    config: fanshieldRefundConfig,
    order: fanshieldOrders,
  },
  'refund/shopguarantee': {
    config: shopGRefundConfig,
    order: shopGOrders,
  },
  'refund/tourshield': {
    config: fanshieldRefundConfig,
    order: fanshieldOrders,
  },
};
