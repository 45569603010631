// mocks
import { raasGeneralConfig } from 'mocks/products/raasNoClient';

// types
import { ConfigFileTypes, ConfigStep } from 'types';

const raasCertificateSteps: ConfigStep[] = [
  {
    name: 'CertificatePage',
    backButtonText: '',
    fields: [
      {
        component: 'CertificatePage',
        verbiages: {},
      },
    ],
  },
];

export const raasCertificateConfig: ConfigFileTypes = {
  ...raasGeneralConfig,
  steps: raasCertificateSteps,
};
