import React, { VFC } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import ImageIcon from '@mui/icons-material/Image';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from 'styles';

export const getExtension = (end: string) => {
  const [extension] = end.split('?');

  return extension;
};

const FileSelection: VFC<{ uploads: string[] }> = function ({ uploads }) {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.up('sm'));
  const limit = isMobile ? 30 : 25;

  const isReady = () => uploads.length > 0;

  const formatText = (upload: string) => {
    const file = upload.split('/').slice(4).join('');
    const extensionIndex = file.lastIndexOf('.');
    const filename = file.slice(0, extensionIndex);
    const end = file.slice(extensionIndex + 1);
    const extension = getExtension(end);

    return filename.length > limit
      ? `${filename.slice(0, limit)}...${extension}`
      : `${filename}.${extension}`;
  };

  return isReady() ? (
    <Stack>
      {uploads.map((upload, index) => (
        <Box
          key={index}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            minWidth: '45%',
            width: '100%',
            margin: '3px 3px 3px 0px',
            borderRadius: '6px',
            background: '#eaebec',
            px: 2,
            height: '32px',
          }}
        >
          <Stack direction="row" spacing={1} alignItems="center" width="100%">
            <ImageIcon />
            <Box
              style={{
                display: 'block',
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {formatText(upload)}
            </Box>
          </Stack>
        </Box>
      ))}
    </Stack>
  ) : null;
};

export default FileSelection;
