import { RootState } from 'store';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { UploadedFileFormat } from 'components/UploadFiles/types';
import { ProductType, ProductEnum } from 'types';
import { radioGroupText } from 'utils/radioGroup';
import { getProductValue } from 'store/utils/hooks';
import { formatDateToTwentyFourHourTime } from 'utils/date';
import { selectPreferredLang } from './selectors';
import { selectSelectedItems, selectSelectedOrder } from '../orders/selectors';
import { selectSubmissionMapping, selectUrlProduct } from '../config/selectors';
import { ClaimFormattedData } from './types';
import {
  callFlowSubmitApi,
  flattenUniqueIds,
  submissionMapping,
} from './helpers';
import { Flow, FlowType } from '../config/types';
import { FormattedItem, FormattedOrder } from '../orders/types';

export const createClaim = createAsyncThunk(
  'claims/createClaim',
  async (
    { signature }: { signature: string },
    { getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const selectedItems = selectSelectedItems(state);
    const selectedOrder = selectSelectedOrder(state);
    const submissionMappingConfig = selectSubmissionMapping(state);

    const product = getProductValue(selectUrlProduct(state));
    if (!product) return rejectWithValue('Error could not find product');

    const submissionMappingData = submissionMapping(
      state,
      submissionMappingConfig
    );
    const type: FlowType = state.configFileReducer.flow;
    const claimData = createClaimData(
      type,
      submissionMappingData,
      state,
      selectedItems,
      signature,
      selectedOrder,
      product
    );

    try {
      const response = await callFlowSubmitApi(
        selectedOrder?.uuid,
        claimData,
        type
      );
      return response.data;
    } catch (e) {
      return rejectWithValue('Error could not perform operation');
    }
  }
);
const createClaimData = (
  type: string,
  submissionMappingData: any,
  state: RootState,
  selectedItems: FormattedItem[],
  signature: string,
  selectedOrder: FormattedOrder | undefined,
  product: ProductType
) => {
  const radioGroupOptions = radioGroupText();
  if (type === Flow.cancellation) {
    const reason =
      submissionMappingData.reason === 'other'
        ? state.formsReducer.forms.step1.cancellation_description
        : radioGroupOptions.find(
            ({ key }) => key === submissionMappingData.reason
          ).value;

    return {
      items: flattenUniqueIds(selectedItems).map((id) => ({
        id,
        reason,
      })),
    };
  }
  if (type === Flow.billing) {
    return {
      ...submissionMappingData,
      items: flattenUniqueIds(selectedItems),
      claim_status:
        selectedItems &&
        selectedItems.length > 0 &&
        selectedItems[0]?.claim_status
          ? selectedItems[0].claim_status
          : '',
    };
  }

  if (type === Flow.recharge) {
    return submissionMappingData;
  }

  return product === ProductEnum.shopguarantee
    ? ({
        ...submissionMappingData,
        ...(submissionMappingData.files.length && {
          files: submissionMappingData.files.map(
            (file: UploadedFileFormat) => file.id
          ),
        }),
        signature,
        preferred_language: selectPreferredLang(state),
        items: flattenUniqueIds(selectedItems),
        client_id: selectedOrder?.client_id,
        order: selectedOrder?.uuid,
      } as ClaimFormattedData)
    : ({
        ...submissionMappingData,
        ...(submissionMappingData.files.length && {
          files: submissionMappingData.files.map(
            (file: UploadedFileFormat) => file.id
          ),
        }),
        signature,
        preferred_language: selectPreferredLang(state),
        items: flattenUniqueIds(selectedItems),
        client_id: selectedOrder?.client_id,
        order: selectedOrder?.uuid,
        incident_date:
          submissionMappingData.incident_date &&
          submissionMappingData.incident_date.split('T')[0],
        arrival_date:
          submissionMappingData?.arrival_date &&
          submissionMappingData?.arrival_date !== 'null'
            ? formatDateToTwentyFourHourTime(submissionMappingData.arrival_date)
            : null,
        departure_date:
          submissionMappingData?.departure_date &&
          submissionMappingData?.departure_date !== 'null'
            ? formatDateToTwentyFourHourTime(
                submissionMappingData.departure_date
              )
            : null,
        event_date:
          submissionMappingData.event_date &&
          submissionMappingData.event_date.split('T')[0],
      } as ClaimFormattedData);
};
