// types
import type { ProductOrder } from 'store/modules/orders/types';
import type { ConfigFileTypes, ConfigTheme, Links } from 'types';

export const fanshieldTheme: ConfigTheme = {
  palette: {
    primary: {
      main: '#00a3e0',
      contrastText: '#fff',
    },
    text: {
      primary: '#000',
    },
  },
};

export const fanshieldLinks: Links = {
  helpUrl:
    'https://help.fanshield.com/s/topic/0TO6g000000suYtGAI/refund-program-faq',
  helpEmail: 'https://protecht.com/refund-contact',
  privacyPolicyUrl: 'https://protecht.com/refund-privacy',
  contactUsUrl: 'https://protecht.com/refund-contact',
  helpPhone: '8448494827',
};

export const fanshieldGeneralConfig: ConfigFileTypes = {
  theme: fanshieldTheme,
  steps: [],
  submissionMapping: {},
  translationOverride: {
    general: {},
    product: {},
    underwriter: {},
  },
  links: fanshieldLinks,
};

const fanshieldProdOrder: ProductOrder[] = [
  {
    id: 'F9BF752D-3654-479F-A3E7-5B0182CAF20C',
    reference_id: '#1234567',
    created: new Date(Date.now() + 864e5).toISOString(),
    currency: 'USD',
    order_subtotal: '69.69',
    order_subtotal_literal: 'USD 69.69',
    order_premium_total: '10.00',
    order_premium_total_literal: 'USD 10.00',
    jurisdiction: {
      country: 'US',
      region: 'LA',
    },
    client: {
      name: 'Fanatics',
      id: '0428b167-a964-443b-bce1-b8fbd646e195',
    },
    customer: {
      id: 'E2FDD7E1-CD30-4A60-88F0-89D1B43CF2C3',
      first_name: 'Billy',
      last_name: 'Bob',
      email: 'billy@bob.com',
      phone: '5555555555',
      locale: 'en_US',
      address_1: '123 Test St',
      address_2: '',
      city: 'Newport Beach',
      state: 'CA',
      country: 'US',
      postal_code: '92663',
    },
    items: [
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB1',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E3',
        name: 'Russell Wilson Denver Broncos Nike Game Jersey',
        description: 'Orange Size: M',
        reference_number: 'some_reference_number',
        unit_cost: '129.99',
        unit_cost_literal: 'USD 129.99',
        premium: '9.99',
        premium_literal: 'USD 9.99',
        event_id: '9A518657-0998-49EC-AEF3-7DE1D566D3E9',
        image_url: null,
        reference_url: null,
        claim_status: '',
        status: 'Insufficient Address',
        issued: new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24
        ).toISOString(),
        look_period: new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24
        ).toISOString(),
        policy: {
          certificate_of_coverage: '',
          id: 'B2ABF7DD-7CC7-4E48-AE4D-4E18373A99B4',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!?',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
        event: {
          id: '9A518657-0998-49EC-AEF3-7DE1D566D3E9',
          reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E5',
          name: 'such cool event 1',
          start_date: new Date(
            new Date().getTime() + 1000 * 60 * 60 * 24
          ).toISOString(),
          end_date: null,
          location: {
            venue: 'Crypto.com Arena',
            city: 'Los Angeles',
            state: 'California',
            country: 'United States',
          },
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB3',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E1',
        name: 'Color-ad-o Avalanche WinCraft 2023 Stanley Cup Champions',
        description: '3 x 5 1-Sided Deluxe Flag',
        reference_number: 'some_reference_number',
        unit_cost: '42.99',
        unit_cost_literal: 'USD 42.99',
        premium: '9.99',
        premium_literal: 'USD 9.99',
        event_id: '9A518657-0998-49EC-AEF3-7DE1D566D3E9',
        image_url: null,
        reference_url: null,
        claim_status: '',
        status: 'Issued',
        issued: new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24
        ).toISOString(),
        look_period: new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24
        ).toISOString(),
        policy: {
          certificate_of_coverage: '',
          id: 'B2ABF7DD-7CC7-4E48-AE4D-4E18373A99B5',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: true,
          is_weather_interruption: false,
        },
        event: {
          id: '9A518657-0998-49EC-AEF3-7DE1D566D3E9',
          reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E5',
          name: 'such cool event 1',
          start_date: new Date(
            new Date().getTime() + 1000 * 60 * 60 * 24
          ).toISOString(),
          end_date: null,
          location: {
            venue: 'Crypto.com Arena',
            city: 'Los Angeles',
            state: 'California',
            country: 'United States',
          },
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB4',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E5',
        name: 'Color-ad-o Rockies Nike Wordmark Legend T-Shirt',
        description: 'Black Size: XL',
        reference_number: 'some_reference_number',
        unit_cost: '42.99',
        unit_cost_literal: 'USD 42.99',
        premium: '9.99',
        premium_literal: 'USD 9.99',
        event_id: '629E9A7F-A276-4089-BDEB-D06B1F1170AA',
        image_url: null,
        reference_url: null,
        claim_status: '',
        status: 'Issued',
        issued: new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24
        ).toISOString(),
        look_period: new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24
        ).toISOString(),
        policy: {
          certificate_of_coverage: '',
          id: 'B2ABF7DD-7CC7-4E48-AE4D-4E18373A99B4',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
        event: {
          id: '9A518657-0998-49EC-AEF3-7DE1D566D3E9',
          reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E5',
          name: 'such cool event 1',
          start_date: new Date(
            new Date().getTime() + 1000 * 60 * 60 * 24
          ).toISOString(),
          end_date: null,
          location: {
            venue: 'Crypto.com Arena',
            city: 'Los Angeles',
            state: 'California',
            country: 'United States',
          },
        },
      },
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB5',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E5',
        name: 'Color-ado Rockies Nike Wordmark Legend T-Shirt',
        description: 'Black Size: L',
        reference_number: 'some_reference_number',
        unit_cost: '50.00',
        unit_cost_literal: 'USD 50.00',
        premium: '9.99',
        premium_literal: 'USD 9.99',
        event_id: '629E9A7F-A276-4089-BDEB-D06B1F1170AA',
        image_url: null,
        reference_url: null,
        claim_status: '',
        status: 'Insufficient Address',
        issued: new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24
        ).toISOString(),
        look_period: new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24
        ).toISOString(),
        policy: {
          certificate_of_coverage: '',
          id: 'B2ABF7DD-7CC7-4E48-AE4D-4E18373A99B4',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
        event: {
          id: '9A518657-0998-49EC-AEF3-7DE1D566D3E9',
          reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E5',
          name: 'such cool event 1',
          start_date: new Date(
            new Date().getTime() + 1000 * 60 * 60 * 24
          ).toISOString(),
          end_date: null,
          location: {
            venue: 'Crypto.com Arena',
            city: 'Los Angeles',
            state: 'California',
            country: 'United States',
          },
        },
      },
    ],
    impressions_count: 1,
  },
  {
    id: 'F9BF752D-3654-479F-A3E7-5B0182CAF20F',
    reference_id: '#456789790',
    created: new Date(Date.now() - 864e5).toISOString(),
    currency: 'USD',
    order_subtotal: '69.69',
    order_subtotal_literal: 'USD 69.69',
    order_premium_total: '0.00',
    order_premium_total_literal: 'USD 0.00',
    jurisdiction: {
      country: 'US',
      region: 'LA',
    },
    client: {
      name: 'Fanatics',
      id: '0428b167-a964-443b-bce1-b8fbd646e195',
    },
    customer: {
      id: 'E2FDD7E1-CD30-4A60-88F0-89D1B43CF2C5',
      first_name: 'Lancette',
      last_name: 'Bob',
      email: 'billy@bob.com',
      phone: '5555555555',
      locale: 'en_US',
      address_1: '895 Test St',
      address_2: '',
      city: 'Another Beach',
      state: 'CA',
      country: 'US',
      postal_code: '92668',
    },
    items: [
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB7',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E7',
        name: 'Color-ado Rockies New Era 2023 City Connect 59FIFTY Fitted Hat ',
        description: 'White Size: 7 1/8',
        reference_number: 'some_reference_number',
        unit_cost: '45.99',
        unit_cost_literal: 'USD 45.99',
        premium: '9.99',
        premium_literal: 'USD 9.99',
        event_id: '326E9AED-E796-446D-9985-BBCD65FC7754',
        image_url: 'coolimage.com',
        reference_url: 'mycoolitem.com/1/',
        claim_status: '',
        status: 'Issued',
        issued: new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24
        ).toISOString(),
        tracking: {
          created: '2023-8-08 12:01:00.123456',
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        look_period: new Date(
          new Date().getTime() - 1000 * 60 * 60 * 24
        ).toISOString(),
        policy: {
          certificate_of_coverage: '',
          id: 'B2ABF7DD-7CC7-4E48-AE4D-4E18373A99B4',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
        event: {
          id: '9A518657-0998-49EC-AEF3-7DE1D566D3E9',
          reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E5',
          name: 'such cool event 1',
          start_date: new Date(
            new Date().getTime() + 1 * 60 * 60 * 24
          ).toISOString(),
          end_date: null,
          location: {
            venue: 'Crypto.com Arena',
            city: 'Los Angeles',
            state: 'California',
            country: 'United States',
          },
        },
      },
    ],
    impressions_count: 1,
  },
  {
    id: 'F9BF752D-3654-479F-A3E7-5B0182CAF20F',
    reference_id: '#456789790',
    created: '2023-11-27T16:27:38.635939Z',
    currency: 'USD',
    order_subtotal: '69.69',
    order_subtotal_literal: 'USD 69.69',
    order_premium_total: '9.99',
    order_premium_total_literal: 'USD 9.99',
    jurisdiction: {
      country: 'US',
      region: 'LA',
    },
    client: {
      name: 'Fanatics',
      id: '0428b167-a964-443b-bce1-b8fbd646e195',
    },
    customer: {
      id: 'E2FDD7E1-CD30-4A60-88F0-89D1B43CF2C5',
      first_name: 'Lancette',
      last_name: 'Bob',
      email: 'billy@bob.com',
      phone: '5555555555',
      locale: 'en_US',
      address_1: '895 Test St',
      address_2: '',
      city: 'Another Beach',
      state: 'CA',
      country: 'US',
      postal_code: '92668',
    },
    items: [
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB7',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E7',
        name: 'Colorado Rockies New Era 2023 City Connect 59FIFTY Fitted Hats',
        description: 'White Size: 7 1/8',
        reference_number: 'some_reference_number',
        unit_cost: '45.99',
        unit_cost_literal: 'USD 45.99',
        premium: '9.99',
        premium_literal: 'USD 9.99',
        event_id: '326E9AED-E796-446D-9985-BBCD65FC7754',
        image_url: 'coolimage.com',
        reference_url: 'mycoolitem.com/1/',
        claim_status: '',
        status: 'Issued',
        issued: new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24 * 2
        ).toISOString(),
        tracking: {
          created: '2023-8-08 12:01:00.123456',
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        look_period: new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24
        ).toISOString(),
        policy: {
          certificate_of_coverage: '',
          id: 'B2ABF7DD-7CC7-4E48-AE4D-4E18373A99B4',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
        event: {
          id: '9A518657-0998-49EC-AEF3-7DE1D566D3E9',
          reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E5',
          name: 'such cool event 1',
          start_date: new Date(
            new Date().getTime() + 1000 * 60 * 60 * 24 * 2
          ).toISOString(),
          end_date: null,
          location: {
            venue: 'Crypto.com Arena',
            city: 'Los Angeles',
            state: 'California',
            country: 'United States',
          },
        },
      },
    ],
    impressions_count: 1,
  },
  {
    id: 'F9BF752D-3654-479F-A3E7-5B0182CAF20F',
    reference_id: '#456789790',
    created: '2023-11-27T16:27:38.635939Z',
    currency: 'USD',
    order_subtotal: '69.69',
    order_subtotal_literal: 'USD 69.69',
    order_premium_total: '9.99',
    order_premium_total_literal: 'USD 9.99',
    jurisdiction: {
      country: 'US',
      region: 'LA',
    },
    client: {
      name: 'Fanatics',
      id: '0428b167-a964-443b-bce1-b8fbd646e195',
    },
    customer: {
      id: 'E2FDD7E1-CD30-4A60-88F0-89D1B43CF2C5',
      first_name: 'Lancette',
      last_name: 'Bob',
      email: 'billy@bob.com',
      phone: '5555555555',
      locale: 'en_US',
      address_1: '895 Test St',
      address_2: '',
      city: 'Another Beach',
      state: 'CA',
      country: 'US',
      postal_code: '92668',
    },
    items: [
      {
        id: '534142D7-821D-478E-AB39-56555DEFEFB7',
        reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E7',
        name: 'Colorado Rockies New Era 2023 City Connect 59FIFTY Fitted Hat!',
        description: 'White Size: 7 1/8',
        reference_number: 'some_reference_number',
        unit_cost: '45.99',
        unit_cost_literal: 'USD 45.99',
        premium: '9.99',
        premium_literal: 'USD 9.99',
        event_id: '326E9AED-E796-446D-9985-BBCD65FC7754',
        image_url: 'coolimage.com',
        reference_url: 'mycoolitem.com/1/',
        claim_status: '',
        status: 'On Hold',
        issued: new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24
        ).toISOString(),
        tracking: {
          created: '2023-8-08 12:01:00.123456',
          number: '123ABCDEF456',
          carrier: 'USPS',
        },
        look_period: new Date(
          new Date().getTime() + 1000 * 60 * 60 * 24
        ).toISOString(),
        policy: {
          certificate_of_coverage: '',
          terms_and_conditions:
            '<a href="https://www.fanshield.insure/markelterms">Terms and conditions</a>',
          id: 'B2ABF7DD-7CC7-4E48-AE4D-4E18373A99B4',
          underwriter: 'JSV',
          sold_by: 'JSV?!?!',
          legal_signature:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus porttitor nibh ut laoreet imperdiet. Aliquam aliquet aliquet tincidunt. Mauris non erat vel nunc varius vulputate. Suspendisse lobortis sit amet mi id condimentum. Donec interdum magna et risus pulvinar, vel facilisis libero hendrerit. Praesent nec egestas metus. Nullam nec ex sit amet dolor ultricies mollis quis ac metus. Pellentesque lectus lorem, facilisis et dignissim ac, ultricies in risus. Donec consectetur mauris eget nunc efficitur euismod.',
          is_event_interruption: false,
          is_weather_interruption: false,
        },
        event: {
          id: '9A518657-0998-49EC-AEF3-7DE1D566D3E9',
          reference_id: 'BB5E8794-B95D-4DA9-B659-3AC19E0754E5',
          name: 'such cool event 1',
          start_date: new Date(
            new Date().getTime() + 1000 * 60 * 60 * 24
          ).toISOString(),
          end_date: null,
          location: {
            venue: 'Crypto.com Arena',
            city: 'Los Angeles',
            state: 'California',
            country: 'United States',
          },
        },
      },
    ],
    impressions_count: 1,
  },
];

export const fanshieldOrders = {
  count: 2,
  next: '[https://newdomain.protecht.com/raas/orders/?limit=20&offset=20](https://newdomain.protecht.com/raas/orders/?limit=20&offset=20)',
  previous: null,
  items: fanshieldProdOrder,
};
